import { TypedDocumentNode, gql } from '@apollo/client';
import { GetIssueHeaderQuery, GetIssueHeaderQueryVariables } from '__generated__/graphql';

export const GET_ISSUE_HEADER: TypedDocumentNode<GetIssueHeaderQuery, GetIssueHeaderQueryVariables> = gql`
  query getIssueHeader($id: ID!) {
    issue(id: $id) {
      id
      package
      cve {
        id
        cve
        title
      }
      version {
        id
        tag
        source {
          id
          name
          origin
        }
      }
    }
  }
`;
