import { SceneComponentProps } from '@grafana/scenes';
import { Box, Switch } from '@grafana/ui';
import { ChangeEvent, useCallback } from 'react';

import { SwitchVariable } from './SwitchVariable';

export function CustomSwitch({ model }: SceneComponentProps<SwitchVariable>) {
  const { value, key, invalid } = model.useState();

  const onToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      model.setValue(e.target.checked);
    },
    [model]
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      paddingX={1}
      borderColor="medium"
      borderRadius="default"
      borderStyle="solid"
      backgroundColor="canvas"
    >
      <Switch id={key} value={value} invalid={invalid} onChange={onToggle} />
    </Box>
  );
}
