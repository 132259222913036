import { Button, ButtonGroup, Dropdown, Menu } from '@grafana/ui';
import { Role } from '__generated__/graphql';
import { useCallback, useState } from 'react';
import { useRole } from 'scenes/hooks';

export enum IssueScope {
  Issue,
  Version,
  Source,
  All,
}

interface ButtonOption {
  label: string;
  description: string;
  disabled: boolean;
  scope: IssueScope;
}

interface ModifyRiskButtonType {
  disabled: boolean;
  updateScope: (scope: IssueScope) => void;
  onClick: () => void;
}

const defaultButton: ButtonOption = {
  label: 'Update Risk',
  description: 'Affects this issue only',
  disabled: false,
  scope: IssueScope.Issue,
};

export const ModifyRiskButton = ({ disabled, updateScope, onClick }: ModifyRiskButtonType) => {
  const { role } = useRole();

  const [selectedButton, setSelectedButton] = useState<ButtonOption>(defaultButton);

  const getButtonOptions = useCallback(
    (isNotAdmin: boolean): ButtonOption[] => [
      defaultButton,
      {
        label: 'Update Risk for All Issues',
        description: `Affects all issues from this cve for this version only`,
        disabled: false,
        scope: IssueScope.Version,
      },
      {
        label: 'Update Risk for All Versions',
        description: `Affects all issues from this cve for this source only ${
          isNotAdmin ? '(administrators only)' : ''
        }`,
        disabled: isNotAdmin,
        scope: IssueScope.Source,
      },
      {
        label: 'Update Risk for All Sources',
        description: `Affects all issues from this cve for all sources ${isNotAdmin ? '(administrators only)' : ''}`,
        disabled: isNotAdmin,
        scope: IssueScope.All,
      },
    ],
    []
  );

  return (
    <ButtonGroup>
      <Button
        disabled={disabled}
        type="submit"
        onClick={onClick}
        variant="destructive"
        tooltip={disabled ? 'Sign in to modify risk' : ''}
        tooltipPlacement="top"
      >
        {selectedButton.label}
      </Button>
      <Dropdown
        placement="auto-end"
        overlay={
          <Menu>
            {getButtonOptions(role !== Role.Administrator).map((option, index) => (
              <Menu.Item
                key={index}
                disabled={option.disabled}
                label={option.label}
                description={option.description}
                onClick={() => {
                  setSelectedButton(option);
                  updateScope(option.scope);
                }}
              />
            ))}
          </Menu>
        }
      >
        <Button
          disabled={disabled}
          icon="angle-down"
          tooltipPlacement="auto-end"
          tooltip="Additional Options"
          variant="destructive"
        />
      </Dropdown>
    </ButtonGroup>
  );
};
