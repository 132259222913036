import { TypedDocumentNode, gql } from '@apollo/client';
import { GetSourceWithCvesQuery, GetSourceWithCvesQueryVariables } from '__generated__/graphql';

export const GET_SOURCE_WITH_CVES: TypedDocumentNode<GetSourceWithCvesQuery, GetSourceWithCvesQueryVariables> = gql`
  query getSourceWithCves($id: ID!) {
    source(id: $id) {
      id
      versions {
        id
        tag
        issues {
          isDismissed
          cve {
            cve
            severity
          }
        }
      }
    }
  }
`;
