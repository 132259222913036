import { PanelBuilders } from '@grafana/scenes';
import { BigValueColorMode, BigValueTextMode, VizOrientation } from '@grafana/schema';
import { BUG_BOUNTY_PAYOUT_THRESHOLDS } from 'scenes/panelConstants';

export const getBugBountyPayoutStat = () =>
  PanelBuilders.stat()
    .setTitle('Total Paid')
    .setNoValue('No bug bounty submissions')
    .setUnit('currencyUSD')
    .setDisplayMode('transparent')
    .setOption('reduceOptions', {
      calcs: ['sum'],
    })
    .setOption('wideLayout', false)
    .setOption('colorMode', BigValueColorMode.Background)
    .setOption('textMode', BigValueTextMode.Value)
    .setOption('orientation', VizOrientation.Vertical)
    .setThresholds(BUG_BOUNTY_PAYOUT_THRESHOLDS)
    .setOverrides((overridesBuilder) => {
      overridesBuilder.matchFieldsWithName('squad').overrideNoValue('No Squad Assigned');
    })
    .build();
