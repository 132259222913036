import { EmbeddedScene, SceneAppPage, SceneFlexItem, SceneFlexLayout, SceneReactObject } from '@grafana/scenes';
import { ModifyRisk } from 'scenes/components/ModifyRisk';
import { ISSUES_ROUTE } from 'shared/constants';

export const getIssueReclassifyDrilldown = (apiUrl: string, issueID: string) =>
  new SceneAppPage({
    title: 'Modify Risk',
    titleIcon: 'edit',
    key: 'modifyDrilldown',
    url: `${ISSUES_ROUTE}/${issueID}/modify`,
    getScene: () => {
      return new EmbeddedScene({
        key: 'modify',
        body: new SceneFlexLayout({
          direction: 'column',
          children: [
            new SceneFlexItem({
              body: new SceneReactObject({
                component: () => ModifyRisk({ issueID }),
              }),
            }),
          ],
        }),
      });
    },
  });
