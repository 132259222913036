import { CustomCellRendererProps, Icon } from '@grafana/ui';
import { SourceType } from '__generated__/graphql';
import { memo } from 'react';

const SourceTypeCell = memo(({ value }: CustomCellRendererProps) => {
  return <Icon name={(value as string).toUpperCase() === SourceType.Container ? 'docker' : 'github'} />;
});

SourceTypeCell.displayName = 'SourceTypeCell';

export { SourceTypeCell };
