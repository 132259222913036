import { REPOSITORIES, DOCKER_LOGO_SVG, GAR_LOGO_SVG, GCR_LOGO_SVG, GITHUB_LOGO_SVG } from 'shared/constants';

export const getSourceImage = (origin: string): string => {
  const lcOrigin = origin.toLowerCase();
  switch (lcOrigin) {
    case REPOSITORIES.GITHUB:
      return GITHUB_LOGO_SVG;
    case REPOSITORIES.DOCKER:
      return DOCKER_LOGO_SVG;
    case REPOSITORIES.GCR:
      return GCR_LOGO_SVG;
    case REPOSITORIES.GAR:
      return GAR_LOGO_SVG;
    default:
      return '';
  }
};
