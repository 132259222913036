import { PanelBuilders, SceneDataTransformer } from '@grafana/scenes';
import { ColorBoxCell } from 'scenes/components/ColorBoxCell';
import { SourceTypeCell } from 'scenes/components/IssuesTable';
import { VersionLinkCell } from 'scenes/components/VersionLinkCell';
import { VersionLinkCellType } from 'scenes/components/VersionLinkCell/VersionLinkCell';
import { RISK_LEVEL_MAPPINGS } from 'scenes/panelConstants';
import { removeSubstringTransform, sortByRiskTransform } from 'scenes/transformations';
import { GITHUB_PREFIX, SOURCES_ROUTE } from 'shared/constants';

export const getImpactedVersionsTable = (cve: string) =>
  PanelBuilders.table()
    .setData(
      new SceneDataTransformer({
        transformations: [
          sortByRiskTransform({
            sort: {
              field: 'risk',
            },
          }),
          {
            id: 'groupBy',
            options: {
              fields: {
                name: {
                  operation: 'groupby',
                },
                type: {
                  operation: 'groupby',
                },
                version: {
                  operation: 'groupby',
                },
                versionID: {
                  operation: 'groupby',
                },
                sourceID: {
                  operation: 'groupby',
                },
                isArchived: {
                  operation: 'groupby',
                },
                risk: {
                  aggregations: ['firstNotNull'],
                  operation: 'aggregate',
                },
              },
            },
          },
          removeSubstringTransform({
            substring: GITHUB_PREFIX,
            fieldName: 'name',
          }),
          {
            id: 'organize',
            options: {
              indexByName: {
                type: 0,
                name: 1,
                version: 2,
                'risk (firstNotNull)': 3,
                versionID: 5,
              },
              renameByName: {
                version: 'Version',
                'risk (firstNotNull)': 'Highest Risk',
                name: 'Source',
                type: 'Type',
              },
            },
          },
        ],
      })
    )
    .setTitle('Impacted Versions')
    .setDisplayMode('transparent')
    .setNoValue('No impacted versions. Try updating the filters.')
    .setOverrides((overridesBuilder) => {
      overridesBuilder
        .matchFieldsWithName('Type')
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          // @ts-ignore
          type: 'custom',
          cellComponent: SourceTypeCell,
        })
        .overrideCustomFieldConfig('width', 40);
      overridesBuilder
        .matchFieldsWithName('Source')
        .overrideCustomFieldConfig('filterable', true)
        .overrideLinks([
          {
            title: 'Source ${__data.fields.Source}',
            url: `${SOURCES_ROUTE}/\${__data.fields.sourceID}`,
          },
        ]);
      overridesBuilder
        .matchFieldsWithName('Version')
        .overrideCustomFieldConfig('filterable', true)
        .overrideCustomFieldConfig('cellOptions', {
          // @ts-ignore
          type: 'custom',
          cellComponent: (props: VersionLinkCellType) => <VersionLinkCell {...props} cve={cve} />,
        });
      overridesBuilder
        .matchFieldsWithName('Highest Risk')
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          // @ts-ignore
          type: 'custom',
          cellComponent: ColorBoxCell,
        })
        .overrideMappings(RISK_LEVEL_MAPPINGS)
        .overrideCustomFieldConfig('width', 116);

      overridesBuilder.matchFieldsWithName('sourceID').overrideCustomFieldConfig('hidden', true);
      overridesBuilder.matchFieldsWithName('versionID').overrideCustomFieldConfig('hidden', true);
      overridesBuilder.matchFieldsWithName('isArchived').overrideCustomFieldConfig('hidden', true);
    })
    .build();
