import { useQuery } from '@apollo/client';
import { Stack, Text } from '@grafana/ui';
import { GetVersionNameQuery } from '__generated__/graphql';
import { Fragment } from 'react';
import { GITHUB_PREFIX } from 'shared/constants';

import { capitalizeFirstLetter, formatDate, getSourceImage } from '../utils';

import { GET_VERSION_NAME } from './VersionHeaderQueries';

interface VersionHeaderType {
  versionID: string;
}

export const VersionHeader = ({ versionID }: VersionHeaderType) => {
  const { data, loading, error } = useQuery<GetVersionNameQuery>(GET_VERSION_NAME, {
    variables: {
      id: versionID,
    },
  });

  if (loading || error) {
    return null;
  }

  const {
    version,
    latestScans,
    source: { name, origin },
  } = data!.version;
  const sourceImage = getSourceImage(origin);

  return (
    <Stack direction="column" gap={0.5}>
      <Stack justifyContent="flex-start" alignItems="center" gap={1}>
        <img src={sourceImage} alt="logo" height="30px" width="30px" />
        <Text element="h1" variant="h2">{`${name.replace(GITHUB_PREFIX, '')}:${version}`}</Text>
      </Stack>
      <Stack direction="row" gap={1}>
        {latestScans.map((scan, index) => (
          <Fragment key={scan.id}>
            {index > 0 && (
              <Text color="secondary" variant="bodySmall">
                |
              </Text>
            )}
            <Text color="secondary" variant="bodySmall">
              Scanned by {capitalizeFirstLetter(scan.tool.name)} on {formatDate(new Date(scan.ingestTime))}
            </Text>
          </Fragment>
        ))}
      </Stack>
    </Stack>
  );
};
