import { SceneComponentProps } from '@grafana/scenes';
import { Input } from '@grafana/ui';
import { useCallback, useEffect, useState } from 'react';

import { TextInputVariable } from './TextInputVariable';

export function TextInput({ model }: SceneComponentProps<TextInputVariable>) {
  const { value, width, prefix, placeholder, key, loading } = model.useState();
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value);
  }, []);

  const onBlur = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      model.setValue(e.currentTarget.value);
    },
    [model]
  );

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        model.setValue(e.currentTarget.value);
      }
    },
    [model]
  );

  return (
    <Input
      id={key}
      placeholder={placeholder}
      width={width}
      value={inputValue}
      onChange={onChange}
      prefix={prefix}
      loading={loading}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
    />
  );
}
