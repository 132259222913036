import { useSuspenseQuery } from '@apollo/client';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Stack, useStyles2, Pagination } from '@grafana/ui';
import { GetUsersQuery } from '__generated__/graphql';

import { GET_USERS } from './AccessControlQueries';
import { UserRow } from './UserRow';

interface UserTableType {
  userSearch: string;
  roleSearch: string;
  offset: number;
  onNavigate: (page: number) => void;
  pageSize: number;
}

export const UserTable = ({ userSearch, roleSearch, offset, onNavigate, pageSize }: UserTableType) => {
  const styles = useStyles2(getStyles);

  const { data } = useSuspenseQuery<GetUsersQuery>(GET_USERS, {
    variables: {
      filters: {
        ...(userSearch && { name: userSearch }),
        ...(roleSearch && { role: roleSearch }),
      },
      first: pageSize,
      after: offset,
    },
  });

  return (
    <Stack direction="column">
      <table className={styles.table}>
        <thead>
          <tr>
            <th>User</th>
            <th>Role</th>
          </tr>
        </thead>
        <tbody>
          {data!.users.response.map((user, index) => (
            <UserRow user={user} key={index} />
          ))}
        </tbody>
      </table>
      <Stack direction="row-reverse">
        <Pagination
          currentPage={Math.ceil((offset + 1) / pageSize)}
          numberOfPages={Math.ceil(data!.users.metadata.totalCount / pageSize)}
          onNavigate={onNavigate}
          hideWhenSinglePage
        />
      </Stack>
    </Stack>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  table: css({
    marginTop: theme.spacing(1),
    width: '100%',
    borderCollapse: 'collapse',
    'thead tr': {
      backgroundColor: theme.colors.background.primary,
    },
    'tbody tr:nth-of-type(odd)': {
      backgroundColor: theme.colors.background.secondary,
    },
    'tbody tr:nth-of-type(even)': {
      backgroundColor: theme.colors.background.primary,
    },
    th: {
      width: '85%',
    },
    'th, td': {
      padding: '8px',
    },
  }),
});
