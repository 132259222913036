import type { SelectableValue } from '@grafana/data';
import { Impact } from '__generated__/graphql';

export enum Exploitability {
  ZeroDay = '0 Day',
  SignOfExploitation = 'Sign of Exploitation',
  HighlyExploitable = 'Highly Exploitable',
  Exploitable = 'Exploitable',
  UnlikelyExploitable = 'Unlikely Exploitable',
  NotExploitable = 'Not Exploitable',
  FalsePositive = 'False Positive',
}

export const ExploitabilityOptions: SelectableValue[] = [
  {
    label: Exploitability.ZeroDay,
    value: Exploitability.ZeroDay,
    description: `1st or 3rd party code that's exploitable/known with no available patches, and it's public knowledge that we're vulnerable`,
  },
  {
    label: Exploitability.SignOfExploitation,
    value: Exploitability.SignOfExploitation,
    description: `Signs that vulneraiblity has been exploited to impact on Grafana or customers`,
  },
  {
    label: Exploitability.HighlyExploitable,
    value: Exploitability.HighlyExploitable,
    description: `Easy to discover / easy to exploit vulnerability - unacceptable likelihood that the vulnerability will be discovered by third parties or is trivially exploitable once known within a month`,
  },
  {
    label: Exploitability.Exploitable,
    value: Exploitability.Exploitable,
    description: `1st or 3rd party code where the vulnerable aspects are reachable but 3rd party discovery or successful attack is complex`,
  },
  {
    label: Exploitability.UnlikelyExploitable,
    value: Exploitability.UnlikelyExploitable,
    description: `1st or 3rd party code where it is unclear if the vulnerable aspects are reachable or successful attack is unlikely`,
  },
  {
    label: Exploitability.NotExploitable,
    value: Exploitability.NotExploitable,
    description: `1st or 3rd party code where the vulnerability is real, but code paths aren't used or it's otherwise protected by defense in depth - this is fragile and needs patching, but not urgently`,
  },
  {
    label: Exploitability.FalsePositive,
    value: Exploitability.FalsePositive,
    description: `The reported vulnerability can't be found in code following reasonable attempts to verify existence - SLOs do not apply`,
  },
];

export const ImpactOptions: SelectableValue[] = [
  { label: 'None', value: Impact.None },
  { label: 'Low', value: Impact.Low },
  { label: 'High', value: Impact.High },
];
