import { PanelBuilders, SceneDataTransformer } from '@grafana/scenes';
import { TableCellDisplayMode } from '@grafana/schema';
import { ColorBoxCell } from 'scenes/components/ColorBoxCell';
import { ActionsCell, SourceTypeCell } from 'scenes/components/IssuesTable';
import { PackageCell } from 'scenes/components/PackageCell';
import {
  CVSS_SCORE_THRESHOLDS,
  RISK_LEVEL_MAPPINGS,
  SLO_REMAINING_THRESHOLDS,
  STATUS_MAPPINGS,
  TOOL_MAPPINGS,
} from 'scenes/panelConstants';
import { removeSubstringTransform } from 'scenes/transformations';
import { GITHUB_PREFIX, SOURCES_ROUTE, CVE_DETAILS_ROUTE } from 'shared/constants';

export const getIssuesTable = () =>
  PanelBuilders.table()
    .setData(
      new SceneDataTransformer({
        transformations: [
          {
            id: 'organize',
            options: {
              indexByName: {
                Type: 0,
                CVE: 1,
                Source: 2,
                Version: 3,
                CVSS: 4,
                Package: 5,
                Installed: 6,
                'Fixed In': 7,
                'SLO Remaining': 8,
                Risk: 9,
                Tool: 10,
                Status: 11,
                Actions: 12,
              },
            },
          },
          removeSubstringTransform({
            substring: GITHUB_PREFIX,
            fieldName: 'Source',
          }),
        ],
      })
    )
    .setHoverHeader(true)
    .setDisplayMode('transparent')
    .setThresholds(CVSS_SCORE_THRESHOLDS)
    .setOverrides((overridesBuilder) => {
      overridesBuilder
        .matchFieldsWithName('Status')
        .overrideCustomFieldConfig('width', 90)
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorText,
        })
        .overrideMappings(STATUS_MAPPINGS);
      overridesBuilder
        .matchFieldsWithName('CVE')
        .overrideCustomFieldConfig('width', 150)
        .overrideLinks([
          {
            title: '${__data.fields.CVE}',
            url: `${CVE_DETAILS_ROUTE}/\${__data.fields.CVE}`,
          },
        ]);
      overridesBuilder.matchFieldsWithName('cveId').overrideCustomFieldConfig('hidden', true);

      overridesBuilder.matchFieldsWithName('Package').overrideCustomFieldConfig('cellOptions', {
        // @ts-ignore
        type: 'custom',
        cellComponent: PackageCell,
      });

      overridesBuilder.matchFieldsWithName('Source').overrideLinks([
        {
          title: '${__data.fields.Source} overview',
          url: `${SOURCES_ROUTE}/\${__data.fields.sourceId}`,
        },
      ]);
      overridesBuilder.matchFieldsWithName('sourceId').overrideCustomFieldConfig('hidden', true);
      overridesBuilder.matchFieldsWithName('Version').overrideLinks([
        {
          title: '${__data.fields.Source}@${__data.fields.Version} overview',
          url: `${SOURCES_ROUTE}/\${__data.fields.sourceId}/version/\${__data.fields.versionId}?var-cve=\${__data.fields.CVE}`,
        },
      ]);
      overridesBuilder.matchFieldsWithName('versionId').overrideCustomFieldConfig('hidden', true);
      overridesBuilder
        .matchFieldsWithName('Type')
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          // @ts-ignore
          type: 'custom',
          cellComponent: SourceTypeCell,
        })
        .overrideCustomFieldConfig('width', 40);

      overridesBuilder
        .matchFieldsWithName('Tool')
        .overrideCustomFieldConfig('width', 80)
        .overrideCustomFieldConfig('align', 'center')
        .overrideMappings(TOOL_MAPPINGS);
      overridesBuilder
        .matchFieldsWithName('CVSS')
        .overrideDecimals(1)
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          // @ts-ignore
          type: 'custom',
          cellComponent: ColorBoxCell,
        })
        .overrideThresholds(CVSS_SCORE_THRESHOLDS)
        .overrideCustomFieldConfig('width', 70);

      overridesBuilder
        .matchFieldsWithName('Installed')
        .overrideCustomFieldConfig('width', 120)
        .overrideCustomFieldConfig('align', 'center');
      overridesBuilder
        .matchFieldsWithName('Fixed In')
        .overrideCustomFieldConfig('width', 120)
        .overrideCustomFieldConfig('align', 'center');

      overridesBuilder
        .matchFieldsWithName('SLO Remaining')
        .overrideCustomFieldConfig('width', 120)
        .overrideCustomFieldConfig('align', 'center')
        .overrideThresholds(SLO_REMAINING_THRESHOLDS)
        .overrideCustomFieldConfig('cellOptions', {
          type: TableCellDisplayMode.ColorText,
        })
        .overrideUnit('days');

      overridesBuilder
        .matchFieldsWithName('Risk')
        .overrideDecimals(1)
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          // @ts-ignore
          type: 'custom',
          cellComponent: ColorBoxCell,
        })
        .overrideMappings(RISK_LEVEL_MAPPINGS)
        .overrideCustomFieldConfig('width', 116);
      overridesBuilder
        .matchFieldsWithName('Actions')
        .overrideCustomFieldConfig('align', 'center')
        .overrideCustomFieldConfig('cellOptions', {
          // @ts-ignore
          type: 'custom',
          cellComponent: ActionsCell,
        })
        .overrideCustomFieldConfig('width', 100);
    })
    .build();
