import { useSuspenseQuery } from '@apollo/client';
import { TextLink, Box, Text } from '@grafana/ui';
import { GetRelatedIssuesQuery } from '__generated__/graphql';
import { ISSUES_ROUTE } from 'shared/constants';

import { capitalizeFirstLetter } from '../utils';

import { GET_RELATED_ISSUES } from './IssueReportQueries';

interface RelatedIssuesType {
  issueID: string;
  versionID: string;
  cve: string;
}

export const RelatedIssues = ({ issueID, versionID, cve }: RelatedIssuesType) => {
  const { data } = useSuspenseQuery<GetRelatedIssuesQuery>(GET_RELATED_ISSUES, {
    variables: {
      filters: {
        versionId: versionID,
        cve,
      },
    },
  });

  const relatedIssues = data.issues.response.filter((issue) => issue.id.toString() !== issueID);

  return relatedIssues.length ? (
    <Box backgroundColor="canvas" borderColor="strong" borderStyle="solid" marginTop={2} padding={1.5}>
      <Text element="h3">Similar Issues</Text>
      <Box element="ul" marginTop={1} paddingLeft={2}>
        {relatedIssues.map((issue, index) => (
          <li key={index}>
            <TextLink href={`${ISSUES_ROUTE}/${issue.id}/report`} inline>
              {`${capitalizeFirstLetter(issue.tool.name)} detected ${cve} in ${issue.package}`}
            </TextLink>
          </li>
        ))}
      </Box>
    </Box>
  ) : null;
};
