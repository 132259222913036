import { TypedDocumentNode, gql } from '@apollo/client';
import { GetCveReportQuery, GetCveReportQueryVariables } from '__generated__/graphql';

export const GET_CVE_REPORT: TypedDocumentNode<GetCveReportQuery, GetCveReportQueryVariables> = gql`
  query getCveReport($filters: CveFilters, $first: Int, $after: Int) {
    cves(filters: $filters, first: $first, after: $after) {
      response {
        id
        cve
        cvssScore
        cvssV3
        description
        severity
        publishedDate
        lastModifiedDate
        url
        issues {
          package
          tool {
            name
          }
        }
      }
    }
  }
`;
