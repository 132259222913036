import {
  CustomVariable,
  sceneGraph,
  SceneObjectState,
  SceneObjectBase,
  SceneComponentProps,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { Pagination, Stack } from '@grafana/ui';

interface PaginatorState extends SceneObjectState {
  first: string;
  after: string;
}

export class Paginator extends SceneObjectBase<PaginatorState> {
  static Component = PaginatorRenderer;

  protected _variableDependency = new VariableDependencyConfig(this, {
    statePaths: ['first', 'after'],
  });

  public constructor(state: PaginatorState) {
    super({ ...state });
  }

  onNavigate = (page: number) => {
    const { first, after } = this.state;
    const offsetVar = sceneGraph.lookupVariable(after.slice(2, -1), this)! as CustomVariable;
    const pageSize = parseInt(sceneGraph.interpolate(this, first), 10);

    offsetVar.changeValueTo(pageSize * (page - 1));
  };
}

function PaginatorRenderer({ model }: SceneComponentProps<Paginator>) {
  const { first, after } = model.useState();
  const { data } = sceneGraph.getData(model).useState();

  if (!data || data.state !== 'Done') {
    return null;
  }

  const { totalCount } = JSON.parse(data?.series[0].fields[0].values[0]);
  const pageSize = parseInt(sceneGraph.interpolate(model, first), 10);
  const offset = parseInt(sceneGraph.interpolate(model, after), 10);

  const currentPage = Math.ceil((offset + 1) / pageSize);
  const numberOfPages = Math.ceil(totalCount / pageSize);

  return numberOfPages > 1 ? (
    <Stack justifyContent="right">
      <Pagination currentPage={currentPage} numberOfPages={numberOfPages} onNavigate={model.onNavigate} />
    </Stack>
  ) : null;
}
