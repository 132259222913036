import { Switch, Box, Text, Divider } from '@grafana/ui';
import { Control, Controller, FormState, Path } from 'react-hook-form';

import { ContainerSourceFormType } from './ConfigureContainer';
import { RepositorySourceFormType } from './ConfigureRepository';
import {
  GithubAssigneesField,
  GithubLabelsField,
  GithubRepositoryNameField,
  GithubSubIssuesField,
  SlackChannelField,
  SlackSeveritiesField,
} from './Fields';

type ConfigureAlertingSupportedTypes = ContainerSourceFormType | RepositorySourceFormType;

interface ConfigureAlertingType<T extends ConfigureAlertingSupportedTypes> {
  control: Control<T>;
  formState: FormState<T>;
  slackAlertsEnabled: boolean;
  githubIssuesEnabled: boolean;
}

export const ConfigureAlerting = <T extends ConfigureAlertingSupportedTypes>({
  control,
  formState,
  slackAlertsEnabled,
  githubIssuesEnabled,
}: ConfigureAlertingType<T>) => {
  return (
    <>
      <Box display="flex" direction="row" gap={1} marginBottom={1} alignItems="center">
        <Text variant="body">Enable Slack Alerts</Text>
        <Controller
          name={'slackAlertsEnabled' as Path<T>}
          control={control}
          render={({ field }) => <Switch {...field} />}
        />
      </Box>
      {slackAlertsEnabled && (
        <>
          <SlackChannelField control={control} formState={formState} required={slackAlertsEnabled} />
          <SlackSeveritiesField control={control} formState={formState} required={slackAlertsEnabled} />
        </>
      )}
      <Divider />
      <Box display="flex" direction="row" gap={1} marginBottom={1} alignItems="center">
        <Text variant="body">
          Enable GitHub Issues{' '}
          <Text variant="body" color="secondary" italic>
            (coming soon)
          </Text>
        </Text>
        <Controller
          name={'githubIssuesEnabled' as Path<T>}
          control={control}
          render={({ field }) => <Switch {...field} disabled />}
        />
      </Box>
      {githubIssuesEnabled && (
        <>
          <GithubRepositoryNameField control={control} formState={formState} required={githubIssuesEnabled} />
          <GithubSubIssuesField control={control} formState={formState} />
          <GithubLabelsField control={control} formState={formState} />
          <GithubAssigneesField control={control} formState={formState} />
        </>
      )}
    </>
  );
};
