import { css } from '@emotion/css';
import { CollapsableSection, useStyles2, ErrorBoundary, Alert, LoadingPlaceholder } from '@grafana/ui';
import { Suspense, useState } from 'react';

import { DeleteSource } from './DeleteSource';
import { ManageGroups } from './ManageGroups';
import { ManageVersions } from './ManageVersions';

interface ManageSourceType {
  sourceID: string;
}

export const ManageSource = ({ sourceID }: ManageSourceType) => {
  const styles = useStyles2(getStyles);
  const [isGeneralOpen, setIsGeneralOpen] = useState<boolean>(true);
  const [isVersionsOpen, setIsVersionsOpen] = useState<boolean>(true);
  const [isDangerZoneOpen, setIsDangerZoneOpen] = useState<boolean>(true);

  return (
    <>
      <ErrorBoundary>
        {({ error }) => {
          if (error) {
            return (
              <Alert
                title="Error: failed to load source settings"
                onRemove={() => window.location.reload()}
                buttonContent="Reload"
              >
                {error.message}
              </Alert>
            );
          }
          return (
            <div className={styles.container}>
              <CollapsableSection label="General" isOpen={isGeneralOpen} onToggle={setIsGeneralOpen}>
                <Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
                  <ManageGroups sourceID={sourceID} />
                </Suspense>
              </CollapsableSection>
              <CollapsableSection label="Versions" isOpen={isVersionsOpen} onToggle={setIsVersionsOpen}>
                <Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
                  <ManageVersions sourceID={sourceID} />
                </Suspense>
              </CollapsableSection>
              <CollapsableSection label="Danger Zone" isOpen={isDangerZoneOpen} onToggle={setIsDangerZoneOpen}>
                <Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
                  <DeleteSource sourceID={sourceID} />
                </Suspense>
              </CollapsableSection>
            </div>
          );
        }}
      </ErrorBoundary>
    </>
  );
};

const getStyles = () => ({
  container: css({
    maxWidth: '480px',
  }),
});
