export enum BUG_BOUNTY_SQUADS {
  Grafana = 'Grafana',
  Loki = 'Loki',
  Tempo = 'Tempo',
  Mimir = 'Mimir',
  Pyroscope = 'Pyroscope',
  Incidents = 'Incidents',
  K6 = 'K6',
  ML = 'Machine Learning',
  SM = 'Synthetic Monitoring',
  OnCall = 'On Call',
  Slo = 'SLO',
  IAM = 'Identity and Access Management',
  Alerting = 'Alerting',
}
