import { css, cx } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';
import { useMemo } from 'react';

import { hexToRgba } from '../utils';

interface SeverityBoxType {
  value: number;
}

export const SeverityBox = ({ value }: SeverityBoxType) => {
  const styles = useStyles2(getStyles);

  let background = useMemo(() => {
    let background = styles.none;
    if (value >= 9) {
      background = styles.critical;
    } else if (value >= 7) {
      background = styles.high;
    } else if (value >= 4) {
      background = styles.medium;
    } else if (value >= 0) {
      background = styles.low;
    }
    return background;
  }, [value, styles]);

  return <div className={cx(styles.box, background)}>{value || 0.0}</div>;
};

const getStyles = (theme: GrafanaTheme2) => ({
  box: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderRadius: theme.shape.radius.default,
    minWidth: '40px',
    color: theme.colors.text.maxContrast,
  }),
  maximum: css({
    border: `1px solid ${theme.visualization.getColorByName('dark-purple')}`,
    background: hexToRgba(theme.visualization.getColorByName('dark-purple'), 0.08),
  }),
  critical: css({
    border: `1px solid ${theme.visualization.getColorByName('dark-red')}`,
    background: hexToRgba(theme.visualization.getColorByName('dark-red'), 0.08),
  }),
  high: css({
    border: `1px solid ${theme.visualization.getColorByName('dark-orange')}`,
    background: hexToRgba(theme.visualization.getColorByName('dark-orange'), 0.08),
  }),
  medium: css({
    border: `1px solid ${theme.visualization.getColorByName('dark-yellow')}`,
    background: hexToRgba(theme.visualization.getColorByName('dark-yellow'), 0.08),
  }),
  low: css({
    border: `1px solid ${theme.visualization.getColorByName('grey')}`,
    background: hexToRgba(theme.visualization.getColorByName('grey'), 0.08),
  }),
  none: css({
    border: `1px solid ${theme.colors.secondary.border}`,
    background: hexToRgba(theme.colors.secondary.border, 0.08),
  }),
});
