import { PanelBuilders, SceneDataTransformer } from '@grafana/scenes';
import { BigValueColorMode } from '@grafana/schema';

export const getCveTotalsStat = () =>
  PanelBuilders.stat()
    .setData(
      new SceneDataTransformer({
        transformations: [
          {
            id: 'organize',
            options: {
              indexByName: {
                critical: 0,
                high: 1,
                medium: 2,
                low: 3,
              },
            },
          },
        ],
      })
    )
    .setTitle(`Active CVE Totals`)
    .setDisplayMode('transparent')
    .setOption('colorMode', BigValueColorMode.Background)
    .setOverrides((overridesBuilder) => {
      overridesBuilder.matchFieldsWithName('critical').overrideDisplayName('Critical').overrideColor({
        mode: 'fixed',
        fixedColor: 'red',
      });
      overridesBuilder.matchFieldsWithName('high').overrideDisplayName('High').overrideColor({
        mode: 'fixed',
        fixedColor: 'orange',
      });
      overridesBuilder.matchFieldsWithName('medium').overrideDisplayName('Medium').overrideColor({
        mode: 'fixed',
        fixedColor: 'yellow',
      });
      overridesBuilder.matchFieldsWithName('low').overrideDisplayName('Low').overrideColor({
        mode: 'fixed',
        fixedColor: 'grey',
      });
    })
    .build();
