import { SceneAppPage, SceneAppPageLike } from '@grafana/scenes';
import { SourceHeader } from 'scenes/components/SourceHeader';
import { SOURCES_ROUTE } from 'shared/constants';

import { getCompareTab } from './getCompareTab';
import { getManageTab } from './getManageTab';
import { getVersionsTab } from './getVersionsTab';

interface GetSourcesPageType {
  apiUrl: string;
  sourceID: string;
  parent: SceneAppPageLike;
}

export const getSourceDrilldown = ({ apiUrl, sourceID, parent }: GetSourcesPageType) =>
  new SceneAppPage({
    title: 'Source',
    renderTitle: () => SourceHeader({ sourceID }),
    key: 'sourceDrilldown',
    url: `${SOURCES_ROUTE}/${sourceID}`,
    getParentPage: () => parent,
    tabs: [
      getVersionsTab({ apiUrl, sourceID }),
      getCompareTab({ apiUrl, sourceID }),
      getManageTab({ apiUrl, sourceID }),
    ],
  });
