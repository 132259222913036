import { PanelBuilders, SceneDataTransformer } from '@grafana/scenes';
import { BigValueColorMode, BigValueTextMode } from '@grafana/schema';

export const getCveTotalsOutOfSloStat = () =>
  PanelBuilders.stat()
    .setData(
      new SceneDataTransformer({
        transformations: [
          {
            id: 'filterByValue',
            options: {
              filters: [
                {
                  config: {
                    id: 'lowerOrEqual',
                    options: {
                      value: 0,
                    },
                  },
                  fieldName: 'sloRemaining',
                },
              ],
              match: 'any',
              type: 'include',
            },
          },
          {
            id: 'groupBy',
            options: {
              fields: {
                cve: {
                  aggregations: ['distinctCount'],
                  operation: 'aggregate',
                },
                severity: {
                  operation: 'groupby',
                },
                sloRemaining: {
                  aggregations: ['min'],
                },
              },
            },
          },
          {
            id: 'rowsToFields',
            options: {
              mappings: [
                {
                  fieldName: 'severity',
                  handlerKey: 'field.name',
                },
                {
                  fieldName: 'cve (distinctCount)',
                  handlerKey: 'field.value',
                },
              ],
            },
          },
          {
            id: 'organize',
            options: {
              excludeByName: {
                UNKNOWN: true,
              },
              indexByName: {
                CRITICAL: 0,
                HIGH: 1,
                LOW: 3,
                MEDIUM: 2,
                UNKNOWN: 4,
              },
            },
          },
        ],
      })
    )
    .setTitle(`CVE Totals Out of SLO`)
    .setDisplayMode('transparent')
    .setOption('colorMode', BigValueColorMode.Background)
    .setOption('textMode', BigValueTextMode.ValueAndName)
    .setOption('wideLayout', false)
    .setNoValue('No CVEs out of SLO')
    .setColor({
      mode: 'fixed',
      fixedColor: 'green',
    })
    .setOverrides((overridesBuilder) => {
      overridesBuilder.matchFieldsWithName('CRITICAL').overrideDisplayName('Critical').overrideColor({
        mode: 'fixed',
        fixedColor: 'red',
      });
      overridesBuilder.matchFieldsWithName('HIGH').overrideDisplayName('High').overrideColor({
        mode: 'fixed',
        fixedColor: 'red',
      });
      overridesBuilder.matchFieldsWithName('MEDIUM').overrideDisplayName('Medium').overrideColor({
        mode: 'fixed',
        fixedColor: 'red',
      });
      overridesBuilder.matchFieldsWithName('LOW').overrideDisplayName('Low').overrideColor({
        mode: 'fixed',
        fixedColor: 'red',
      });
    })
    .build();
