import { useMutation } from '@apollo/client';
import { AppEvents, SelectableValue } from '@grafana/data';
import { getAppEvents, config } from '@grafana/runtime';
import { Select, Stack, Text } from '@grafana/ui';
import { Role, UpdateUserRoleMutation, User } from '__generated__/graphql';

import { UPDATE_USER_ROLE } from './AccessControlMutations';
import { GET_USERS } from './AccessControlQueries';

interface UserRowType {
  user: User;
}

export const UserRow = ({ user }: UserRowType) => {
  const [updateUser] = useMutation<UpdateUserRoleMutation>(UPDATE_USER_ROLE, {
    refetchQueries: [GET_USERS],
    ignoreResults: true,
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to update user role`],
      });
    },
  });

  const onUpdateRole = ({ value }: SelectableValue) => {
    updateUser({
      variables: {
        input: {
          id: user.id,
          role: value,
        },
      },
    });
  };

  const isSelf = config.bootData.user.login === user.name;

  return (
    <tr>
      <th>
        <Stack alignItems="center">
          <Text>{user.name}</Text>
        </Stack>
      </th>
      <td>
        <Stack>
          <Select
            options={[
              { label: 'Reader', value: Role.Reader, description: 'View' },
              { label: 'Writer', value: Role.Writer, description: 'View, Edit' },
              { label: 'Administrator', value: Role.Administrator, description: 'View, Edit, Delete' },
            ]}
            value={user.role}
            width={20}
            onChange={onUpdateRole}
            disabled={isSelf}
          />
        </Stack>
      </td>
    </tr>
  );
};
