import { SceneQueryRunner } from '@grafana/scenes';
import { getPrometheusDatasource } from 'scenes/datasources/prometheus/prometheusDatasource';

export const getSloHistoryQueryRunner = (group: string) =>
  new SceneQueryRunner({
    datasource: getPrometheusDatasource(),
    queries: [
      {
        refId: 'F',
        expr: `(1 - ( 
            (sum by (group) (vulns_by_group{database="svo", cluster="\${cluster}", group="${group}", severity=~"critical", slo_expired="True"}) )
            / 
            (sum by (group) (vulns_by_group{database="svo", cluster="\${cluster}", group="${group}", severity=~"critical"})  )
        )) * 100`,
      },
      {
        refId: 'G',
        expr: `(1 - ( 
              (sum by (group) (vulns_by_group{database="svo", cluster="\${cluster}", group="${group}", severity=~"high", slo_expired="True"}) )
              / 
              (sum by (group) (vulns_by_group{database="svo", cluster="\${cluster}", group="${group}", severity=~"high"})  )
          )) * 100`,
      },
      {
        refId: 'H',
        expr: `(1 - ( 
              (sum by (group) (vulns_by_group{database="svo", cluster="\${cluster}", group="${group}", severity=~"medium", slo_expired="True"}) )
              / 
              (sum by (group) (vulns_by_group{database="svo", cluster="\${cluster}", group="${group}", severity=~"medium"})  )
          )) * 100`,
      },
      {
        refId: 'I',
        expr: `(1 - ( 
              (sum by (group) (vulns_by_group{database="svo", cluster="\${cluster}", group="${group}", severity=~"low", slo_expired="True"}) )
              / 
              (sum by (group) (vulns_by_group{database="svo", cluster="\${cluster}", group="${group}", severity=~"low"})  )
          )) * 100`,
      },
    ],
  });
