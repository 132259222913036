// Based heavily on TagItem from @grafana/ui

import { css } from '@emotion/css';
import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import { useStyles2, IconButton, getTagColorsFromName } from '@grafana/ui';
import { useMemo } from 'react';

interface Props {
  group: SelectableValue;
  disabled?: boolean;
  onRemove: (tag: SelectableValue) => void;
}

export const GroupItem = ({ group, disabled, onRemove }: Props) => {
  const { color, borderColor } = useMemo(() => getTagColorsFromName(group.name), [group.name]);
  const styles = useStyles2(getStyles);

  return (
    <li className={styles.itemStyle} style={{ backgroundColor: color, borderColor }}>
      <span className={styles.nameStyle}>{group.name}</span>
      <IconButton
        name="times"
        size="lg"
        disabled={disabled}
        tooltip={`Remove from group ${group.name}`}
        onClick={() => onRemove(group)}
        className={styles.buttonStyles}
      />
    </li>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  const height = theme.spacing.gridSize * 3;

  return {
    itemStyle: css({
      display: 'flex',
      gap: '3px',
      alignItems: 'center',
      height: `${height}px`,
      lineHeight: `${height - 2}px`,
      color: '#fff',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: theme.shape.radius.default,
      padding: `0 ${theme.spacing(0.5)}`,
      whiteSpace: 'nowrap',
      textShadow: 'none',
      fontWeight: 500,
      fontSize: theme.typography.bodySmall.fontSize,
    }),
    nameStyle: css({
      maxWidth: '25ch',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    }),
    buttonStyles: css({
      margin: 0,
      '&:hover::before': {
        display: 'none',
      },
    }),
  };
};
