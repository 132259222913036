import { SceneQueryRunner } from '@grafana/scenes';
import { getInfinityGQLQuery } from 'scenes/datasources/infinity';

export const getWeeklyCvesPatchedQueryRunner = (apiUrl: string) =>
  new SceneQueryRunner(
    getInfinityGQLQuery([
      {
        refId: 'D',
        url: apiUrl,
        root_selector: 'data.summary.weeklyPatchedCveCount',
        columns: [
          {
            selector: 'patchedCveCount',
            text: 'patchedCveCount',
            type: 'number',
          },
          {
            selector: 'severity',
            text: 'severity',
            type: 'string',
          },
          {
            selector: 'week',
            text: 'week',
            type: 'timestamp',
          },
        ],
        filterExpression: `severity != 'UNKNOWN'`,
        url_options: {
          body_graphql_query: `
            query getSummary($filters: SummaryFilters) {
              summary(filters: $filters) {
                weeklyPatchedCveCount {
                  week
                  severity
                  patchedCveCount
                }
              }
            }
          `,
          body_graphql_variables: JSON.stringify({
            filters: {
              groupId: '$group',
              latestOnly: '$latestOnly',
            },
          }),
        },
      },
    ])
  );
