import { EmbeddedScene, SceneAppPage, SceneFlexItem, SceneFlexLayout, SceneReactObject } from '@grafana/scenes';
import { IssueReportContainer } from 'scenes/components/IssueReport';
import { ISSUES_ROUTE } from 'shared/constants';

export const getIssueCveDrilldown = (apiUrl: string, issueID: string) =>
  new SceneAppPage({
    title: 'Issue Report',
    titleIcon: 'book',
    key: 'issueReportDrilldown',
    url: `${ISSUES_ROUTE}/${issueID}/report`,
    getScene: () => {
      return new EmbeddedScene({
        key: 'cves',
        body: new SceneFlexLayout({
          direction: 'column',
          maxWidth: '100%',
          children: [
            new SceneFlexItem({
              body: new SceneReactObject({
                component: () => IssueReportContainer({ issueID }),
              }),
            }),
          ],
        }),
      });
    },
  });
