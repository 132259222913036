import { getAppEvents } from '@grafana/runtime';
import {
  EmbeddedScene,
  SceneAppPage,
  SceneFlexLayout,
  SceneVariableSet,
  VariableValueSelectors,
  CustomVariable,
  SceneFlexItem,
  sceneGraph,
} from '@grafana/scenes';
import { VariableHide } from '@grafana/schema';
import { Icon } from '@grafana/ui';
import { CveSortOrder } from '__generated__/graphql';
import { ActWhenVariablesChanged } from 'scenes/controls/ActWhenVariablesChanged';
import { TextInputVariable } from 'scenes/controls/TextInputVariable';
import { Cves } from 'scenes/customScenes/Cves';
import { Paginator } from 'scenes/customScenes/Paginator';
import { CVES_ROUTE, CVE_DETAILS_ROUTE } from 'shared/constants';
import { CveCardPackageClickedEvent } from 'shared/events';

import { getCveDrilldown } from './getCveDrilldown';
import { getCvesCountQueryRunner } from './queryRunners';

interface GetExploreCvesTabType {
  apiUrl: string;
}

export const getExploreCvesTab = ({ apiUrl }: GetExploreCvesTabType) => {
  const packageVariable = new TextInputVariable({
    name: 'package',
    prefix: <Icon name="gf-layout-simple" />,
    placeholder: 'Search Packages',
    width: 40,
    hide: VariableHide.hideLabel,
  });

  getAppEvents().subscribe(CveCardPackageClickedEvent, ({ payload }) => {
    packageVariable.setValue(payload.package);
  });

  return new SceneAppPage({
    title: 'Explore CVEs',
    titleIcon: 'book-open',
    key: 'exploreCves',
    url: CVES_ROUTE,
    getScene: () => {
      return new EmbeddedScene({
        key: 'cves',
        $variables: new SceneVariableSet({
          variables: [
            new TextInputVariable({
              name: 'cve',
              prefix: <Icon name="search" />,
              placeholder: 'Search CVEs',
              width: 40,
              hide: VariableHide.hideLabel,
            }),
            packageVariable,
            new CustomVariable({
              name: 'sortBy',
              label: 'Sort By',
              value: '',
              query: `CVSS Score : ${CveSortOrder.ScoreDesc},Occurances : ${CveSortOrder.OccurancesDesc},Publish Date : ${CveSortOrder.DateIssuedDesc}`,
              includeAll: false,
            }),
            new CustomVariable({
              name: 'cveFirst',
              value: 12,
              hide: VariableHide.hideVariable,
              skipUrlSync: true,
              query: '12',
            }),
            new CustomVariable({
              name: 'cveAfter',
              value: 0,
              hide: VariableHide.hideVariable,
              skipUrlSync: true,
              query: '0',
            }),
          ],
        }),
        $behaviors: [
          new ActWhenVariablesChanged({
            variableNames: ['cve', 'package'],
            onChange: (variable) => {
              const after = sceneGraph.lookupVariable('cveAfter', variable) as CustomVariable;
              after.setState({ value: 0 });
            },
          }),
        ],
        body: new SceneFlexLayout({
          direction: 'column',
          children: [
            new SceneFlexItem({
              height: '100%',
              body: new Cves({
                cve: '${cve}',
                package: '${package}',
                sortBy: '${sortBy}',
                cveFirst: '${cveFirst}',
                cveAfter: '${cveAfter}',
              }),
            }),
            new SceneFlexItem({
              $data: getCvesCountQueryRunner(apiUrl),
              body: new Paginator({
                first: '${cveFirst}',
                after: '${cveAfter}',
              }),
            }),
          ],
        }),
        controls: [new VariableValueSelectors({})],
      });
    },
    drilldowns: [
      {
        routePath: `${CVE_DETAILS_ROUTE}/:cve`,
        getPage(routeMatch, parent) {
          const cve = routeMatch.params.cve;
          return getCveDrilldown({ apiUrl, cve, parent });
        },
      },
    ],
  });
};
