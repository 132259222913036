import { useLazyQuery, useQuery } from '@apollo/client';
import { css } from '@emotion/css';
import { AppEvents, GrafanaTheme2, SelectableValue } from '@grafana/data';
import { getAppEvents, usePluginInteractionReporter } from '@grafana/runtime';
import {
  Button,
  DatePickerWithInput,
  Icon,
  InlineField,
  Input,
  MultiSelect,
  Stack,
  Text,
  useStyles2,
} from '@grafana/ui';
import { GetReleasePlanDataQuery, GetSourceListQuery, Source } from '__generated__/graphql';
import { useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { GITHUB_PREFIX, PLAN_RELEASE_CLICK } from 'shared/constants';

import { BuildReleasePlan } from './BuildReleasePlan';
import { GET_RELEASE_PLAN_DATA, GET_SOURCE_LIST } from './PlanReleaseQueries';

const NO_GROUP = 'No Group';

const getTomorrow = () => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

interface FormType {
  sourceIds: Array<SelectableValue<number>>;
  releaseDate: Date | string;
  bufferDays: number;
}

export const PlanRelease = () => {
  const styles = useStyles2(getStyles);
  const report = usePluginInteractionReporter();
  const tomorrow = getTomorrow();

  const [sources, setSources] = useState<Source[]>([]);
  const [releaseDate, setReleaseDate] = useState<Date>(tomorrow);
  const [bufferDays, setBufferDays] = useState<number>(0);

  const { data: sourceList, loading: loadingSourceList } = useQuery<GetSourceListQuery>(GET_SOURCE_LIST, {
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to fetch sources`],
      });
    },
  });

  const [getReleasePlanData, { loading }] = useLazyQuery<GetReleasePlanDataQuery>(GET_RELEASE_PLAN_DATA, {
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to fetch plan data`],
      });
    },
    onCompleted(data) {
      setSources(data.sources.response as Source[]);
    },
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormType>({
    defaultValues: {
      releaseDate: tomorrow,
      bufferDays: 14,
    },
  });

  const onSubmit: SubmitHandler<FormType> = (formData) => {
    getReleasePlanData({
      variables: {
        filters: {
          sourceIds: formData.sourceIds.map((sv) => sv.value),
        },
      },
    });
    setReleaseDate(new Date(formData.releaseDate));
    setBufferDays(Number(formData.bufferDays));
    report(PLAN_RELEASE_CLICK, {
      source_ids: formData.sourceIds,
    });
  };

  const groupOptions = useMemo(() => {
    const sourceOptions =
      sourceList?.sources.response.reduce(
        (acc, source) => {
          const groups = source.groups.length ? source.groups.map((group) => group.name) : [NO_GROUP];
          const option = {
            label: source.name.replace(GITHUB_PREFIX, ''),
            value: source.id,
            icon: source.type === 'repository' ? 'github' : 'docker',
          };

          groups.forEach((group) => {
            if (group in acc) {
              acc[group].push(option);
            } else {
              acc[group] = [option];
            }
          });

          return acc;
        },
        { [NO_GROUP]: [] } as { [g: string]: Array<SelectableValue<string>> }
      ) || {};

    return Object.entries(sourceOptions).map(([group, sources]) => ({
      label: group,
      options: sources,
    }));
  }, [sourceList]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <Stack alignItems="center" gap={2}>
          <InlineField label="Sources" className={styles.input}>
            <Controller
              name="sourceIds"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <MultiSelect
                  {...field}
                  allowCustomValue={false}
                  placeholder="Select sources"
                  options={groupOptions}
                  closeMenuOnSelect={false}
                  isLoading={loadingSourceList}
                  isClearable
                  width={40}
                  invalid={!!errors.sourceIds}
                  noOptionsMessage="No sources"
                />
              )}
            />
          </InlineField>
          <InlineField label="Target Release Date" className={styles.input}>
            <Controller
              name="releaseDate"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <DatePickerWithInput {...field} width={14} minDate={tomorrow} />
              )}
            />
          </InlineField>
          <InlineField
            label="Buffer Time"
            tooltip="Include CVEs going out of SLO before the target release date with an added buffer"
            className={styles.input}
          >
            <Input {...register('bufferDays', { required: true })} type="number" suffix="days" width={12} />
          </InlineField>
          <Button type="submit" size="sm">
            Generate Plan
          </Button>
          {loading && <Icon name="fa fa-spinner" />}
        </Stack>
      </form>
      {sources.length ? (
        <Stack direction="column">
          {sources.map((source, index) => (
            <BuildReleasePlan source={source} releaseDate={releaseDate} bufferDays={bufferDays} key={index} />
          ))}
        </Stack>
      ) : (
        <Stack justifyContent="center" alignItems="center" flex="1">
          <Text variant="h4" color="secondary">
            Select sources and a release date to see a plan
          </Text>
        </Stack>
      )}
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  form: css({
    paddingBottom: theme.spacing(1),
  }),
  input: css({
    margin: theme.spacing(0),
  }),
});
