import { SceneQueryRunner } from '@grafana/scenes';
import { getInfinityGQLQuery } from 'scenes/datasources/infinity';

export const getIssuesCountQueryRunner = (apiUrl: string) =>
  new SceneQueryRunner(
    getInfinityGQLQuery([
      {
        refId: 'B',
        url: apiUrl,
        root_selector: 'data.issues',
        url_options: {
          body_graphql_query: `
             query getIssues($filters: IssueFilters, $first: Int, $after: Int) {
                  issues(filters: $filters, first: $first, after: $after) {
                      metadata {
                        totalCount
                      }
                  }
              }
            `,
          body_graphql_variables: JSON.stringify({
            filters: {
              cve: '$cve',
              package: '$package',
              sourceId: '$source',
              versionId: '$version',
              risk: '$risk',
              hideDismissed: '$hideDismissed',
              type: '$type',
              latestVersionsOnly: '$showLatestOnly',
            },
            first: '$issueTableFirst',
            after: '$issueTableAfter',
          }),
        },
      },
    ])
  );
