import { useQuery } from '@apollo/client';
import { Stack, Text } from '@grafana/ui';
import { GetCveHeaderQuery } from '__generated__/graphql';
import { CVE_LS_KEY } from 'shared/constants';

import { GET_CVE_HEADER } from './CveHeaderQueries';

interface CveHeaderType {
  cve: string;
}

export const CveHeader = ({ cve }: CveHeaderType) => {
  const { data, loading, error } = useQuery<GetCveHeaderQuery>(GET_CVE_HEADER, {
    variables: {
      filters: {
        cve,
      },
      first: 1,
    },
  });

  if (loading || error) {
    return null;
  }

  const { cve: cveNumber, title, id: cveId } = data!.cves.response[0];
  const capitalTitle = title.charAt(0).toUpperCase() + title.slice(1);

  // Set active CVE in local storage for exception form
  localStorage.setItem(CVE_LS_KEY, JSON.stringify({ cve: cveNumber, id: cveId }));

  return (
    <Stack direction="column" gap={1}>
      <Text element="h1">{cveNumber}</Text>
      <Text element="h4" color="secondary">
        {capitalTitle}
      </Text>
    </Stack>
  );
};
