import { TypedDocumentNode, gql } from '@apollo/client';
import { GetScanPayloadQuery, GetScanPayloadQueryVariables } from '__generated__/graphql';

export const GET_SCAN_PAYLOAD: TypedDocumentNode<GetScanPayloadQuery, GetScanPayloadQueryVariables> = gql`
  query getScanPayload($id: ID!) {
    version(id: $id) {
      id
      tag
      source {
        id
        name
        type
      }
    }
  }
`;
