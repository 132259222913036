import { config } from '@grafana/runtime';
import { DataQuery } from '@grafana/schema';

import { InfinityColumn, InfinityURLOptions } from './infinityTypes';

export const INFINITY_DATASOURCE_NAME = 'security-vulnerability-observability-infinity';

export const getInfinityDatasource = () => config.datasources[INFINITY_DATASOURCE_NAME];

export const baseOpts = {
  type: 'graphql',
  source: 'url',
  format: 'dataframe',
  parser: 'backend',
};

export const urlOpts = {
  body_content_type: 'application/json',
  body_type: 'graphql',
  method: 'POST',
};

interface InfinityDataQuery extends DataQuery {
  url: string;
  url_options: Partial<InfinityURLOptions>;
  root_selector?: String;
  columns?: InfinityColumn[];
  computed_columns?: InfinityColumn[];
  filterExpression?: string;
}

export const getInfinityGQLQuery = (queries: InfinityDataQuery[]) => ({
  datasource: getInfinityDatasource(),
  queries: queries.map(({ url_options, ...query }) => ({
    ...baseOpts,
    ...query,
    url_options: {
      ...urlOpts,
      ...url_options,
    },
  })),
});
