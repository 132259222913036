import { TypedDocumentNode, gql } from '@apollo/client';
import { AddTargetsToCveExceptionMutation, AddTargetsToCveExceptionMutationVariables } from '__generated__/graphql';

export const ADD_TARGETS_TO_CVE_EXCEPTION: TypedDocumentNode<
  AddTargetsToCveExceptionMutation,
  AddTargetsToCveExceptionMutationVariables
> = gql`
  mutation addTargetsToCveException($input: AddTargetsToCveExceptionInput!) {
    addTargetsToCveException(input: $input)
  }
`;
