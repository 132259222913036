import { PanelBuilders, SceneDataTransformer } from '@grafana/scenes';

export const getMeanTimeToResolveTimeseries = () =>
  PanelBuilders.timeseries()
    .setData(
      new SceneDataTransformer({
        transformations: [
          {
            id: 'prepareTimeSeries',
            options: {
              format: 'multi',
            },
          },
        ],
      })
    )
    .setTitle(`Mean Time to Resolve CVEs`)
    .setDisplayMode('transparent')
    .setCustomFieldConfig('axisLabel', 'Days')
    .setCustomFieldConfig('lineWidth', 2)
    .setOverrides((overridesBuilder) => {
      overridesBuilder
        .matchFieldsWithName('meanTimeToResolution CRITICAL')
        .overrideDisplayName('Critical')
        .overrideColor({
          mode: 'fixed',
          fixedColor: 'red',
        });
      overridesBuilder.matchFieldsWithName('meanTimeToResolution HIGH').overrideDisplayName('High').overrideColor({
        mode: 'fixed',
        fixedColor: 'orange',
      });
      overridesBuilder.matchFieldsWithName('meanTimeToResolution MEDIUM').overrideDisplayName('Medium').overrideColor({
        mode: 'fixed',
        fixedColor: 'yellow',
      });
      overridesBuilder.matchFieldsWithName('meanTimeToResolution LOW').overrideDisplayName('Low').overrideColor({
        mode: 'fixed',
        fixedColor: 'grey',
      });
    })
    .build();
