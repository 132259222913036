import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { CustomCellRendererProps, useStyles2 } from '@grafana/ui';
import { memo } from 'react';

import { hexToRgba } from '../utils';

const ColorBoxCell = memo((props: CustomCellRendererProps) => {
  const styles = useStyles2(getStyles);

  const { text, color } = props.field.display!(props.value);

  return <div className={styles.box(color!)}>{text}</div>;
});

const getStyles = (theme: GrafanaTheme2) => ({
  box: (color: string) =>
    css({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: `${theme.spacing(0)} ${theme.spacing(1)}`,
      margin: `${theme.spacing(0)} ${theme.spacing(1)}`,
      borderRadius: theme.shape.radius.default,
      minWidth: '40px',
      border: `1px solid ${color}`,
      backgroundColor: hexToRgba(color, 0.08),
    }),
});

ColorBoxCell.displayName = 'ColorBoxCell';

export { ColorBoxCell };
