import { Alert, ErrorBoundary, LoadingPlaceholder } from '@grafana/ui';
import { Suspense, useDeferredValue } from 'react';

import { VersionSummaryCardGrid } from './VersionSummaryCardGrid';

interface VersionSummaryGridType {
  sourceID: string;
  tag: string;
}

export const VersionSummaryContainer = ({ sourceID, tag }: VersionSummaryGridType) => {
  const deferredTag = useDeferredValue(tag);

  return (
    <ErrorBoundary>
      {({ error }) => {
        if (error) {
          return (
            <Alert
              title="Error: failed to load versions"
              onRemove={() => window.location.reload()}
              buttonContent="Reload"
            >
              {error.message}
            </Alert>
          );
        }
        return (
          <Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
            <VersionSummaryCardGrid sourceID={sourceID} version={deferredTag} />
          </Suspense>
        );
      }}
    </ErrorBoundary>
  );
};
