import { useMutation, useSuspenseQuery } from '@apollo/client';
import { AppEvents, SelectableValue } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Field } from '@grafana/ui';
import { AddGroupMutation, GetAllGroupsQuery, GetGroupsQuery, RemoveGroupMutation } from '__generated__/graphql';
import { useCallback } from 'react';

import { GroupsInput } from '../GroupsInput';

import { ADD_GROUP, REMOVE_GROUP } from './ManageSourceMutations';
import { GET_ALL_GROUPS, GET_GROUPS } from './ManageSourceQueries';

interface ManageGroupsType {
  sourceID: string;
}

export const ManageGroups = ({ sourceID }: ManageGroupsType) => {
  const { data: sourceGroups } = useSuspenseQuery<GetGroupsQuery>(GET_GROUPS, {
    variables: {
      id: sourceID,
    },
  });

  const { data: allGroups } = useSuspenseQuery<GetAllGroupsQuery>(GET_ALL_GROUPS, {
    fetchPolicy: 'cache-and-network',
  });

  const [addGroup] = useMutation<AddGroupMutation>(ADD_GROUP, {
    refetchQueries: [GET_GROUPS],
    ignoreResults: true,
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to add group`],
      });
    },
  });
  const [removeGroup] = useMutation<RemoveGroupMutation>(REMOVE_GROUP, {
    refetchQueries: [GET_GROUPS],
    ignoreResults: true,
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to remove group`],
      });
    },
  });

  const onAddGroup = useCallback(
    (newGroup: string) => {
      addGroup({
        variables: {
          input: {
            name: newGroup,
            sourceId: sourceID,
          },
        },
      });
    },
    [sourceID, addGroup]
  );

  const onRemoveGroup = useCallback(
    (group: SelectableValue) => {
      removeGroup({
        variables: {
          input: {
            groupId: group.id,
            sourceId: sourceID,
          },
        },
      });
    },
    [sourceID, removeGroup]
  );

  const options = allGroups.groups.map((gp) => ({ label: gp.name, value: gp.id }));

  return (
    <Field label="Groups">
      <GroupsInput
        onAddGroup={onAddGroup}
        onRemoveGroup={onRemoveGroup}
        width={40}
        placeholder="New group (enter key to add)"
        groups={sourceGroups.source.groups}
        options={options}
      />
    </Field>
  );
};
