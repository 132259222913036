import { TypedDocumentNode, gql } from '@apollo/client';
import { GetCveHeaderQuery, GetCveHeaderQueryVariables } from '__generated__/graphql';

export const GET_CVE_HEADER: TypedDocumentNode<GetCveHeaderQuery, GetCveHeaderQueryVariables> = gql`
  query getCveHeader($filters: CveFilters, $first: Int, $after: Int) {
    cves(filters: $filters, first: $first, after: $after) {
      response {
        id
        cve
        title
        url
      }
    }
  }
`;
