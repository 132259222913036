import pluginJson from '../plugin.json';

export const PLUGIN_ID = pluginJson.id;
export const GQL_URL = `api/plugin-proxy/${PLUGIN_ID}/api-proxy/graphql/query`;
export const GITHUB_PREFIX = 'https://github.com/';

export const PATCH_ALL_THE_THINGS_REPO = `https://github.com/grafana/patch-all-the-things-configurations`;

// Page routes
export const PLUGIN_BASE_URL = `/a/${PLUGIN_ID}`;

export const ABOUT_ROUTE = PLUGIN_BASE_URL;
export const SOURCES_ROUTE = `${PLUGIN_BASE_URL}/sources`;
export const ISSUES_ROUTE = `${PLUGIN_BASE_URL}/issues`;
export const CVES_ROUTE = `${PLUGIN_BASE_URL}/cves`;
export const CVE_DETAILS_ROUTE = `${CVES_ROUTE}/details`;
export const CVE_EXCEPTIONS_ROUTE = `${CVES_ROUTE}/exceptions`;
export const CVE_EXCEPTIONS_CREATE_ROUTE = `${CVE_EXCEPTIONS_ROUTE}/create`;
export const CVE_EXCEPTION_DETAILS_ROUTE = `${CVE_EXCEPTIONS_ROUTE}/details`;
export const SLOS_ROUTE = `${PLUGIN_BASE_URL}/slos`;
export const SETTINGS_ROUTE = `${PLUGIN_BASE_URL}/settings`;
export const BUG_BOUNTY_ROUTE = `${PLUGIN_BASE_URL}/bug-bounty`;
export const PLAN_RELEASE_ROUTE = `${PLUGIN_BASE_URL}/plan-release`;

// Image Routes
export const IMAGE_BASE_ROUTE = `public/plugins/${PLUGIN_ID}/img`;
export const GITHUB_LOGO_SVG = `${IMAGE_BASE_ROUTE}/github-mark.svg`;
export const DOCKER_LOGO_SVG = `${IMAGE_BASE_ROUTE}/docker-mark-blue.svg`;
export const GCR_LOGO_SVG = `${IMAGE_BASE_ROUTE}/gcr.svg`;
export const GAR_LOGO_SVG = `${IMAGE_BASE_ROUTE}/gar.svg`;

// Tracking events
export const APP_VISIT_EVENT = 'grafana_plugin_vulnerability_observability_visit';
export const PLAN_RELEASE_CLICK = 'grafana_plugin_sources_plan_release';
export const CVES_CARD_CLICK = 'grafana_plugin_cves_card_click';
export const SOURCES_CARD_CLICK = 'grafana_plugin_sources_card_click';
export const VERSIONS_CARD_CLICK = 'grafana_plugin_sources_versions_card_click';

// Local storage keys
export const PREFERRED_GROUP_LS_KEY = 'grafana.preferredGroup';
export const CVE_LS_KEY = 'grafana.currentCve';
