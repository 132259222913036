import { TypedDocumentNode, gql } from '@apollo/client';
import { GetExceptionHeaderQuery, GetExceptionHeaderQueryVariables } from '__generated__/graphql';

export const GET_EXCEPTION_HEADER: TypedDocumentNode<GetExceptionHeaderQuery, GetExceptionHeaderQueryVariables> = gql`
  query getExceptionHeader($id: ID!) {
    cveException(id: $id) {
      id
      isActive
      cve {
        cve
      }
    }
  }
`;
