import { useMutation } from '@apollo/client';
import { AppEvents, SelectableValue } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Button, Modal, Field, Input, MultiSelect } from '@grafana/ui';
import { GetScanPayloadQuery } from '__generated__/graphql';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { capitalizeFirstLetter } from '../utils';

import { TRIGGER_SCAN } from './TriggerScanMutations';

interface FormType {
  name: string;
  version: string;
  versionID: string;
  scanners: Array<SelectableValue<string>>;
}

interface TriggerScanModalType {
  isOpen: boolean;
  onDismiss: () => void;
  version: GetScanPayloadQuery['version'];
}

export const TriggerScanModal = ({ isOpen, onDismiss, version }: TriggerScanModalType) => {
  const [triggerScan] = useMutation(TRIGGER_SCAN, {
    ignoreResults: true,
    onCompleted: () => {
      getAppEvents().publish({
        type: AppEvents.alertSuccess.name,
        payload: [`Added scan to queue`],
      });
    },
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to queue scans`],
      });
    },
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    resetField,
    formState: { isValid },
  } = useForm<FormType>();

  useEffect(() => {
    setValue('name', version.source.name);
    setValue('version', version.tag);
    setValue('versionID', version.id);
  }, [setValue, version]);

  const onSubmit: SubmitHandler<FormType> = (formData) => {
    const { versionID, scanners } = formData;
    triggerScan({
      variables: {
        input: {
          versionId: versionID,
          scanners: scanners.map((scanner) => scanner.value) as string[],
        },
      },
    });
    resetField('scanners');
    onDismiss();
  };

  return (
    <Modal isOpen={isOpen} title={`Trigger a ${capitalizeFirstLetter(version.source.type)} Scan`} onDismiss={onDismiss}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field label="Name">
          <Input {...register('name')} disabled />
        </Field>
        <Field label="Version">
          <Input {...register('version')} disabled />
        </Field>
        <Field label="Select Scanners">
          <Controller
            name="scanners"
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <MultiSelect
                {...field}
                options={[
                  { label: 'Trivy', value: 'trivy' },
                  { label: 'Snyk', value: 'snyk' },
                ]}
              />
            )}
          />
        </Field>
        <Modal.ButtonRow>
          <Button type="submit" variant="success" disabled={!isValid}>
            Queue Scans
          </Button>
        </Modal.ButtonRow>
      </form>
    </Modal>
  );
};
