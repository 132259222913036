import { PanelBuilders } from '@grafana/scenes';
import { VizOrientation } from '@grafana/schema';

export const getVersionSloBarGauge = () =>
  PanelBuilders.bargauge()
    .setTitle(`Version SLO Compliance`)
    .setDisplayMode('transparent')
    .setOption('orientation', VizOrientation.Horizontal)
    .setMin(0)
    .setOverrides((overridesBuilder) => {
      overridesBuilder.matchFieldsWithName('versionsWithinSlo').overrideDisplayName('Inside SLO').overrideColor({
        mode: 'fixed',
        fixedColor: 'dark-green',
      });
      overridesBuilder.matchFieldsWithName('versionsOutOfSlo').overrideDisplayName('Out of SLO').overrideColor({
        mode: 'fixed',
        fixedColor: 'dark-red',
      });
    })
    .build();
