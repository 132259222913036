import { looseSemverSortTagsDec } from '../utils/looseSemverSort';

import { ReportRow } from './BuildReleasePlan';

export const downloadCSV = (csvData: string, sourceName: string, type: string) => {
  const source = sourceName.replace(/[^a-zA-Z0-9]/g, '_');
  const fileName = `${source}_${type.toLowerCase()}_release_plan.csv`;
  const csvBlob = new Blob([csvData], { type: 'text/csv' });
  const url = URL.createObjectURL(csvBlob);

  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.style.visibility = 'hidden';
  a.click();
};

export const buildReleaseTableCSV = (rows: { [pkg: string]: ReportRow }, tags: string[]) => {
  const sortedTags = looseSemverSortTagsDec(tags);
  const headers = ['Package', 'Target', 'Fixed In', ...sortedTags];
  const csvRows = [headers.join(',')];

  Object.entries(rows).forEach(([pkg, metadata]) => {
    const row = [
      pkg,
      metadata.target || '',
      metadata.fixedIn.replace(/,/g, ' ') || '',
      ...sortedTags.map((tag) => metadata.installedVersions[tag] || ''),
    ];
    csvRows.push(row.join(','));
  });

  return csvRows.join('\n');
};
