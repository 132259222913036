import { SceneQueryRunner } from '@grafana/scenes';
import { getInfinityGQLQuery } from 'scenes/datasources/infinity';

export const getBugBountySubmissionsQueryRunner = (apiUrl: string) =>
  new SceneQueryRunner(
    getInfinityGQLQuery([
      {
        refId: 'A',
        url: apiUrl,
        root_selector: 'data.bugBountySubmissions.response',
        url_options: {
          body_graphql_query: `
              query getBugBountySubmissions($filters: BugBountySubmissionFilters, $first: Int, $after: Int) {
                bugBountySubmissions(filters: $filters, first: $first, after: $after) {
                  response {
                    squad
                    reward
                  }
                }
              }
            `,
          body_graphql_variables: JSON.stringify({
            filters: {
              submissionCode: '$submissionCode',
              squad: '$squad',
              status: '$status',
              severity: '$severity',
              from: '${__from:date:iso}',
              to: '${__to:date:iso}',
            },
          }),
        },
      },
    ])
  );
