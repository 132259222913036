import { useSuspenseQuery } from '@apollo/client';
import { IconName } from '@grafana/data';
import { Text, Stack, Icon } from '@grafana/ui';
import { GetCveIssueCountsQuery } from '__generated__/graphql';
import { Fragment } from 'react';
import { Link } from 'react-router-dom-v5-compat';
import { SOURCES_ROUTE } from 'shared/constants';

import { TargetsType } from './CreateException';
import { GET_CVE_ISSUE_COUNTS } from './CreateExceptionQueries';

interface ConfirmationModalType {
  cveID: number;
  targets: TargetsType;
}

export const ImpactedVersionList = ({ cveID, targets }: ConfirmationModalType) => {
  const { data } = useSuspenseQuery<GetCveIssueCountsQuery>(GET_CVE_ISSUE_COUNTS, {
    variables: { id: cveID },
  });

  const issueCounts = data.cve.issues.reduce(
    (acc, { version }) => {
      if (version.id in acc) {
        acc[version.id]++;
      } else {
        acc[version.id] = 1;
      }

      return acc;
    },
    {} as { [versionId: string]: number }
  );

  return (
    <>
      {targets.map(({ source, versions, all }, targetIndex) => {
        const name = source!.label;
        const icon = source!.icon as IconName;

        return (
          <Fragment key={`${targetIndex}`}>
            {versions.map((version, versionIndex) => {
              const count = issueCounts[version.value!.toString()];
              let issues = 'issues';
              if (count === 1) {
                issues = 'issue';
              }

              // @ts-ignore
              const issue = data.cve.issues.find((iss) => iss.version.id === version.value);

              return (
                <Stack direction="row" alignItems="center" key={`${targetIndex}-${versionIndex}`}>
                  <Icon name={icon} />
                  <Text element="p">
                    {name}:{version.label}{' '}
                    <Link
                      to={`${SOURCES_ROUTE}/${issue?.version.sourceId}/version/${issue?.version.id}?var-cve=${data.cve.cve}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Text color="link">
                        (dismisses {count} {issues})
                      </Text>
                    </Link>
                  </Text>
                </Stack>
              );
            })}
            {all && (
              <Stack direction="row" alignItems="center" key={`${targetIndex}-all`}>
                <Icon name={icon} />
                <Text element="p" color="warning">
                  All versions of {name}
                </Text>
              </Stack>
            )}
          </Fragment>
        );
      })}
    </>
  );
};
