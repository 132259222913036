import { Alert, ErrorBoundary, LoadingPlaceholder } from '@grafana/ui';
import { Suspense } from 'react';

import { ModifyRiskForm } from './ModifyRiskForm';

interface ModifyRiskType {
  issueID: string;
}

export const ModifyRisk = ({ issueID }: ModifyRiskType) => {
  return (
    <ErrorBoundary>
      {({ error }) => {
        if (error) {
          return (
            <Alert title={`Error: ${error.message}`} onRemove={() => window.location.reload()} buttonContent="Reload" />
          );
        }
        return (
          <Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
            <ModifyRiskForm issueID={issueID} />
          </Suspense>
        );
      }}
    </ErrorBoundary>
  );
};
