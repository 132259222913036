import { Text, Stack, Box } from '@grafana/ui';
import { Issue } from '__generated__/graphql';

interface IssuePackageBoxType {
  issue: Issue;
}

export const IssuePackageBox = ({ issue }: IssuePackageBoxType) => {
  return (
    <Box backgroundColor="canvas" borderColor="strong" borderStyle="solid" marginBottom={2} padding={1}>
      <Stack direction="row" justifyContent="space-around" alignItems="center" height="100%">
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Text weight="bold">Installed Version</Text>
          <Text>{issue.installedVersion}</Text>
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Text weight="bold">Fixed Version</Text>
          <Text>{issue.fixedVersion}</Text>
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Text weight="bold">Vulnerable Version Range</Text>
          <Text italic={!issue.vulnerableVersionRange} color={issue.vulnerableVersionRange ? 'primary' : 'secondary'}>
            {issue.vulnerableVersionRange || 'unknown'}
          </Text>
        </Stack>
      </Stack>
    </Box>
  );
};
