import { Alert, ErrorBoundary, LoadingPlaceholder } from '@grafana/ui';
import { Suspense, useCallback, useDeferredValue, useState } from 'react';

import { ExploreExceptions } from './ExploreExceptions';

interface ExploreExceptionsContainerType {
  cve: string;
  source: string;
  version: string;
  reason: string;
}

export const ExploreExceptionsContainer = ({ cve, source, version, reason }: ExploreExceptionsContainerType) => {
  const pageSize = 10;
  const [offset, setOffset] = useState<number>(0);

  const deferredCve = useDeferredValue(cve);
  const deferredSource = useDeferredValue(source);
  const deferredVersion = useDeferredValue(version);
  const deferredReason = useDeferredValue(reason);
  const deferredOffset = useDeferredValue(offset);

  const onNavigate = useCallback(
    (page: number) => {
      setOffset(pageSize * (page - 1));
    },
    [setOffset]
  );

  return (
    <ErrorBoundary>
      {({ error }) => {
        if (error) {
          return (
            <Alert
              title="Error: failed to load CVE exceptions"
              onRemove={() => window.location.reload()}
              buttonContent="Reload"
            >
              {error.message}
            </Alert>
          );
        }
        return (
          <Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
            <ExploreExceptions
              cve={deferredCve}
              source={deferredSource}
              version={deferredVersion}
              reason={deferredReason}
              offset={deferredOffset}
              pageSize={pageSize}
              onNavigate={onNavigate}
            />
          </Suspense>
        );
      }}
    </ErrorBoundary>
  );
};
