import { EmbeddedScene, SceneAppPage, SceneFlexLayout, SceneFlexItem, SceneReactObject } from '@grafana/scenes';
import { IssueComments } from 'scenes/components/IssueComments';
import { ISSUES_ROUTE } from 'shared/constants';

export const getIssueActivityDrilldown = (issueID: string) =>
  new SceneAppPage({
    title: 'Activity',
    titleIcon: 'list-ul',
    key: 'activityDrilldown',
    url: `${ISSUES_ROUTE}/${issueID}/activity`,
    getScene: () => {
      return new EmbeddedScene({
        key: 'comments',
        body: new SceneFlexLayout({
          direction: 'column',
          children: [
            new SceneFlexItem({
              body: new SceneReactObject({
                component: () => IssueComments({ issueID }),
              }),
            }),
          ],
        }),
      });
    },
  });
