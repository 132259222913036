import { SceneQueryRunner } from '@grafana/scenes';
import { getPrometheusDatasource } from 'scenes/datasources/prometheus/prometheusDatasource';

export const getTotalVulnerabilitiesQueryRunner = (group: string) =>
  new SceneQueryRunner({
    datasource: getPrometheusDatasource(),
    queries: [
      {
        refId: 'A',
        expr: `sum (vulns_by_group{database="svo", cluster="\${cluster}", group="${group}"})`,
      },
    ],
  });
