import { useMutation } from '@apollo/client';
import { css } from '@emotion/css';
import { AppEvents, GrafanaTheme2 } from '@grafana/data';
import { config, getAppEvents } from '@grafana/runtime';
import { useStyles2, Box, Modal, Button, Text, Stack } from '@grafana/ui';
import {
  CalculatedRisk,
  ModifyRiskAllMutation,
  ModifyRiskMutation,
  ModifyRiskMutationVariables,
  ModifyRiskSourceMutation,
  ModifyRiskVersionMutation,
} from '__generated__/graphql';
import { useState } from 'react';

import { capitalizeFirstLetter } from '../utils';

import { IssueScope, ModifyRiskButton } from './ModifyRiskButton';
import { MODIFY_RISK, MODIFY_RISK_ALL, MODIFY_RISK_SOURCE, MODIFY_RISK_VERSION } from './ModifyRiskMutations';
import { GET_CVSS } from './ModifyRiskQueries';

interface ConfirmationModalType {
  originalRisk: string;
  calculatedRisk: CalculatedRisk;
  modifyRiskPayload: ModifyRiskMutationVariables;
  isOpen: boolean;
  onDismiss: () => void;
  onConfirm: () => void;
}

export const ConfirmationModal = ({
  originalRisk,
  calculatedRisk,
  modifyRiskPayload,
  isOpen,
  onDismiss,
  onConfirm,
}: ConfirmationModalType) => {
  const styles = useStyles2(getStyles);
  const [scope, setSelectedScope] = useState<IssueScope>(IssueScope.Issue);

  const onError = () => {
    getAppEvents().publish({
      type: AppEvents.alertError.name,
      payload: [`Error: failed to update risk`],
    });
    onDismiss();
  };

  const onCompleted = () => {
    getAppEvents().publish({
      type: AppEvents.alertSuccess.name,
      payload: [`Risk update successful.`],
    });
    onConfirm();
  };

  const [modifyRisk] = useMutation<ModifyRiskMutation>(MODIFY_RISK, {
    ignoreResults: true,
    onError,
    onCompleted,
    refetchQueries: [GET_CVSS],
  });

  const [modifyRiskVersion] = useMutation<ModifyRiskVersionMutation>(MODIFY_RISK_VERSION, {
    ignoreResults: true,
    onError,
    onCompleted,
    refetchQueries: [GET_CVSS],
  });

  const [modifyRiskSource] = useMutation<ModifyRiskSourceMutation>(MODIFY_RISK_SOURCE, {
    ignoreResults: true,
    onError,
    onCompleted,
    refetchQueries: [GET_CVSS],
  });

  const [modifyRiskAll] = useMutation<ModifyRiskAllMutation>(MODIFY_RISK_ALL, {
    ignoreResults: true,
    onError,
    onCompleted,
    refetchQueries: [GET_CVSS],
  });

  const user = config.bootData.user;

  const onSubmit = () => {
    switch (scope) {
      case IssueScope.Issue:
        modifyRisk({ variables: modifyRiskPayload });
        break;
      case IssueScope.Version:
        modifyRiskVersion({ variables: modifyRiskPayload });
        break;
      case IssueScope.Source:
        modifyRiskSource({ variables: modifyRiskPayload });
        break;
      case IssueScope.All:
        modifyRiskAll({ variables: modifyRiskPayload });
        break;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      title={
        <Stack direction="column">
          <Box marginTop={1}>
            <Text element="h2">Calculated Risk: {capitalizeFirstLetter(calculatedRisk.risk)}</Text>
          </Box>
          <Box marginBottom={1}>
            <Text element="h4" variant="h5" color="secondary" italic>
              (current risk: {capitalizeFirstLetter(originalRisk)})
            </Text>
          </Box>
        </Stack>
      }
      className={styles.modal}
    >
      <table className={styles.table}>
        <tbody>
          <tr>
            <th>Time to Mitigate in Cloud (days)</th>
            <td>{calculatedRisk?.timeToMitigate === -1 ? 'N/A' : calculatedRisk?.timeToMitigate}</td>
          </tr>
          <tr>
            <th>Time to Release (days)</th>
            <td>{calculatedRisk?.timeToRelease === -1 ? 'N/A' : calculatedRisk?.timeToRelease}</td>
          </tr>
          <tr>
            <th>Suggestion Action</th>
            <td>{calculatedRisk?.suggestedAction}</td>
          </tr>
          <tr>
            <th>Release</th>
            <td>{calculatedRisk?.releaseType}</td>
          </tr>
        </tbody>
        <Box element="caption" marginTop={0.5}>
          Note: Update Risk affects <u>this issue only</u> by default.
        </Box>
      </table>
      <Modal.ButtonRow>
        <Button variant="secondary" fill="outline" onClick={onDismiss}>
          Cancel
        </Button>
        <ModifyRiskButton disabled={!user.isSignedIn} updateScope={setSelectedScope} onClick={onSubmit} />
      </Modal.ButtonRow>
    </Modal>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  modal: css({
    top: '30%',
  }),
  table: css({
    borderCollapse: 'collapse',
    border: `1px solid ${theme.colors.border.medium}`,
    backgroundColor: theme.colors.background.canvas,
    width: '100%',
    th: {
      width: '40%',
    },
    'th, td': {
      padding: theme.spacing(1),
      border: `1px solid ${theme.colors.border.medium}`,
    },
  }),
});
