import {
  SceneObjectBase,
  SceneComponentProps,
  SceneObjectUrlSyncHandler,
  SceneObjectUrlValues,
  SceneObjectUrlSyncConfig,
  SceneVariable,
  SceneVariableState,
  SceneVariableValueChangedEvent,
  VariableValue,
} from '@grafana/scenes';
import { ReactNode } from 'react';

import { TextInput } from './TextInput';

export interface TextInputVariableState extends SceneVariableState {
  value: string;
  placeholder: string;
  width: number;
  prefix: ReactNode;
}

export class TextInputVariable
  extends SceneObjectBase<TextInputVariableState>
  implements SceneVariable<TextInputVariableState>
{
  protected _urlSync: SceneObjectUrlSyncHandler;

  public constructor(initialState: Partial<TextInputVariableState>) {
    super({
      type: 'textbox',
      value: '',
      name: '',
      placeholder: 'Enter value',
      width: 15,
      prefix: null,
      ...initialState,
    });

    this._urlSync = new SceneObjectUrlSyncConfig(this, { keys: [this.getKey()] });
  }

  public getValue(): VariableValue {
    return this.state.value;
  }

  public setValue(newValue: string) {
    if (newValue !== this.state.value) {
      this.setState({ value: newValue });
      this.publishEvent(new SceneVariableValueChangedEvent(this), true);
    }
  }

  private getKey(): string {
    return `var-${this.state.name}`;
  }

  public getUrlState() {
    return { [this.getKey()]: this.state.value };
  }

  public updateFromUrl(values: SceneObjectUrlValues) {
    const update: Partial<TextInputVariableState> = {};
    const val = values[this.getKey()];
    if (typeof val === 'string') {
      update.value = val;
    }

    this.setState(update);
  }

  public static Component = ({ model }: SceneComponentProps<TextInputVariable>) => {
    return <TextInput model={model} />;
  };
}
