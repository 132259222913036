import { TypedDocumentNode, gql } from '@apollo/client';
import {
  ModifyRiskAllMutation,
  ModifyRiskAllMutationVariables,
  ModifyRiskMutation,
  ModifyRiskMutationVariables,
  ModifyRiskSourceMutation,
  ModifyRiskSourceMutationVariables,
  ModifyRiskVersionMutation,
  ModifyRiskVersionMutationVariables,
} from '__generated__/graphql';

export const MODIFY_RISK: TypedDocumentNode<ModifyRiskMutation, ModifyRiskMutationVariables> = gql`
  mutation modifyRisk($input: ModifyRiskInputs!) {
    modifyRisk(input: $input)
  }
`;

export const MODIFY_RISK_VERSION: TypedDocumentNode<ModifyRiskVersionMutation, ModifyRiskVersionMutationVariables> =
  gql`
    mutation modifyRiskVersion($input: ModifyRiskInputs!) {
      modifyRiskVersion(input: $input)
    }
  `;

export const MODIFY_RISK_SOURCE: TypedDocumentNode<ModifyRiskSourceMutation, ModifyRiskSourceMutationVariables> = gql`
  mutation modifyRiskSource($input: ModifyRiskInputs!) {
    modifyRiskSource(input: $input)
  }
`;

export const MODIFY_RISK_ALL: TypedDocumentNode<ModifyRiskAllMutation, ModifyRiskAllMutationVariables> = gql`
  mutation modifyRiskAll($input: ModifyRiskInputs!) {
    modifyRiskAll(input: $input)
  }
`;
