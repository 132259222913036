import { TypedDocumentNode, gql } from '@apollo/client';
import {
  AddUserMutation,
  AddUserMutationVariables,
  UpdateUserRoleMutation,
  UpdateUserRoleMutationVariables,
} from '__generated__/graphql';

export const UPDATE_USER_ROLE: TypedDocumentNode<UpdateUserRoleMutation, UpdateUserRoleMutationVariables> = gql`
  mutation updateUserRole($input: UpdateUserRoleInput!) {
    updateUserRole(input: $input)
  }
`;

export const ADD_USER: TypedDocumentNode<AddUserMutation, AddUserMutationVariables> = gql`
  mutation addUser($input: AddUserInput!) {
    addUser(input: $input) {
      id
    }
  }
`;
