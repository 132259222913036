import { useSuspenseQuery } from '@apollo/client';
import { css } from '@emotion/css';
import { renderMarkdown } from '@grafana/data';
import { Box, LinkButton, Text, useStyles2 } from '@grafana/ui';
import { GetCveReportQuery } from '__generated__/graphql';

import { GET_CVE_REPORT } from './CveReportQueries';

interface CveDescriptionType {
  cve: string;
}

export const CveDescription = ({ cve }: CveDescriptionType) => {
  const styles = useStyles2(getStyles);

  const { data } = useSuspenseQuery<GetCveReportQuery>(GET_CVE_REPORT, {
    variables: {
      filters: {
        cve,
      },
      first: 1,
    },
  });

  const cveData = data.cves.response[0];

  return (
    <Box backgroundColor="canvas" borderColor="strong" borderStyle="solid" marginBottom={2} padding={1.5}>
      <div className={styles.container}>
        {cveData.description.trim()[0] !== '#' && (
          <Box paddingBottom={1}>
            <Text element="h2">Overview</Text>
          </Box>
        )}
        <span className="markdown-html" dangerouslySetInnerHTML={{ __html: renderMarkdown(cveData.description) }} />
        <Box display="flex" justifyContent="right" marginTop={2}>
          <LinkButton href={cveData.url} target="_blank" rel="noreferrer" variant="primary" icon="external-link-alt">
            {cveData.url && cveData.url.includes('github.com') ? 'View on GitHub' : 'More Details'}
          </LinkButton>
          {cveData.cve && !cveData.cve.includes('GHSA') && (
            <Box marginLeft={2}>
              <LinkButton
                href={`https://cve.mitre.org/cgi-bin/cvename.cgi?name=${cve}`}
                target="_blank"
                rel="noreferrer"
                variant="primary"
                icon="external-link-alt"
              >
                View on Mitre
              </LinkButton>
            </Box>
          )}
        </Box>
      </div>
    </Box>
  );
};

const getStyles = () => ({
  container: css({
    maxHeight: '540px',
    overflowY: 'auto',
  }),
});
