import { TypedDocumentNode, gql } from '@apollo/client';
import { GetCvesQuery, GetCvesQueryVariables } from '__generated__/graphql';

export const GET_CVES: TypedDocumentNode<GetCvesQuery, GetCvesQueryVariables> = gql`
  query getCves($filters: CveFilters, $first: Int, $after: Int) {
    cves(filters: $filters, first: $first, after: $after) {
      response {
        id
        cve
        url
        title
        cvssScore
        issues {
          package
        }
      }
    }
  }
`;
