import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2, Text, Box, Stack, Tooltip, Icon, Divider } from '@grafana/ui';
import { Risk, RiskLevel } from '__generated__/graphql';
import { useMemo } from 'react';

import { capitalizeFirstLetter } from '../utils';

interface RiskTableType {
  risk: Risk;
  sloRemaining: number;
  firstSeenInVersion: string;
}

export const RiskTable = ({ risk, sloRemaining, firstSeenInVersion }: RiskTableType) => {
  const styles = useStyles2(getStyles);

  const exploitability = useMemo(() => {
    if (risk.zeroDay) {
      return 'Zero Day';
    } else if (risk.signOfExploitation) {
      return 'Sign of Exploitation';
    } else if (risk.highlyExploitable) {
      return 'Highly Exploitable';
    } else if (risk.exploitable) {
      return 'Exploitable';
    } else if (risk.unlikelyExploitable) {
      return 'Unlikely Exploitable';
    } else if (risk.notExploitable) {
      return 'Not Exploitable';
    } else if (risk.falsePositive) {
      return 'False Positive';
    } else {
      return 'Unknown';
    }
  }, [risk]);

  const riskColor = useMemo(() => {
    const sev = risk.risk.toUpperCase();

    let textColor = styles.none;
    if (sev === RiskLevel.Maximum) {
      textColor = styles.maximum;
    } else if (sev === RiskLevel.Severe) {
      textColor = styles.critical;
    } else if (sev === RiskLevel.Significant) {
      textColor = styles.high;
    } else if (sev === RiskLevel.Moderate) {
      textColor = styles.medium;
    } else if (sev === RiskLevel.Minor) {
      textColor = styles.low;
    }
    return textColor;
  }, [risk, styles]);

  const daysSinceFirstSeen = useMemo(() => {
    const firstSeen = new Date(firstSeenInVersion).getTime();
    const currentDate = new Date().getTime();
    const differenceInMilliseconds = currentDate - firstSeen;
    return Math.floor(differenceInMilliseconds / (24 * 60 * 60 * 1000));
  }, [firstSeenInVersion]);

  return (
    <Box backgroundColor="canvas" borderColor="strong" borderStyle="solid" marginTop={2}>
      <Box padding={1}>
        <Text element="h4" variant="h2">
          Risk Level: <span className={riskColor}>{capitalizeFirstLetter(risk.risk)}</span>
        </Text>
      </Box>
      <table className={styles.table}>
        <tbody>
          <tr>
            <th>Exploitability</th>
            <td>{exploitability}</td>
          </tr>
          <tr>
            <th>Time to Mitigate</th>
            <td>
              <Stack alignItems="center" justifyContent="space-between">
                {risk.riskSlo.timeToMitigate - daysSinceFirstSeen} days
                <Tooltip
                  placement="left"
                  content={
                    <>
                      <Box paddingBottom={0.25}>
                        The time to make a vulnerability non-exploitable. This does not necessarily mean patched.
                        Calculated as:
                      </Box>
                      <Box backgroundColor="canvas" padding={1}>
                        <Text element="p" variant="bodySmall">
                          (days to mitigate for given risk) - (days since discovery)
                        </Text>
                        <Text element="p" variant="bodySmall">
                          {risk.riskSlo.timeToMitigate} - {daysSinceFirstSeen}
                        </Text>
                        <Divider />
                        <Text element="p" variant="bodySmall">
                          {risk.riskSlo.timeToMitigate - daysSinceFirstSeen} days
                        </Text>
                      </Box>
                    </>
                  }
                >
                  <Icon name="question-circle" size="sm" />
                </Tooltip>
              </Stack>
            </td>
          </tr>
          <tr>
            <th>Time to Release</th>
            <td>
              <Stack alignItems="center" justifyContent="space-between">
                {sloRemaining} days
                <Tooltip
                  placement="left"
                  content={
                    <>
                      <Box paddingBottom={0.25}>The time to release a patch. Calculated as:</Box>
                      <Box backgroundColor="canvas" padding={1}>
                        <Text element="p" variant="bodySmall">
                          (days to release for given risk) - (days since discovery)
                        </Text>
                        <Text element="p" variant="bodySmall">
                          {risk.riskSlo.timeToRelease} - {daysSinceFirstSeen}
                        </Text>
                        <Divider />
                        <Text element="p" variant="bodySmall">
                          {risk.riskSlo.timeToRelease - daysSinceFirstSeen} days
                        </Text>
                      </Box>
                    </>
                  }
                >
                  <Icon name="question-circle" size="sm" />
                </Tooltip>
              </Stack>
            </td>
          </tr>
          <tr>
            <th>Suggested Action</th>
            <td>{risk.riskSlo.suggestedAction}</td>
          </tr>
          <tr>
            <th>Release Type</th>
            <td>{risk.riskSlo.releaseType}</td>
          </tr>
        </tbody>
      </table>
    </Box>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  table: css({
    width: '100%',
    borderCollapse: 'collapse',
    'th, td': {
      padding: '8px',
      borderTop: `1px solid ${theme.colors.border.strong}`,
    },
    th: {
      borderRight: `1px solid ${theme.colors.border.strong}`,
    },
  }),
  maximum: css({
    color: theme.visualization.getColorByName('dark-purple'),
  }),
  critical: css({
    color: theme.visualization.getColorByName('dark-red'),
  }),
  high: css({
    color: theme.visualization.getColorByName('dark-orange'),
  }),
  medium: css({
    color: theme.visualization.getColorByName('dark-yellow'),
  }),
  low: css({
    color: theme.visualization.getColorByName('grey'),
  }),
  none: css({
    color: theme.colors.text.primary,
  }),
});
