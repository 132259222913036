import {
  CustomVariable,
  EmbeddedScene,
  SceneAppPage,
  SceneFlexLayout,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';
import { VariableHide } from '@grafana/schema';
import { Icon } from '@grafana/ui';
import { TextInputVariable } from 'scenes/controls/TextInputVariable';
import { Compare } from 'scenes/customScenes/Compare';
import { SOURCES_ROUTE } from 'shared/constants';

interface GetCompareTabType {
  apiUrl: string;
  sourceID: string;
}

export const getCompareTab = ({ apiUrl, sourceID }: GetCompareTabType) =>
  new SceneAppPage({
    title: 'Compare',
    titleIcon: 'exchange-alt',
    key: 'compareTab',
    url: `${SOURCES_ROUTE}/${sourceID}/compare`,
    getScene: () => {
      return new EmbeddedScene({
        key: 'compare',
        $variables: new SceneVariableSet({
          variables: [
            new TextInputVariable({
              name: 'cve',
              prefix: <Icon name="search" />,
              placeholder: 'Search CVEs',
              width: 40,
              hide: VariableHide.hideLabel,
            }),
            new CustomVariable({
              name: 'severity',
              label: 'Severity',
              value: 'All',
              text: 'All',
              query: `All,Critical,High,Medium,Low,Unknown`,
            }),
          ],
        }),
        body: new SceneFlexLayout({
          direction: 'column',
          children: [
            new Compare({
              sourceID,
              cve: '${cve}',
              severity: '${severity}',
            }),
          ],
        }),
        controls: [new VariableValueSelectors({})],
      });
    },
  });
