import { TypedDocumentNode, gql } from '@apollo/client';
import {
  GetReleasePlanDataQuery,
  GetReleasePlanDataQueryVariables,
  GetSourceListQuery,
  GetSourceListQueryVariables,
} from '__generated__/graphql';

export const GET_SOURCE_LIST: TypedDocumentNode<GetSourceListQuery, GetSourceListQueryVariables> = gql`
  query getSourceList($filters: SourceFilters, $first: Int, $after: Int) {
    sources(filters: $filters, first: $first, after: $after) {
      response {
        id
        name
        type
        groups {
          id
          name
        }
      }
    }
  }
`;

export const GET_RELEASE_PLAN_DATA: TypedDocumentNode<GetReleasePlanDataQuery, GetReleasePlanDataQueryVariables> = gql`
  query getReleasePlanData($filters: SourceFilters, $first: Int, $after: Int) {
    sources(filters: $filters, first: $first, after: $after) {
      response {
        id
        name
        origin
        type
        versions {
          id
          tag
          issues {
            package
            target
            installedVersion
            fixedVersion
            sloRemaining
            isGone
            isDismissed
            cve {
              cve
              url
            }
          }
        }
      }
    }
  }
`;
