import { TypedDocumentNode, gql } from '@apollo/client';
import { GetExceptionQuery, GetExceptionQueryVariables } from '__generated__/graphql';

export const GET_EXCEPTION: TypedDocumentNode<GetExceptionQuery, GetExceptionQueryVariables> = gql`
  query getException($id: ID!) {
    cveException(id: $id) {
      id
      reason
      team
      description
      createdOn
      exceptionVersions {
        version {
          id
          version
          source {
            id
            name
            type
          }
        }
      }
      exceptionSources {
        source {
          id
          name
          type
        }
      }
      cve {
        id
        cve
      }
    }
  }
`;
