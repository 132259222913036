import { TypedDocumentNode, gql } from '@apollo/client';
import {
  GetAllGroupsQuery,
  GetAllGroupsQueryVariables,
  GetGroupsQuery,
  GetGroupsQueryVariables,
  GetSourceNameQuery,
  GetSourceNameQueryVariables,
  GetSourceVersionsQuery,
  GetSourceVersionsQueryVariables,
} from '__generated__/graphql';

export const GET_GROUPS: TypedDocumentNode<GetGroupsQuery, GetGroupsQueryVariables> = gql`
  query getGroups($id: ID!) {
    source(id: $id) {
      id
      groups {
        id
        name
      }
    }
  }
`;

export const GET_ALL_GROUPS: TypedDocumentNode<GetAllGroupsQuery, GetAllGroupsQueryVariables> = gql`
  query getAllGroups {
    groups {
      id
      name
    }
  }
`;

export const GET_SOURCE_VERSIONS: TypedDocumentNode<GetSourceVersionsQuery, GetSourceVersionsQueryVariables> = gql`
  query getSourceVersions($filters: VersionFilters!) {
    versions(filters: $filters) {
      response {
        id
        version
        isArchived
        scans {
          ingestTime
        }
      }
    }
  }
`;

export const GET_SOURCE_NAME: TypedDocumentNode<GetSourceNameQuery, GetSourceNameQueryVariables> = gql`
  query getSourceName($id: ID!) {
    source(id: $id) {
      id
      name
    }
  }
`;
