import { PanelBuilders, SceneDataTransformer } from '@grafana/scenes';
import { GraphDrawStyle, SortOrder, StackingMode, TooltipDisplayMode, VisibilityMode } from '@grafana/schema';

export const getCvesPatchedTimeseries = () =>
  PanelBuilders.timeseries()
    .setData(
      new SceneDataTransformer({
        transformations: [
          {
            id: 'prepareTimeSeries',
            options: {
              format: 'multi',
            },
          },
        ],
      })
    )
    .setTitle(`CVEs Patched per Week`)
    .setDisplayMode('transparent')
    .setOption('tooltip', {
      mode: TooltipDisplayMode.Multi,
      sort: SortOrder.Descending,
    })
    .setCustomFieldConfig('axisLabel', 'Count')
    .setCustomFieldConfig('drawStyle', GraphDrawStyle.Bars)
    .setCustomFieldConfig('fillOpacity', 100)
    .setCustomFieldConfig('showPoints', VisibilityMode.Never)
    .setCustomFieldConfig('stacking', {
      mode: StackingMode.Normal,
    })
    .setOverrides((overridesBuilder) => {
      overridesBuilder.matchFieldsWithName('patchedCveCount CRITICAL').overrideDisplayName('Critical').overrideColor({
        mode: 'fixed',
        fixedColor: 'red',
      });
      overridesBuilder.matchFieldsWithName('patchedCveCount HIGH').overrideDisplayName('High').overrideColor({
        mode: 'fixed',
        fixedColor: 'orange',
      });
      overridesBuilder.matchFieldsWithName('patchedCveCount MEDIUM').overrideDisplayName('Medium').overrideColor({
        mode: 'fixed',
        fixedColor: 'yellow',
      });
      overridesBuilder.matchFieldsWithName('patchedCveCount LOW').overrideDisplayName('Low').overrideColor({
        mode: 'fixed',
        fixedColor: 'gray',
      });
    })
    .build();
