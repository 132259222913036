import { EmbeddedScene, SceneAppPage, SceneAppPageLike, SceneFlexLayout, SceneReactObject } from '@grafana/scenes';
import { ExceptionDetailsContainer } from 'scenes/components/ExceptionDetails';
import { DeactivateExceptionButton, ExceptionDetailsHeader } from 'scenes/components/ExceptionDetailsHeader';
import { CVE_EXCEPTION_DETAILS_ROUTE } from 'shared/constants';

interface GetCveExceptionDetailsDrilldownType {
  exceptionID: string;
  parent: SceneAppPageLike;
}

export const getCveExceptionDetailsDrilldown = ({ exceptionID, parent }: GetCveExceptionDetailsDrilldownType) =>
  new SceneAppPage({
    title: 'Exception Details',
    renderTitle: () => ExceptionDetailsHeader({ exceptionID }),
    key: 'exceptionDetailsDrilldown',
    url: `${CVE_EXCEPTION_DETAILS_ROUTE}/${exceptionID}`,
    getParentPage: () => parent,
    controls: [
      new SceneReactObject({
        component: () => DeactivateExceptionButton({ exceptionID }),
      }),
    ],
    getScene: () => {
      return new EmbeddedScene({
        key: 'exceptionDetails',
        body: new SceneFlexLayout({
          direction: 'column',
          children: [
            new SceneFlexLayout({
              direction: 'column',
              height: '100%',
              children: [
                new SceneReactObject({
                  component: () => ExceptionDetailsContainer({ exceptionID }),
                }),
              ],
            }),
          ],
        }),
      });
    },
  });
