import {
  SceneObjectBase,
  SceneComponentProps,
  SceneObjectUrlSyncHandler,
  SceneObjectUrlValues,
  SceneObjectUrlSyncConfig,
  SceneVariable,
  SceneVariableState,
  SceneVariableValueChangedEvent,
  VariableValue,
} from '@grafana/scenes';

import { CustomSwitch } from './CustomSwitch';

export interface SwitchVariableState extends SceneVariableState {
  value: boolean;
  invalid: boolean;
}

export class SwitchVariable extends SceneObjectBase<SwitchVariableState> implements SceneVariable<SwitchVariableState> {
  protected _urlSync: SceneObjectUrlSyncHandler;

  public constructor(initialState: Partial<SwitchVariableState>) {
    super({
      type: 'custom',
      value: false,
      name: '',
      invalid: false,
      ...initialState,
    });

    this._urlSync = new SceneObjectUrlSyncConfig(this, { keys: [this.getKey()] });
  }

  public getValue(): VariableValue {
    return this.state.value;
  }

  public setValue(newValue: boolean) {
    if (newValue !== this.state.value) {
      this.setState({ value: newValue });
      this.publishEvent(new SceneVariableValueChangedEvent(this), true);
    }
  }

  private getKey(): string {
    if (this.state.skipUrlSync) {
      return '';
    }

    return `var-${this.state.name}`;
  }

  public getUrlState() {
    if (this.state.skipUrlSync) {
      return {};
    }

    return { [this.getKey()]: this.state.value.toString() };
  }

  public updateFromUrl(values: SceneObjectUrlValues) {
    const update: Partial<SwitchVariableState> = {};
    const val = values[this.getKey()];
    if (typeof val === 'string') {
      update.value = Boolean(val);
    }

    this.setState(update);
  }

  public static Component = ({ model }: SceneComponentProps<SwitchVariable>) => {
    return <CustomSwitch model={model} />;
  };
}
