import {
  EmbeddedScene,
  SceneAppPage,
  SceneFlexLayout,
  SceneVariableSet,
  SceneFlexItem,
  VariableValueSelectors,
  QueryVariable,
  CustomVariable,
  sceneGraph,
} from '@grafana/scenes';
import { VariableHide } from '@grafana/schema';
import { Icon } from '@grafana/ui';
import { RiskLevel, SourceType } from '__generated__/graphql';
import { IssueHeader } from 'scenes/components/IssueHeader';
import { ActWhenVariablesChanged } from 'scenes/controls/ActWhenVariablesChanged';
import { SwitchVariable } from 'scenes/controls/SwitchVariable';
import { TextInputVariable } from 'scenes/controls/TextInputVariable';
import { Paginator } from 'scenes/customScenes/Paginator';
import { getInfinityDatasource, baseOpts, urlOpts } from 'scenes/datasources/infinity';
import { ISSUES_ROUTE } from 'shared/constants';

import { getIssueActivityDrilldown } from './getIssueActivityDrilldown';
import { getIssueCveDrilldown } from './getIssueCveDrilldown';
import { getIssueReclassifyDrilldown } from './getIssueReclassifyDrilldown';
import { getIssuesTable } from './panels';
import { getIssuesCountQueryRunner, getIssuesQueryRunner } from './queryRunners';

interface GetIssuesPageType {
  apiUrl: string;
}

export const getIssuesPage = ({ apiUrl }: GetIssuesPageType) =>
  new SceneAppPage({
    title: 'Issues',
    key: 'issuesPage',
    url: ISSUES_ROUTE,
    getScene: () => {
      return new EmbeddedScene({
        key: 'issues',
        $variables: new SceneVariableSet({
          variables: [
            new TextInputVariable({
              name: 'cve',
              prefix: <Icon name="search" />,
              placeholder: 'Search CVEs',
              width: 24,
              hide: VariableHide.hideLabel,
            }),
            new TextInputVariable({
              name: 'package',
              prefix: <Icon name="gf-layout-simple" />,
              placeholder: 'Search packages',
              width: 24,
              hide: VariableHide.hideLabel,
            }),
            new QueryVariable({
              name: 'source',
              label: 'Source',
              includeAll: true,
              defaultToAll: true,
              allValue: '0',
              datasource: getInfinityDatasource(),
              query: {
                refId: 'variable-source',
                infinityQuery: {
                  ...baseOpts,
                  url: apiUrl,
                  root_selector: 'data.sources.response',
                  url_options: {
                    ...urlOpts,
                    body_graphql_query: `
                      query getSources {
                        sources {
                            response {
                              __value: id
                              __text: name
                            }
                        }
                      }
                    `,
                  },
                },
                queryType: 'infinity',
              },
            }),
            new QueryVariable({
              name: 'version',
              label: 'Version',
              includeAll: true,
              defaultToAll: true,
              allValue: '0',
              datasource: getInfinityDatasource(),
              query: {
                refId: 'variable-version',
                infinityQuery: {
                  ...baseOpts,
                  url: apiUrl,
                  root_selector: 'data.versions.response',
                  url_options: {
                    ...urlOpts,
                    body_graphql_query: `
                      query getVersions($filters: VersionFilters) {
                        versions(filters: $filters) {
                            response {
                              __value: id
                              __text: tag
                            }
                        }
                      }
                    `,
                    body_graphql_variables: JSON.stringify({
                      filters: {
                        sourceId: '$source',
                      },
                    }),
                  },
                },
                queryType: 'infinity',
              },
            }),
            new CustomVariable({
              name: 'risk',
              label: 'Risk',
              value: RiskLevel.All,
              text: RiskLevel.All,
              query: `All : ${RiskLevel.All},Maximum : ${RiskLevel.Maximum},Severe : ${RiskLevel.Severe},Significant : ${RiskLevel.Significant},Moderate : ${RiskLevel.Moderate},Minor : ${RiskLevel.Minor},None : ${RiskLevel.None}`,
            }),
            new CustomVariable({
              name: 'type',
              label: 'Type',
              value: SourceType.All,
              text: 'All',
              query: `All : ${SourceType.All},Container : ${SourceType.Container},Repository : ${SourceType.Repository}`,
            }),
            new SwitchVariable({
              name: 'hideDismissed',
              label: 'Hide Dismissed',
              value: false,
            }),
            new SwitchVariable({
              name: 'showLatestOnly',
              label: 'Latest Versions Only',
              value: false,
            }),
            new CustomVariable({
              name: 'issueTableFirst',
              value: 20,
              hide: VariableHide.hideVariable,
              skipUrlSync: true,
              query: '20',
            }),
            new CustomVariable({
              name: 'issueTableAfter',
              value: 0,
              hide: VariableHide.hideVariable,
              skipUrlSync: true,
              query: '0',
            }),
          ],
        }),
        $data: getIssuesQueryRunner(apiUrl),
        $behaviors: [
          new ActWhenVariablesChanged({
            variableNames: ['cve', 'package', 'source', 'version', 'risk', 'hideDismissed'],
            onChange: (variable) => {
              const after = sceneGraph.lookupVariable('issueTableAfter', variable) as CustomVariable;
              after.setState({ value: 0 });
            },
          }),
          new ActWhenVariablesChanged({
            variableNames: ['source'],
            onChange: (variable) => {
              const after = sceneGraph.lookupVariable('version', variable) as CustomVariable;
              after.setState({ value: '0' });
            },
          }),
        ],
        body: new SceneFlexLayout({
          direction: 'column',
          children: [
            new SceneFlexItem({
              height: '100%',
              body: getIssuesTable(),
            }),
            new SceneFlexItem({
              $data: getIssuesCountQueryRunner(apiUrl),
              body: new Paginator({
                first: '${issueTableFirst}',
                after: '${issueTableAfter}',
              }),
            }),
          ],
        }),
        controls: [new VariableValueSelectors({})],
      });
    },
    drilldowns: [
      {
        routePath: `${ISSUES_ROUTE}/:issueID`,
        getPage(routeMatch, parent) {
          const issueID = routeMatch.params.issueID;
          return new SceneAppPage({
            url: `${ISSUES_ROUTE}/${issueID}/report`,
            title: `Issue Details`,
            renderTitle: () => IssueHeader({ issueID }),
            getParentPage: () => parent,
            tabs: [
              getIssueCveDrilldown(apiUrl, issueID),
              getIssueActivityDrilldown(issueID),
              getIssueReclassifyDrilldown(apiUrl, issueID),
            ],
          });
        },
      },
    ],
  });
