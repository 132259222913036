import { DOCKER_LOGO_SVG, GAR_LOGO_SVG, GCR_LOGO_SVG, GITHUB_LOGO_SVG } from 'shared/constants';

const GITHUB = 'github';
const DOCKER = 'hub.docker.com';
const GCR = 'us.gcr.io';
const GAR = 'us-docker.pkg.dev';

export const getSourceImage = (origin: string): string => {
  const lcOrigin = origin.toLowerCase();
  switch (lcOrigin) {
    case GITHUB:
      return GITHUB_LOGO_SVG;
    case DOCKER:
      return DOCKER_LOGO_SVG;
    case GCR:
      return GCR_LOGO_SVG;
    case GAR:
      return GAR_LOGO_SVG;
    default:
      return '';
  }
};
