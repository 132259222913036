import { TypedDocumentNode, gql } from '@apollo/client';
import {
  CalculateRiskQuery,
  CalculateRiskQueryVariables,
  GetCvssQuery,
  GetCvssQueryVariables,
} from '__generated__/graphql';

export const GET_CVSS: TypedDocumentNode<GetCvssQuery, GetCvssQueryVariables> = gql`
  query getCvss($id: ID!) {
    issue(id: $id) {
      id
      cve {
        id
        cvssScore
      }
      risk {
        id
        risk
      }
    }
  }
`;

export const CALCULATE_RISK: TypedDocumentNode<CalculateRiskQuery, CalculateRiskQueryVariables> = gql`
  query calculateRisk($input: CalculateRiskInputs!) {
    calculateRisk(input: $input) {
      risk
      timeToMitigate
      timeToRelease
      suggestedAction
      releaseType
    }
  }
`;
