import { DataFrame, getFieldDisplayName } from '@grafana/data';
import { CustomTransformOperator } from '@grafana/scenes';
import { RiskLevel } from '__generated__/graphql';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface SortByField {
  field: string;
  desc?: boolean;
  index?: number;
}

interface SortByRiskTransformationType {
  sort: SortByField;
}

// Note: this transform does not support variable interpolation for field names
export const sortByRiskTransform: (options: SortByRiskTransformationType) => CustomTransformOperator =
  (options: SortByRiskTransformationType) => () => (source: Observable<DataFrame[]>) => {
    return source.pipe(
      map((data: DataFrame[]) => {
        if (!Array.isArray(data) || data.length === 0 || !options?.sort) {
          return data;
        }
        return sortDataFrames(data, options.sort);
      })
    );
  };

const sortDataFrames = (data: DataFrame[], sort: SortByField): DataFrame[] => {
  return data.map((frame) => {
    const s = attachFieldIndex(frame, sort);
    if (s && s.index != null) {
      return sortDataFrame(frame, s.index, s.desc);
    }
    return frame;
  });
};

const attachFieldIndex = (frame: DataFrame, sort: SortByField): SortByField => {
  if (sort.index != null) {
    return sort;
  }
  return {
    ...sort,
    index: frame.fields.findIndex((f) => sort.field === getFieldDisplayName(f, frame)),
  };
};

function isRiskLevel(value: any): value is RiskLevel {
  return Object.values(RiskLevel).includes(value);
}

const riskComparer = (values: string[], reverse: boolean) => {
  const riskOrder = [
    RiskLevel.Maximum,
    RiskLevel.Severe,
    RiskLevel.Significant,
    RiskLevel.Moderate,
    RiskLevel.Minor,
    RiskLevel.None,
  ];
  return (a: number, b: number) => {
    const stringA = values[a] ? values[a].toUpperCase() : undefined;
    const stringB = values[b] ? values[b].toUpperCase() : undefined;

    const riskA = isRiskLevel(stringA) ? (stringA as RiskLevel) : RiskLevel.None;
    const riskB = isRiskLevel(stringB) ? (stringB as RiskLevel) : RiskLevel.None;

    const indexA = riskOrder.indexOf(riskA);
    const indexB = riskOrder.indexOf(riskB);

    return reverse ? indexB - indexA : indexA - indexB;
  };
};

const sortDataFrame = (data: DataFrame, sortIndex?: number, reverse = false): DataFrame => {
  const field = data.fields[sortIndex!];
  if (!field) {
    return data;
  }

  const index: number[] = [];
  for (let i = 0; i < data.length; i++) {
    index.push(i);
  }

  const fieldComparer = riskComparer(field.values, reverse);
  index.sort(fieldComparer);

  return {
    ...data,
    fields: data.fields.map((f) => {
      const newF = {
        ...f,
        values: f.values.map((v, i) => f.values[index[i]]),
      };

      return newF;
    }),
  };
};
