import { useMutation } from '@apollo/client';
import { css } from '@emotion/css';
import { AppEvents } from '@grafana/data';
import { config, getAppEvents } from '@grafana/runtime';
import { useStyles2, Box, Modal, Button, Text, Stack, LoadingPlaceholder } from '@grafana/ui';
import { AddCveExceptionMutation } from '__generated__/graphql';
import { Suspense } from 'react';

import { capitalizeFirstLetter } from '../utils';

import type { ExceptionFormType } from './CreateException';
import { ADD_CVE_EXCEPTION } from './CreateExceptionMutations';
import { ImpactedVersionList } from './ImpactedVersionList';
import { buildAddExceptionPayload } from './utils';

interface ConfirmationModalType {
  formData: ExceptionFormType;
  isOpen: boolean;
  onDismiss: () => void;
  onConfirm: () => void;
}

export const ExceptionConfirmationModal = ({ formData, isOpen, onDismiss, onConfirm }: ConfirmationModalType) => {
  const styles = useStyles2(getStyles);

  const [addCveException] = useMutation<AddCveExceptionMutation>(ADD_CVE_EXCEPTION, {
    ignoreResults: true,
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to create CVE exception`],
      });
    },
    onCompleted: () => {
      getAppEvents().publish({
        type: AppEvents.alertSuccess.name,
        payload: [`Created new CVE exception`],
      });
    },
  });

  const user = config.bootData.user;

  const onSubmit = () => {
    addCveException({
      variables: {
        input: buildAddExceptionPayload(formData),
      },
    });
    onConfirm();
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      title={
        <Stack direction="column">
          <Box marginTop={1}>
            <Text element="h2">
              {formData.category}: {formData.cve!.label}
            </Text>
          </Box>
          <Box marginBottom={1}>
            <Text element="h4" variant="h5" color="secondary" italic>
              {capitalizeFirstLetter(formData.cve!.description!)}
            </Text>
          </Box>
        </Stack>
      }
      className={styles.modal}
    >
      <Stack direction="column">
        <Text element="h5">Creating this exception will affect the following:</Text>
        <Suspense fallback={<LoadingPlaceholder text="Loading affected versions..." />}>
          <ImpactedVersionList cveID={formData.cve!.value!} targets={formData.targets} />
        </Suspense>
      </Stack>
      <Modal.ButtonRow>
        <Button variant="secondary" fill="outline" onClick={onDismiss}>
          Cancel
        </Button>
        <Button
          variant="destructive"
          onClick={onSubmit}
          {...(!user.isSignedIn && {
            disabled: true,
            tooltip: 'Sign in to create an exception',
          })}
        >
          Create Exception
        </Button>
      </Modal.ButtonRow>
    </Modal>
  );
};

const getStyles = () => ({
  modal: css({
    top: '30%',
  }),
});
