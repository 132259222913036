import { Alert, ErrorBoundary, LoadingPlaceholder } from '@grafana/ui';
import { Suspense } from 'react';

import { Compare } from './Compare';

interface CompareContainerType {
  sourceID: string;
  cve: string;
  severity: string;
}

export const CompareContainer = ({ sourceID, cve, severity }: CompareContainerType) => {
  return (
    <ErrorBoundary>
      {({ error }) => {
        if (error) {
          return (
            <Alert
              title="Error: failed to load compare"
              onRemove={() => window.location.reload()}
              buttonContent="Reload"
            >
              {error.message}
            </Alert>
          );
        }
        return (
          <Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
            <Compare sourceID={sourceID} cve={cve} severity={severity} />
          </Suspense>
        );
      }}
    </ErrorBoundary>
  );
};
