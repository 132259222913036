import { TypedDocumentNode, gql } from '@apollo/client';
import { DeactivateCveExceptionMutation, DeactivateCveExceptionMutationVariables } from '__generated__/graphql';

export const DEACTIVATE_CVE_EXCEPTION: TypedDocumentNode<
  DeactivateCveExceptionMutation,
  DeactivateCveExceptionMutationVariables
> = gql`
  mutation deactivateCveException($input: DeactivateCveExceptionInput!) {
    deactivateCveException(input: $input)
  }
`;
