import { ErrorBoundary, Alert, LoadingPlaceholder } from '@grafana/ui';
import { Suspense } from 'react';

import { CveSummaryTable } from './CveSummaryTable';

interface CveSummaryTableType {
  cve: string;
}

export const CveSummaryTableContainer = ({ cve }: CveSummaryTableType) => {
  return (
    <ErrorBoundary>
      {({ error }) => {
        if (error) {
          return (
            <Alert
              title="Error: failed to load CVE summary table"
              onRemove={() => window.location.reload()}
              buttonContent="Reload"
            >
              {error.message}
            </Alert>
          );
        }
        return (
          <Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
            <CveSummaryTable cve={cve} />
          </Suspense>
        );
      }}
    </ErrorBoundary>
  );
};
