import { useQuery } from '@apollo/client';
import { Button, Stack } from '@grafana/ui';
import { GetExceptionHeaderQuery } from '__generated__/graphql';
import { useState } from 'react';

import { DeactivateExceptionModal } from './DeactivateExceptionModal';
import { GET_EXCEPTION_HEADER } from './ExceptionDetailsHeaderQueries';

interface DeactivateExceptionButtonType {
  exceptionID: string;
}

export const DeactivateExceptionButton = ({ exceptionID }: DeactivateExceptionButtonType) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { data, loading, error } = useQuery<GetExceptionHeaderQuery>(GET_EXCEPTION_HEADER, {
    variables: {
      id: exceptionID,
    },
  });

  if (loading || error) {
    return null;
  }

  const { cve, id, isActive } = data?.cveException!;

  return (
    <Stack alignItems="center">
      <Button
        variant="destructive"
        size="sm"
        disabled={Boolean(loading) || Boolean(error) || !isActive}
        tooltip={isActive ? '' : 'This exception was deactivated'}
        onClick={() => setIsModalOpen(true)}
      >
        Deactivate Exception
      </Button>
      {data && (
        <DeactivateExceptionModal
          isOpen={isModalOpen}
          onDismiss={() => setIsModalOpen(false)}
          cve={cve.cve}
          exceptionID={id}
        />
      )}
    </Stack>
  );
};
