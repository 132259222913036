import {
  SceneObjectState,
  SceneObjectBase,
  SceneComponentProps,
  sceneGraph,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { SourceCardGridContainer } from 'scenes/components/SourceCard';

interface SourcesState extends SceneObjectState {
  source: string;
  type: string;
  group: string;
  sourceFirst: string;
  sourceAfter: string;
}

export class Sources extends SceneObjectBase<SourcesState> {
  static Component = SourcesRenderer;

  protected _variableDependency = new VariableDependencyConfig(this, {
    statePaths: ['source', 'type', 'group', 'sourceFirst', 'sourceAfter'],
  });
}

function SourcesRenderer({ model }: SceneComponentProps<Sources>) {
  const { source, type, group, sourceFirst, sourceAfter } = model.useState();

  return (
    <SourceCardGridContainer
      source={sceneGraph.interpolate(model, source)}
      type={sceneGraph.interpolate(model, type)}
      group={sceneGraph.interpolate(model, group)}
      first={sceneGraph.interpolate(model, sourceFirst)}
      after={sceneGraph.interpolate(model, sourceAfter)}
    />
  );
}
