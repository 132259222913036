import { useQuery } from '@apollo/client';
import { Stack, Text } from '@grafana/ui';
import { GetSourceHeaderQuery } from '__generated__/graphql';
import { GITHUB_PREFIX } from 'shared/constants';

import { getSourceImage } from '../utils';

import { GET_SOURCE_HEADER } from './SourceHeaderQueries';

interface SourceHeaderType {
  sourceID: string;
}

export const SourceHeader = ({ sourceID }: SourceHeaderType) => {
  const { data, loading, error } = useQuery<GetSourceHeaderQuery>(GET_SOURCE_HEADER, {
    variables: {
      id: sourceID,
    },
  });

  if (loading || error) {
    return null;
  }

  const { name, origin } = data!.source;
  const sourceImage = getSourceImage(origin);

  return (
    <Stack justifyContent="flex-start" alignItems="center" gap={1}>
      <img src={sourceImage} alt="logo" height="30px" width="30px" />
      <Text element="h1" variant="h2">
        {name.replace(GITHUB_PREFIX, '')}
      </Text>
    </Stack>
  );
};
