import { CustomCellRendererProps, Icon, Stack, Tooltip, useTheme2 } from '@grafana/ui';
import { memo } from 'react';
import { Link } from 'react-router-dom-v5-compat';
import { ISSUES_ROUTE } from 'shared/constants';

const ActionsCell = memo((props: CustomCellRendererProps) => {
  const theme = useTheme2();

  return (
    <Stack justifyContent="space-evenly">
      <Link to={`${ISSUES_ROUTE}/${props.value}/report`}>
        <Tooltip content="Full Details">
          <Icon name="book" size="lg" color={theme.visualization.getColorByName('blue')} />
        </Tooltip>
      </Link>
      <Link to={`${ISSUES_ROUTE}/${props.value}/activity`}>
        <Tooltip content="Activity">
          <Icon name="list-ul" size="lg" color={theme.visualization.getColorByName('light-grey')} />
        </Tooltip>
      </Link>
      <Link to={`${ISSUES_ROUTE}/${props.value}/modify`}>
        <Tooltip content="Modify Risk">
          <Icon name="edit" size="lg" color={theme.visualization.getColorByName('red')} />
        </Tooltip>
      </Link>
    </Stack>
  );
});

ActionsCell.displayName = 'ActionsCell';

export { ActionsCell };
