import { TypedDocumentNode, gql } from '@apollo/client';
import { GetSourcesQuery, GetSourcesQueryVariables } from '__generated__/graphql';

export const GET_SOURCES: TypedDocumentNode<GetSourcesQuery, GetSourcesQueryVariables> = gql`
  query getSources($filters: SourceFilters, $first: Int, $after: Int) {
    sources(filters: $filters, first: $first, after: $after) {
      response {
        id
        name
        origin
        versions {
          id
          version
        }
        groups {
          id
          name
        }
      }
    }
  }
`;
