import { TypedDocumentNode, gql } from '@apollo/client';
import { GetCveExceptionsQuery, GetCveExceptionsQueryVariables } from '__generated__/graphql';

export const GET_CVE_EXCEPTIONS: TypedDocumentNode<GetCveExceptionsQuery, GetCveExceptionsQueryVariables> = gql`
  query getCveExceptions($filters: CveExceptionFilters, $first: Int, $after: Int) {
    cveExceptions(filters: $filters, first: $first, after: $after) {
      response {
        id
        cve {
          id
          cve
        }
        reason
        createdOn
        exceptionVersions {
          version {
            id
            version
            source {
              id
              name
              type
            }
          }
        }
        exceptionSources {
          source {
            id
            name
            type
          }
        }
      }
      metadata {
        totalCount
      }
    }
  }
`;
