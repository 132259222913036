import { useQuery } from '@apollo/client';
import { Button } from '@grafana/ui';
import { GetScanPayloadQuery } from '__generated__/graphql';
import { useState } from 'react';

import { TriggerScanModal } from './TriggerScanModal';
import { GET_SCAN_PAYLOAD } from './TriggerScanQueries';

interface TriggerScanButtonType {
  versionID: string;
}

export const TriggerScanButton = ({ versionID }: TriggerScanButtonType) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { data, loading, error } = useQuery<GetScanPayloadQuery>(GET_SCAN_PAYLOAD, {
    variables: {
      id: versionID,
    },
  });

  return (
    <>
      <Button size="sm" disabled={Boolean(loading) || Boolean(error)} onClick={() => setIsModalOpen(true)}>
        Trigger a Scan
      </Button>
      {data && <TriggerScanModal isOpen={isModalOpen} onDismiss={() => setIsModalOpen(false)} version={data.version} />}
    </>
  );
};
