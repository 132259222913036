import { useLazyQuery, useSuspenseQuery } from '@apollo/client';
import { css, cx } from '@emotion/css';
import { AppEvents, GrafanaTheme2, SelectableValue } from '@grafana/data';
import { config, getAppEvents } from '@grafana/runtime';
import {
  useStyles2,
  Field,
  Button,
  Legend,
  Select,
  ReactMonacoEditor,
  RadioButtonGroup,
  Input,
  Checkbox,
  Tooltip,
  Label,
  Icon,
  TextLink,
  Stack,
  Box,
  LoadingPlaceholder,
  Text,
} from '@grafana/ui';
import { CalculateRiskQuery, GetCvssQuery, Impact, ModifyRiskMutationVariables } from '__generated__/graphql';
import { useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import { capitalizeFirstLetter } from '../utils';

import { ConfirmationModal } from './ConfirmationModal';
import { GET_CVSS, CALCULATE_RISK } from './ModifyRiskQueries';
import { Exploitability, ExploitabilityOptions, ImpactOptions } from './constants';
import { prepareRiskPayload } from './utils';

export interface ModifyRiskFormInput {
  exploitability: SelectableValue<Exploitability> | null;
  confidentialityImpact: Impact | null;
  integrityImpact: Impact | null;
  availabilityImpact: Impact | null;
  cvssScore: number | null;
  isThirdParty: boolean;
  comment: string;
}

interface ModifyRiskFormType {
  issueID: string;
}

export const ModifyRiskForm = ({ issueID }: ModifyRiskFormType) => {
  const styles = useStyles2(getStyles);
  const [showConfirmation, setShowConfirmation] = useState<Boolean>(false);
  const [modifyRiskPayload, setModifyRiskPayload] = useState<ModifyRiskMutationVariables | null>(null);

  const {
    data: { issue },
  } = useSuspenseQuery<GetCvssQuery>(GET_CVSS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: issueID,
    },
  });

  const [calculateRisk, { data, loading }] = useLazyQuery<CalculateRiskQuery>(CALCULATE_RISK, {
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to calculate risk`],
      });
      setShowConfirmation(false);
    },
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<ModifyRiskFormInput>({
    defaultValues: {
      exploitability: null,
      confidentialityImpact: null,
      integrityImpact: null,
      availabilityImpact: null,
      cvssScore: null,
      isThirdParty: true,
      comment: '',
    },
  });

  useEffect(() => {
    setValue('cvssScore', issue.cve.cvssScore);
  }, [issue, setValue]);

  const onCalculate: SubmitHandler<ModifyRiskFormInput> = (formData) => {
    calculateRisk({
      variables: {
        input: {
          ...prepareRiskPayload(formData),
        },
      },
    });
    setShowConfirmation(true);
    setModifyRiskPayload({
      input: {
        ...prepareRiskPayload(formData),
        issueId: issueID,
        userId: user.id.toString(),
        userName: user.name,
        gravatarUrl: user.gravatarUrl,
        comment: formData.comment,
      },
    });
  };

  const onConfirm = () => {
    reset();
    setValue('cvssScore', issue.cve.cvssScore);
    setShowConfirmation(false);
  };

  const user = config.bootData.user;
  return (
    <>
      <Box element="form" onSubmit={handleSubmit(onCalculate)} padding={1.5} borderColor="weak" borderStyle="solid">
        <Stack justifyContent="space-between">
          <Legend>Modify Risk</Legend>
          <span className={styles.currentRisk}>
            <Text color="secondary" variant="h5">
              Current Risk: {issue.risk ? capitalizeFirstLetter(issue.risk?.risk) : 'Unknown'}
            </Text>
          </span>
        </Stack>
        <Stack>
          <Field
            label={
              <Label>
                <Stack>
                  <span>Exploitability</span>
                  <Tooltip
                    interactive
                    content={
                      <>
                        <p>
                          This metric measures the current state of exploit techniques or code availability. Public
                          availability of easy-to-use exploit code increases the number of potential attackers by
                          including those who are unskilled, thereby increasing the severity of the vulnerability.
                        </p>
                        <TextLink
                          href="https://www.first.org/cvss/v2/guide#2-2-1-Exploitability-E"
                          variant="bodySmall"
                          inline
                          external
                        >
                          Read more on first.org
                        </TextLink>
                      </>
                    }
                  >
                    <Icon name="info-circle" size="sm" />
                  </Tooltip>
                </Stack>
              </Label>
            }
          >
            <Controller
              name="exploitability"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <Select {...field} width={35} options={ExploitabilityOptions} invalid={!!errors.exploitability} />
              )}
            />
          </Field>
          <Field
            label={
              <Label>
                <Stack>
                  <span>CVSS Score</span>
                  <Tooltip
                    interactive
                    content={
                      <>
                        <p>The Common Vulnerability Scoring System</p>
                        <TextLink
                          href="https://www.first.org/cvss/v2/guide#1-1-What-is-CVSS"
                          variant="bodySmall"
                          inline
                          external
                        >
                          Read more on first.org
                        </TextLink>
                      </>
                    }
                  >
                    <Icon name="info-circle" size="sm" />
                  </Tooltip>
                </Stack>
              </Label>
            }
          >
            <Input {...register('cvssScore')} width={12} disabled />
          </Field>
        </Stack>
        <Stack>
          <Field
            label={
              <Label>
                <Stack>
                  <span>Confidentiality Impact</span>
                  <Tooltip
                    interactive
                    content={
                      <>
                        <p>
                          This metric measures the impact on confidentiality of a successfully exploited vulnerability.
                          Confidentiality refers to limiting information access and disclosure to only authorized users,
                          as well as preventing access by, or disclosure to, unauthorized ones. Increased
                          confidentiality impact increases the vulnerability score.
                        </p>
                        <TextLink
                          href="https://www.first.org/cvss/v2/guide#2-1-4-Confidentiality-Impact-C"
                          variant="bodySmall"
                          inline
                          external
                        >
                          Read more on first.org
                        </TextLink>
                      </>
                    }
                  >
                    <Icon name="info-circle" size="sm" />
                  </Tooltip>
                </Stack>
              </Label>
            }
          >
            <Controller
              name="confidentialityImpact"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <RadioButtonGroup
                  {...field}
                  size="md"
                  options={ImpactOptions}
                  className={cx({ [styles.invalid]: !!errors.confidentialityImpact })}
                />
              )}
            />
          </Field>
          <Field
            label={
              <Label>
                <Stack>
                  <span>Integrity Impact</span>
                  <Tooltip
                    interactive
                    content={
                      <>
                        <p>
                          This metric measures the impact to integrity of a successfully exploited vulnerability.
                          Integrity refers to the trustworthiness and guaranteed veracity of information. Increased
                          integrity impact increases the vulnerability score.
                        </p>
                        <TextLink
                          href="https://www.first.org/cvss/v2/guide#2-1-5-Integrity-Impact-I"
                          variant="bodySmall"
                          inline
                          external
                        >
                          Read more on first.org
                        </TextLink>
                      </>
                    }
                  >
                    <Icon name="info-circle" size="sm" />
                  </Tooltip>
                </Stack>
              </Label>
            }
          >
            <Controller
              name="integrityImpact"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <RadioButtonGroup
                  {...field}
                  size="md"
                  options={ImpactOptions}
                  className={cx({ [styles.invalid]: !!errors.integrityImpact })}
                />
              )}
            />
          </Field>
          <Field
            label={
              <Label>
                <Stack>
                  <span>Availability Impact</span>
                  <Tooltip
                    interactive
                    content={
                      <>
                        <p>
                          This metric measures the impact to availability of a successfully exploited vulnerability.
                          Availability refers to the accessibility of information resources. Attacks that consume
                          network bandwidth, processor cycles, or disk space all impact the availability of a system.
                          Increased availability impact increases the vulnerability score.
                        </p>
                        <TextLink
                          href="https://www.first.org/cvss/v2/guide#2-1-6-Availability-Impact-A"
                          variant="bodySmall"
                          inline
                          external
                        >
                          Read more on first.org
                        </TextLink>
                      </>
                    }
                  >
                    <Icon name="info-circle" size="sm" />
                  </Tooltip>
                </Stack>
              </Label>
            }
          >
            <Controller
              name="availabilityImpact"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <RadioButtonGroup
                  {...field}
                  size="md"
                  options={ImpactOptions}
                  className={cx({ [styles.invalid]: !!errors.availabilityImpact })}
                />
              )}
            />
          </Field>
        </Stack>
        <Field>
          <Checkbox {...register('isThirdParty')} label="Is Third Party?" disabled />
        </Field>
        <Field label="Justification" invalid={!!errors.comment}>
          <Controller
            name="comment"
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <ReactMonacoEditor
                {...field}
                height="240px"
                language="markdown"
                className={cx(styles.editor, { [styles.invalid]: !!errors.comment })}
                options={{
                  overviewRulerLanes: 0,
                  wordWrap: 'on',
                  renderLineHighlight: 'none',
                  folding: false,
                  lineNumbers: 'off',
                  lineDecorationsWidth: 8,
                  fontSize: 14,
                  minimap: {
                    enabled: false,
                  },
                  scrollbar: {
                    vertical: 'hidden',
                  },
                }}
              />
            )}
          />
        </Field>
        <Stack justifyContent="space-between">
          <em>Working as: {user.name || 'anonymous'}</em>
          <Stack alignItems="center" direction="row">
            {loading && <LoadingPlaceholder text="Calculating..." className={styles.loading} />}
            <Button type="submit">Calculate Risk</Button>
          </Stack>
        </Stack>
      </Box>
      {data && modifyRiskPayload && (
        <ConfirmationModal
          originalRisk={issue.risk?.risk || 'unknown'}
          calculatedRisk={data.calculateRisk}
          modifyRiskPayload={modifyRiskPayload}
          isOpen={!loading && Boolean(showConfirmation)}
          onDismiss={() => setShowConfirmation(false)}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  editor: css({
    background: theme.colors.background.canvas,
    border: `1px solid ${theme.colors.border.weak}`,
    padding: '4px 0px',
  }),
  invalid: css({
    border: `1px solid ${theme.colors.error.border}`,
  }),
  loading: css({
    marginBottom: 0,
  }),
  currentRisk: css({
    minWidth: '180px',
    textAlign: 'right',
  }),
});
