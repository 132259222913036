import { useSuspenseQuery } from '@apollo/client';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Icon, LinkButton, Pagination, Stack, Tag, Text, Tooltip, useStyles2 } from '@grafana/ui';
import { GetBugBountySubmissionsQuery } from '__generated__/graphql';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { BUG_BOUNTY_REPORT_ROUTE } from 'shared/constants';

import { formatDate } from '../utils';

import { BugBountySeverityBox } from './BugBountySeverityBox';
import { CveEntryBox } from './CveEntryBox';
import { GET_BUG_BOUNTY_SUBMISSIONS } from './ExploreBugBountySubmissionsQueries';

interface ExploreBugBountySubmissionsType {
  submissionCode: string;
  squad: string;
  severity: string;
  status: string;
  sortBy: string;
  from: string;
  to: string;
  offset: number;
  pageSize: number;
  onNavigate: (page: number) => void;
}

const ExploreBugBountySubmissions = memo(
  ({
    submissionCode,
    squad,
    severity,
    status,
    sortBy,
    from,
    to,
    offset,
    pageSize,
    onNavigate,
  }: ExploreBugBountySubmissionsType) => {
    const styles = useStyles2(getStyles);
    const navigate = useNavigate();

    const { data } = useSuspenseQuery<GetBugBountySubmissionsQuery>(GET_BUG_BOUNTY_SUBMISSIONS, {
      variables: {
        filters: {
          submissionCode,
          squad,
          severity,
          status,
          sortBy,
          from,
          to,
        },
        first: pageSize,
        after: offset,
      },
    });

    let USD = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    const submissions = data.bugBountySubmissions.response;

    return (
      <Stack direction="column">
        {submissions.length ? (
          <>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Submission Code</th>
                  <th>Title</th>
                  <th>Severity</th>
                  <th>Status</th>
                  <th>Squad</th>
                  <th>Reward</th>
                  <th className={styles.cveColumn}>
                    <Stack alignItems="center">
                      <Text>CVE</Text>
                      <Tooltip
                        interactive
                        content={<Text element="p">Click a CVE to edit. Press Enter to submit the change.</Text>}
                      >
                        <Icon name="info-circle" size="sm" />
                      </Tooltip>
                    </Stack>
                  </th>
                  <th>Created On</th>
                </tr>
              </thead>
              <tbody>
                {submissions.map((submission, index) => (
                  <tr key={`${submission.submissionCode}-${index}`}>
                    <td>
                      <LinkButton
                        className={styles.linkButton}
                        fill="text"
                        onClick={() => navigate(`${BUG_BOUNTY_REPORT_ROUTE}/${submission.submissionCode}`)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {submission.submissionCode}
                      </LinkButton>
                    </td>
                    <td>{submission.title}</td>
                    <td>
                      <BugBountySeverityBox value={submission.severity} />
                    </td>
                    <td>
                      {submission.status ? (
                        submission.status
                      ) : (
                        <Text italic color="secondary">
                          Unknown
                        </Text>
                      )}
                    </td>
                    <td>
                      {submission.squad ? (
                        <Tag name={submission.squad} key={`squad-${submission.submissionCode}-${submission.squad}`} />
                      ) : (
                        <Text italic color="secondary">
                          Unassigned
                        </Text>
                      )}
                    </td>
                    <td>
                      {submission.reward ? (
                        USD.format(submission.reward)
                      ) : (
                        <Text italic color="secondary">
                          No Payout
                        </Text>
                      )}
                    </td>
                    <CveEntryBox submission={submission} />
                    <td>{formatDate(new Date(submission.createdAt), false)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Stack direction="row-reverse">
              <Pagination
                currentPage={Math.ceil((offset + 1) / pageSize)}
                numberOfPages={Math.ceil(data.bugBountySubmissions.metadata.totalCount / pageSize)}
                onNavigate={onNavigate}
                hideWhenSinglePage
              />
            </Stack>
          </>
        ) : (
          <Text italic color="secondary">
            No bug bounty submissions
          </Text>
        )}
      </Stack>
    );
  }
);

ExploreBugBountySubmissions.displayName = 'ExploreBugBountySubmissions';

export { ExploreBugBountySubmissions };

const getStyles = (theme: GrafanaTheme2) => ({
  table: css({
    marginTop: theme.spacing(1),
    width: '100%',
    borderCollapse: 'collapse',
    'thead tr': {
      backgroundColor: theme.colors.background.primary,
    },
    'tbody tr:nth-of-type(odd)': {
      backgroundColor: theme.colors.background.secondary,
    },
    'tbody tr:nth-of-type(even)': {
      backgroundColor: theme.colors.background.primary,
    },
    'th, td': {
      padding: '8px',
    },
  }),
  linkButton: css({
    padding: theme.spacing(0),
  }),
  cveColumn: css({
    width: '160px',
  }),
});
