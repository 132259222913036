import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { CustomCellRendererProps, TagList, Text, useStyles2 } from '@grafana/ui';
import { Group } from '__generated__/graphql';
import { memo } from 'react';

const GroupsCell = memo((props: CustomCellRendererProps) => {
  const styles = useStyles2(getStyles);

  const { text } = props.field.display!(props.value);
  const groups = JSON.parse(text) as Group[];

  if (!groups.length) {
    return (
      <Text color="secondary" italic>
        No groups
      </Text>
    );
  }

  return <TagList className={styles.tags} tags={groups.map((g) => g.name)} />;
});

const getStyles = (theme: GrafanaTheme2) => ({
  tags: css({
    svg: {
      marginRight: theme.spacing(0.5),
    },
  }),
});

GroupsCell.displayName = 'GroupsCell';

export { GroupsCell };
