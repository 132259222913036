import { SceneQueryRunner } from '@grafana/scenes';
import { getInfinityGQLQuery } from 'scenes/datasources/infinity';

export const getCvesCountQueryRunner = (apiUrl: string) =>
  new SceneQueryRunner(
    getInfinityGQLQuery([
      {
        refId: 'B',
        url: apiUrl,
        root_selector: 'data.cves',
        url_options: {
          body_graphql_query: `
              query getCves($filters: CveFilters, $first: Int, $after: Int) {
                cves(filters: $filters, first: $first, after: $after) {
                  metadata {
                    totalCount
                  }
                }
              }
            `,
          body_graphql_variables: JSON.stringify({
            filters: {
              cve: '$cve',
              package: '$package',
              sortBy: '$sortBy',
            },
            first: '$cveFirst',
            after: '$cveAfter',
          }),
        },
      },
    ])
  );
