import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { usePluginInteractionReporter } from '@grafana/runtime';
import { Card, Text, useStyles2, AlertVariant, Alert, Stack } from '@grafana/ui';
import { Version, Source } from '__generated__/graphql';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { VERSIONS_CARD_CLICK, SOURCES_ROUTE, GITHUB_PREFIX } from 'shared/constants';

import { formatDate, getSourceImage } from '../utils';

import { SeverityCounts } from './SeverityCounts';

interface VersionSummaryCardProps {
  version: Version;
  source: Source;
}

export interface CveCounts {
  critical: number;
  high: number;
  medium: number;
  low: number;
}

export const VersionSummaryCard = ({ version, source }: VersionSummaryCardProps) => {
  const styles = useStyles2(getStyles);
  const report = usePluginInteractionReporter();
  const navigate = useNavigate();
  const versionRoute = `${SOURCES_ROUTE}/${source.id}/version/${version.id}`;
  const sourceImage = getSourceImage(source.origin);
  const lowestSlo = version.issues.reduce((lowest, iss) => {
    if (!iss.isDismissed && iss.sloRemaining < lowest) {
      return iss.sloRemaining;
    }
    return lowest;
  }, Infinity);

  const warnThreshold = 7;
  let alertProps: {
    title: string;
    severity: AlertVariant;
  };
  if (lowestSlo <= 0) {
    alertProps = {
      title: 'Out of SLO',
      severity: 'error',
    };
  } else if (lowestSlo <= warnThreshold) {
    alertProps = {
      title: 'SLO running low',
      severity: 'warning',
    };
  } else {
    alertProps = {
      title: 'Inside SLOs',
      severity: 'success',
    };
  }

  const { cveTotals, cvesTotalsOutOfSlo } = useMemo(() => {
    const cveTotals: CveCounts = {
      critical: 0,
      high: 0,
      medium: 0,
      low: 0,
    };

    const cvesTotalsOutOfSlo: CveCounts = {
      critical: 0,
      high: 0,
      medium: 0,
      low: 0,
    };

    const seenCves: string[] = [];
    const seenCvesOutOfSlo: string[] = [];
    version.issues.forEach(({ cve, isDismissed, sloRemaining }) => {
      if (isDismissed) {
        return;
      }

      const severity = cve.severity.toLowerCase() as keyof CveCounts;
      if (!seenCves.includes(cve.id)) {
        cveTotals[severity] += 1;
        seenCves.push(cve.id);
      }

      if (sloRemaining <= 0) {
        if (!seenCvesOutOfSlo.includes(cve.id)) {
          cvesTotalsOutOfSlo[severity] += 1;
          seenCvesOutOfSlo.push(cve.id);
        }
      }
    });

    return { cveTotals, cvesTotalsOutOfSlo };
  }, [version]);

  return (
    <Card
      href={versionRoute}
      onClick={() =>
        report(VERSIONS_CARD_CLICK, {
          version_id: version.id,
        })
      }
    >
      <Card.Figure>
        <img src={sourceImage} alt="logo" height="40px" width="40px" className={styles.logo} />
      </Card.Figure>
      <Card.Heading>
        {source.name.replace(GITHUB_PREFIX, '')}:{version.version}
        {version.isMostRecentVersion && <Text color="secondary"> (latest)</Text>}
      </Card.Heading>
      <Card.Meta>{`Last Scan: ${formatDate(new Date(version.latestScan))}`}</Card.Meta>
      <div className={styles.descriptionArea}>
        <Stack direction="column">
          <Stack direction="row" alignItems="center">
            <Text color="primary">CVEs&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>
            <SeverityCounts counts={cveTotals as CveCounts} />
          </Stack>
          <Stack direction="row" alignItems="center">
            <Text color="primary">Out of SLO</Text>
            <SeverityCounts counts={cvesTotalsOutOfSlo as CveCounts} isSlo />
          </Stack>
        </Stack>
      </div>
      <Card.Tags>
        <Alert {...alertProps} bottomSpacing={0} onClick={() => navigate(versionRoute)} />
      </Card.Tags>
    </Card>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  logo: css({
    objectFit: 'contain',
  }),
  descriptionArea: css({
    gridArea: 'Description',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  }),
});
