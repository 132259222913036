import { SelectableValue } from '@grafana/data';
import { Alert, Box, Button, ErrorBoundary, FilterInput, LoadingPlaceholder, Select, Stack, Text } from '@grafana/ui';
import { Role } from '__generated__/graphql';
import { Suspense, useMemo, useState, startTransition } from 'react';

import { debounce } from '../utils';

import { AddUserModal } from './AddUserModal';
import { UserTable } from './UserTable';

const PAGE_SIZE = 10;

export const Users = () => {
  const [userSearch, setUserSearch] = useState<string>('');
  const [tempUserSearch, setTempUserSearch] = useState<string>('');
  const [roleSearch, setRoleSearch] = useState<string>('');
  const [offset, setOffset] = useState<number>(0);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);

  const debouncedSetUserSearch = useMemo(
    () =>
      debounce((search: string) => {
        startTransition(() => {
          setUserSearch(search);
          setOffset(0);
        });
      }, 200),
    []
  );

  const onUpdateTempSearch = (value: string) => {
    setTempUserSearch(value);
    debouncedSetUserSearch(value);
  };

  const onUpdateRole = ({ value }: SelectableValue) => {
    startTransition(() => {
      setRoleSearch(value);
      setOffset(0);
    });
  };

  const onNavigate = (page: number) => {
    startTransition(() => {
      setOffset(PAGE_SIZE * (page - 1));
    });
  };

  return (
    <ErrorBoundary>
      {({ error }) => {
        if (error) {
          return (
            <Alert title="Error: failed to load users" onRemove={() => window.location.reload()} buttonContent="Reload">
              {error.message}
            </Alert>
          );
        }
        return (
          <Stack direction="column">
            <Box marginBottom={1}>
              <Text element="h2">Manage Access</Text>
            </Box>
            <Stack alignItems="center" justifyContent="space-between">
              <Stack gap={2}>
                <FilterInput
                  width={40}
                  placeholder="Search Users"
                  value={tempUserSearch}
                  onChange={onUpdateTempSearch}
                />
                <Select
                  options={[
                    { label: 'All', value: null },
                    { label: 'Reader', value: Role.Reader },
                    { label: 'Writer', value: Role.Writer },
                    { label: 'Administrator', value: Role.Administrator },
                  ]}
                  width={20}
                  value={roleSearch}
                  onChange={onUpdateRole}
                  placeholder="Select Role"
                />
              </Stack>
              <Button variant="success" icon="plus" onClick={() => setIsAddModalOpen(true)}>
                Add User
              </Button>
            </Stack>
            <Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
              <UserTable
                userSearch={userSearch}
                roleSearch={roleSearch}
                offset={offset}
                onNavigate={onNavigate}
                pageSize={PAGE_SIZE}
              />
            </Suspense>
            <AddUserModal isOpen={isAddModalOpen} onDismiss={() => setIsAddModalOpen(false)} />
          </Stack>
        );
      }}
    </ErrorBoundary>
  );
};
