import {
  EmbeddedScene,
  QueryVariable,
  SceneAppPage,
  SceneFlexLayout,
  SceneVariableSet,
  VariableValueSelectors,
  SceneTimeRange,
  SceneTimePicker,
  SceneControlsSpacer,
  VariableValueOption,
  SceneGridRow,
  SceneGridLayout,
  SceneGridItem,
  CustomVariable,
  SceneByVariableRepeater,
} from '@grafana/scenes';
import { VariableHide } from '@grafana/schema';
import { getPrometheusDatasource } from 'scenes/datasources/prometheus/prometheusDatasource';
import { SLOS_ROUTE } from 'shared/constants';

import {
  getCriticalAndHighInsideSloStat,
  getMediumAndLowInsideSloStat,
  getSloHistoryTimeseries,
  getTotalVulnerabilitiesStat,
} from './panels';
import {
  getCriticalAndHighVulnerabilitiesQueryRunner,
  getMediumAndLowVulnerabilitiesQueryRunner,
  getSloHistoryQueryRunner,
  getTotalVulnerabilitiesQueryRunner,
} from './queryRunners';

interface GetSlosPageType {
  apiUrl: string;
}

export const getSlosPage = ({ apiUrl }: GetSlosPageType) => {
  return new SceneAppPage({
    title: 'CVE SLO Summary',
    subTitle: 'The SLO figure is calculated as the percentage of detected vulnerabilities in a group within SLO',
    key: 'sloSummary',
    url: SLOS_ROUTE,
    getScene: () => {
      return new EmbeddedScene({
        key: 'slos',
        $variables: new SceneVariableSet({
          variables: [
            new CustomVariable({
              name: 'cluster',
              label: 'Cluster',
              value: 'grafanalabs-security-prod',
              text: 'Prod',
              query: 'Prod : grafanalabs-security-prod, Dev : grafanalabs-security-dev,Local : local',
              hide: VariableHide.hideVariable,
            }),
            new QueryVariable({
              name: 'group',
              label: 'Groups',
              isMulti: true,
              includeAll: true,
              defaultToAll: true,
              datasource: getPrometheusDatasource(),
              query: {
                qryType: 1,
                query: 'label_values(vulns_by_group,group)',
                refId: 'variable-A',
              },
            }),
          ],
        }),
        $timeRange: new SceneTimeRange({ from: 'now-7d', to: 'now' }),
        body: new SceneFlexLayout({
          direction: 'column',
          children: [
            new SceneByVariableRepeater({
              variableName: 'group',
              body: new SceneGridLayout({
                children: [],
              }),
              getLayoutChild: (option: VariableValueOption) => {
                return new SceneGridRow({
                  title: option.label,
                  key: `row-${option.value}`,
                  isCollapsed: false,
                  isDraggable: false,
                  isResizable: false,
                  y: 0,
                  x: 0,
                  children: [
                    new SceneGridItem({
                      x: 0,
                      y: 0,
                      width: 4,
                      height: 4,
                      $data: getTotalVulnerabilitiesQueryRunner(option.value as string),
                      body: getTotalVulnerabilitiesStat(),
                    }),
                    new SceneGridItem({
                      x: 4,
                      y: 0,
                      width: 5,
                      height: 4,
                      $data: getCriticalAndHighVulnerabilitiesQueryRunner(option.value as string),
                      body: getCriticalAndHighInsideSloStat(),
                    }),
                    new SceneGridItem({
                      x: 9,
                      y: 0,
                      width: 5,
                      height: 4,
                      $data: getMediumAndLowVulnerabilitiesQueryRunner(option.value as string),
                      body: getMediumAndLowInsideSloStat(),
                    }),
                    new SceneGridItem({
                      x: 14,
                      y: 0,
                      width: 10,
                      height: 4,
                      $data: getSloHistoryQueryRunner(option.value as string),
                      body: getSloHistoryTimeseries(),
                    }),
                  ],
                });
              },
            }),
          ],
        }),
        controls: [new VariableValueSelectors({}), new SceneControlsSpacer(), new SceneTimePicker({})],
      });
    },
  });
};
