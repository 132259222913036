import { PanelBuilders } from '@grafana/scenes';
import { GraphDrawStyle, LineInterpolation, ThresholdsMode, VisibilityMode } from '@grafana/schema';

export const getSloHistoryTimeseries = () =>
  PanelBuilders.timeseries()
    .setTitle('SLO History')
    .setUnit('percent')
    .setCustomFieldConfig('drawStyle', GraphDrawStyle.Line)
    .setCustomFieldConfig('lineInterpolation', LineInterpolation.Linear)
    .setCustomFieldConfig('showPoints', VisibilityMode.Never)
    .setCustomFieldConfig('lineWidth', 1)
    .setCustomFieldConfig('spanNulls', true)
    .setCustomFieldConfig('pointSize', 5)
    .setThresholds({
      mode: ThresholdsMode.Absolute,
      steps: [
        {
          color: 'red',
          value: -Infinity,
        },
        {
          color: 'green',
          value: 100,
        },
      ],
    })
    .setMin(0)
    .setMax(0)
    .setNoValue('None out of SLO')
    .setOverrides((overridesBuilder) => {
      overridesBuilder.matchFieldsByQuery('F').overrideDisplayName('Critical').overrideColor({
        mode: 'fixed',
        fixedColor: 'dark-red',
      });
      overridesBuilder.matchFieldsByQuery('G').overrideDisplayName('High').overrideColor({
        mode: 'fixed',
        fixedColor: 'orange',
      });
      overridesBuilder.matchFieldsByQuery('H').overrideDisplayName('Medium').overrideColor({
        mode: 'fixed',
        fixedColor: 'yellow',
      });
      overridesBuilder.matchFieldsByQuery('I').overrideDisplayName('Low').overrideColor({
        mode: 'fixed',
        fixedColor: 'grey',
      });
    })
    .build();
