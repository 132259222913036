import { DataFrame } from '@grafana/data';
import { CustomTransformOperator } from '@grafana/scenes';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface RemovePrefixTransformationType {
  substring: string;
  fieldName: string;
}

export const removeSubstringTransform: ({
  substring,
  fieldName,
}: RemovePrefixTransformationType) => CustomTransformOperator =
  ({ substring, fieldName }: RemovePrefixTransformationType) =>
  () =>
  (source: Observable<DataFrame[]>) => {
    return source.pipe(
      map((data: DataFrame[]) => {
        return data.map((frame: DataFrame) => {
          return {
            ...frame,
            fields: frame.fields.map((field) => {
              if (field.name === fieldName) {
                return {
                  ...field,
                  values: field.values.map((v) => v.replace(substring, '')),
                };
              }
              return field;
            }),
          };
        });
      })
    );
  };
