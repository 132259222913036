import { TypedDocumentNode, gql } from '@apollo/client';
import { GetBugBountySubmissionsQuery, GetBugBountySubmissionsQueryVariables } from '__generated__/graphql';

export const GET_BUG_BOUNTY_SUBMISSIONS: TypedDocumentNode<
  GetBugBountySubmissionsQuery,
  GetBugBountySubmissionsQueryVariables
> = gql`
  query getBugBountySubmissions($filters: BugBountySubmissionFilters, $first: Int, $after: Int) {
    bugBountySubmissions(filters: $filters, first: $first, after: $after) {
      response {
        submissionCode
        title
        status
        severity
        squad
        reward
        cve
        createdAt
        url
      }
      metadata {
        totalCount
      }
    }
  }
`;
