import { Alert, ErrorBoundary, LoadingPlaceholder } from '@grafana/ui';
import { Suspense, useDeferredValue } from 'react';

import { CveCardGrid } from './CveCardGrid';

interface CveCardGridType {
  cve: string;
  package: string;
  sortBy: string;
  first: string;
  after: string;
}

export const CveCardGridContainer = ({ cve, package: pkg, sortBy, first, after }: CveCardGridType) => {
  const deferredCve = useDeferredValue(cve);
  const deferredPackage = useDeferredValue(pkg);
  const deferredSortBy = useDeferredValue(sortBy);
  const deferredFirst = useDeferredValue(first);
  const deferredAfter = useDeferredValue(after);

  return (
    <ErrorBoundary>
      {({ error }) => {
        if (error) {
          return (
            <Alert title="Error: failed to load cves" onRemove={() => window.location.reload()} buttonContent="Reload">
              {error.message}
            </Alert>
          );
        }
        return (
          <Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
            <CveCardGrid
              cve={deferredCve}
              package={deferredPackage}
              sortBy={deferredSortBy}
              first={deferredFirst}
              after={deferredAfter}
            />
          </Suspense>
        );
      }}
    </ErrorBoundary>
  );
};
