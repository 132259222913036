import { TypedDocumentNode, gql } from '@apollo/client';
import { GetVersionsWithRiskSloQuery, GetVersionsWithRiskSloQueryVariables } from '__generated__/graphql';

export const GET_VERSIONS_WITH_RISK_SLO: TypedDocumentNode<
  GetVersionsWithRiskSloQuery,
  GetVersionsWithRiskSloQueryVariables
> = gql`
  query getVersionsWithRiskSlo($id: ID!) {
    source(id: $id) {
      id
      origin
      name
      versions {
        id
        version
        isMostRecentVersion
        latestScan
        issues {
          isDismissed
          cve {
            id
            severity
          }
          sloRemaining
        }
      }
    }
  }
`;
