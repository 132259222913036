import { css } from '@emotion/css';
import { getAppEvents, usePluginInteractionReporter } from '@grafana/runtime';
import { Card, TagList, useStyles2 } from '@grafana/ui';
import { Source } from '__generated__/graphql';
import { GITHUB_PREFIX, SOURCES_CARD_CLICK, SOURCES_ROUTE } from 'shared/constants';
import { SourceCardGroupClickedEvent } from 'shared/events';

import { getSourceImage, looseSemverSortTagsDec } from '../utils';

interface SourceCardProps {
  source: Source;
}

export const SourceCard = ({ source }: SourceCardProps) => {
  const report = usePluginInteractionReporter();
  const styles = useStyles2(getStyles);
  const sourceImage = getSourceImage(source.origin);

  const onTagClick = (tag: string) => {
    const group = source.groups.find((group) => group.name === tag)!;
    const payload = {
      groupName: group.name,
      groupID: group.id,
    };
    getAppEvents().publish(new SourceCardGroupClickedEvent(payload));
  };

  return (
    <Card
      href={`${SOURCES_ROUTE}/${source.id}`}
      onClick={() =>
        report(SOURCES_CARD_CLICK, {
          source_id: source.id,
        })
      }
    >
      <Card.Heading>{source.name.replace(GITHUB_PREFIX, '')}</Card.Heading>
      <Card.Figure>
        <img src={sourceImage} alt="logo" height="40px" width="40px" className={styles.logo} />
      </Card.Figure>
      <Card.Meta className={styles.meta}>
        {looseSemverSortTagsDec(source.versions.map((version) => version.version))}
      </Card.Meta>
      <Card.Tags>
        <TagList tags={[...source.groups.map((group) => group.name)]} onClick={onTagClick} displayMax={10} />
      </Card.Tags>
    </Card>
  );
};

const getStyles = () => {
  return {
    logo: css({
      objectFit: 'contain',
    }),
    meta: css({
      flexWrap: 'wrap',
    }),
  };
};
