import { css, cx } from '@emotion/css';
import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import { useStyles2, useTheme2, Button, Input, Stack } from '@grafana/ui';
import { useCallback, useState } from 'react';

import { GroupItem } from './GroupItem';

interface Props {
  placeholder?: string;
  /** Array of selected groups */
  groups?: SelectableValue[];
  onAddGroup: (newGroup: string) => void;
  onRemoveGroup: (group: SelectableValue) => void;
  width?: number;
  id?: string;
  className?: string;
  /** Toggle disabled state */
  disabled?: boolean;
  /** Enable adding new tags when input loses focus */
  addOnBlur?: boolean;
  /** Toggle invalid state */
  invalid?: boolean;
  /** Array of options for autocomplete */
  options?: SelectableValue[];
}

export const GroupsInput = ({
  placeholder = 'New tag (enter key to add)',
  groups = [],
  onAddGroup,
  onRemoveGroup,
  width,
  className,
  disabled,
  addOnBlur,
  invalid,
  id,
  options = [],
}: Props) => {
  const [newGroupName, setNewGroupName] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<Array<SelectableValue<string>>>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const styles = useStyles2(getStyles);
  const theme = useTheme2();

  const onNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setNewGroupName(value);

      if (value) {
        const filtered = options.filter((option) => {
          const isNameInGroups = groups.map((group) => group.name).includes(option.label);
          const matchQuery = option.label!.toLowerCase().includes(value.toLowerCase());

          return !isNameInGroups && matchQuery;
        });

        setFilteredOptions(filtered);
        setShowDropdown(true);
        setFocusedIndex(-1);
      } else {
        setFilteredOptions([]);
        setShowDropdown(false);
        setFocusedIndex(-1);
      }
    },
    [options, groups]
  );

  const onSelectOption = (option: SelectableValue<string>) => {
    setNewGroupName(option.label!);
    setShowDropdown(false);
    setFocusedIndex(-1);
  };

  const onAdd = (event?: React.MouseEvent | React.KeyboardEvent) => {
    event?.preventDefault();
    if (!groups.map((group) => group.name).includes(newGroupName)) {
      onAddGroup(newGroupName);
    }
    setNewGroupName('');
    setShowDropdown(false);
    setFocusedIndex(-1);
  };

  const onBlur = () => {
    if (addOnBlur && newGroupName) {
      onAdd();
    }
    setShowDropdown(false);
  };

  const onKeyboardNavigation = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (focusedIndex >= 0 && focusedIndex < filteredOptions.length) {
        onSelectOption(filteredOptions[focusedIndex]);
      } else if (newGroupName !== '') {
        onAdd(event);
      }
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      setFocusedIndex((prevIndex) => Math.min(prevIndex + 1, filteredOptions.length - 1));
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setFocusedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    }
  };

  return (
    <Stack direction="column">
      <div className={cx(styles.wrapper, className, width ? css({ width: theme.spacing(width) }) : '')}>
        <Input
          id={id}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onNameChange}
          value={newGroupName}
          onKeyDown={onKeyboardNavigation}
          onBlur={onBlur}
          invalid={invalid}
          suffix={
            <Button
              fill="text"
              className={styles.addButtonStyle}
              onClick={onAdd}
              size="md"
              disabled={newGroupName.length <= 0}
            >
              Add
            </Button>
          }
        />
        {showDropdown && filteredOptions.length > 0 && (
          <ul className={styles.dropdown}>
            {filteredOptions.map((option, index) => (
              <li
                key={option.value}
                role="option"
                aria-selected={index === focusedIndex}
                tabIndex={0}
                onMouseDown={() => onSelectOption(option)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' || event.key === ' ') {
                    onSelectOption(option);
                  }
                }}
                className={cx(styles.option, index === focusedIndex && styles.focusedOption)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
      {groups?.length > 0 && (
        <ul className={styles.tags}>
          {groups.map((group) => (
            <GroupItem key={group.id} group={group} onRemove={onRemoveGroup} disabled={disabled} />
          ))}
        </ul>
      )}
    </Stack>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  wrapper: css({
    position: 'relative',
    minHeight: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    flexWrap: 'wrap',
  }),
  dropdown: css({
    position: 'absolute',
    top: '100%',
    left: 0,
    zIndex: 1000,
    backgroundColor: theme.colors.background.primary,
    borderRadius: theme.shape.radius.default,
    listStyleType: 'none',
    padding: 0,
    marginTop: theme.spacing(0.5),
    width: '100%',
    maxHeight: '200px',
    overflowY: 'auto',
    boxShadow: theme.shadows.z1,
  }),
  option: css({
    color: theme.colors.text.maxContrast,
    padding: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.background.secondary,
    },
  }),
  focusedOption: css({
    backgroundColor: theme.colors.background.secondary,
  }),
  tags: css({
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: theme.spacing(0.5),
  }),
  addButtonStyle: css({
    margin: `0 -${theme.spacing(1)}`,
  }),
});
