import { ModifyRiskFormInput } from './ModifyRiskForm';
import { Exploitability } from './constants';

export const prepareRiskPayload = ({
  exploitability,
  cvssScore,
  confidentialityImpact,
  integrityImpact,
  availabilityImpact,
  isThirdParty,
}: ModifyRiskFormInput) => ({
  signOfExploitation: exploitability!.value === Exploitability.SignOfExploitation,
  zeroDay: exploitability!.value === Exploitability.ZeroDay,
  highlyExploitable: exploitability!.value === Exploitability.HighlyExploitable,
  exploitable: exploitability!.value === Exploitability.Exploitable,
  unlikelyExploitable: exploitability!.value === Exploitability.UnlikelyExploitable,
  notExploitable: exploitability!.value === Exploitability.NotExploitable,
  falsePositive: exploitability!.value === Exploitability.FalsePositive,
  cvssScore: cvssScore!,
  confidentialityImpact: confidentialityImpact!,
  integrityImpact: integrityImpact!,
  availabilityImpact: availabilityImpact!,
  isThirdParty: isThirdParty,
});
