import { SceneQueryRunner } from '@grafana/scenes';
import { getPrometheusDatasource } from 'scenes/datasources/prometheus/prometheusDatasource';

export const getMediumAndLowVulnerabilitiesQueryRunner = (group: string) =>
  new SceneQueryRunner({
    datasource: getPrometheusDatasource(),
    queries: [
      {
        refId: 'E',
        expr: `1 - ( 
            (sum by (group) (vulns_by_group{database="svo", cluster="\${cluster}", group="${group}", severity=~"medium|low", slo_expired="True"}) )
            / 
            (sum by (group) (vulns_by_group{database="svo", cluster="\${cluster}", group="${group}", severity=~"medium|low"})  )
        )`,
      },
    ],
  });
