import { css } from '@emotion/css';
import { GrafanaTheme2, renderMarkdown } from '@grafana/data';
import { LoadingPlaceholder, Stack, Tag, useStyles2 } from '@grafana/ui';

import { useFetchBugBountySubmission } from './useFetchBugBountySubmission';

interface BugBountyReportTableProps {
  submissionCode: string;
}

export const BugBountyReportTable = ({ submissionCode }: BugBountyReportTableProps) => {
  const styles = useStyles2(getStyles);
  const { submission, loading } = useFetchBugBountySubmission(submissionCode);

  if (loading) {
    return <LoadingPlaceholder text="Loading..." />;
  }

  if (!submission || 'error' in submission) {
    return null;
  }

  const { report } = submission;
  if (!report) {
    return null;
  }

  const { domain, type, endpointVulnerableComponent, pocDescription, impact, attachments } = report;

  let pocDescriptionWithImg = pocDescription || '';
  if (attachments && attachments.length > 0) {
    for (const attachment of attachments) {
      if (!attachment) {
        continue;
      }
      pocDescriptionWithImg = pocDescriptionWithImg.replace(
        `{${attachment.code}}`,
        `![${attachment.code}](${attachment.url})`
      );
    }
  }

  return (
    <table className={styles.table}>
      <tbody>
        <tr>
          <th>Domain</th>
          <td>
            <Stack direction="row" wrap="wrap">
              {domain?.name}
              {domain?.tier?.value && <Tag name={domain.tier.value} />}
              {domain?.type?.value && <Tag name={domain.type.value} />}
            </Stack>
          </td>
        </tr>
        <tr>
          <th>Type</th>
          <td>
            <Stack direction="row">
              {type?.cwe + ' ' + type?.name}
              {type?.category && <Tag name={type.category} />}
            </Stack>
          </td>
        </tr>
        <tr>
          <th>Endpoint / vulnerable component</th>
          <td>{endpointVulnerableComponent}</td>
        </tr>
        <tr>
          <th>Proof of concept/description</th>
          <td>
            <span
              className="markdown-html"
              dangerouslySetInnerHTML={{ __html: renderMarkdown(pocDescriptionWithImg) }}
            />
          </td>
        </tr>
        <tr>
          <th>Impact</th>
          <td>{impact}</td>
        </tr>
      </tbody>
    </table>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  table: css({
    width: '100%',
    height: 'max-content',
    borderCollapse: 'collapse',
    border: `1px solid ${theme.colors.border.strong}`,
    background: theme.colors.background.canvas,
    'th, td': {
      padding: '8px',
      borderBottom: `1px solid ${theme.colors.border.strong}`,
    },
    th: {
      borderRight: `1px solid ${theme.colors.border.strong}`,
    },
  }),
});
