import { css, cx } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Box, useStyles2 } from '@grafana/ui';
import { useMemo } from 'react';

import { EXCEPTION_REASONS } from '../CreateException/CreateException';
import { hexToRgba } from '../utils';

interface ReasonBoxType {
  value: string;
}

export const ReasonBox = ({ value }: ReasonBoxType) => {
  const styles = useStyles2(getStyles);

  let background = useMemo(() => {
    switch (value) {
      case EXCEPTION_REASONS.FalsePositive:
        return styles.falsePositive;
      case EXCEPTION_REASONS.NotExploitable:
        return styles.notExploitable;
      case EXCEPTION_REASONS.WontFixDeprecated:
        return styles.wontFix;
      default:
        return styles.none;
    }
  }, [value, styles]);

  return (
    <div className={cx(styles.box, background)}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        paddingX={1}
        paddingY={0.25}
        borderRadius="default"
      >
        {value || 'Unknown'}
      </Box>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  box: css({
    width: 'max-content',
    color: theme.colors.text.maxContrast,
  }),
  falsePositive: css({
    border: `1px solid ${theme.visualization.getColorByName('dark-purple')}`,
    background: hexToRgba(theme.visualization.getColorByName('dark-purple'), 0.1),
  }),
  notExploitable: css({
    border: `1px solid ${theme.visualization.getColorByName('dark-blue')}`,
    background: hexToRgba(theme.visualization.getColorByName('dark-blue'), 0.1),
  }),
  wontFix: css({
    border: `1px solid ${theme.visualization.getColorByName('dark-orange')}`,
    background: hexToRgba(theme.visualization.getColorByName('dark-orange'), 0.1),
  }),
  none: css({
    border: `1px solid ${theme.colors.secondary.border}`,
    background: hexToRgba(theme.colors.secondary.border, 0.1),
  }),
});
