import { TypedDocumentNode, gql } from '@apollo/client';
import {
  AddIssueCommentMutation,
  AddIssueCommentMutationVariables,
  AddIssueCommentToAllMutation,
  AddIssueCommentToAllMutationVariables,
  AddIssueCommentToSourceMutation,
  AddIssueCommentToSourceMutationVariables,
  AddIssueCommentToVersionMutation,
  AddIssueCommentToVersionMutationVariables,
  RemoveCommentMutation,
  RemoveCommentMutationVariables,
} from '__generated__/graphql';

export const ADD_ISSUE_COMMENT: TypedDocumentNode<AddIssueCommentMutation, AddIssueCommentMutationVariables> = gql`
  mutation addIssueComment($input: AddCommentInput!) {
    addIssueComment(input: $input) {
      id
    }
  }
`;

export const ADD_ISSUE_COMMENT_TO_VERSION: TypedDocumentNode<
  AddIssueCommentToVersionMutation,
  AddIssueCommentToVersionMutationVariables
> = gql`
  mutation addIssueCommentToVersion($input: AddCommentInput!) {
    addIssueCommentToVersion(input: $input) {
      id
    }
  }
`;

export const ADD_ISSUE_COMMENT_TO_SOURCE: TypedDocumentNode<
  AddIssueCommentToSourceMutation,
  AddIssueCommentToSourceMutationVariables
> = gql`
  mutation addIssueCommentToSource($input: AddCommentInput!) {
    addIssueCommentToSource(input: $input) {
      id
    }
  }
`;

export const ADD_ISSUE_COMMENT_TO_ALL: TypedDocumentNode<
  AddIssueCommentToAllMutation,
  AddIssueCommentToAllMutationVariables
> = gql`
  mutation addIssueCommentToAll($input: AddCommentInput!) {
    addIssueCommentToAll(input: $input) {
      id
    }
  }
`;

export const REMOVE_COMMENT: TypedDocumentNode<RemoveCommentMutation, RemoveCommentMutationVariables> = gql`
  mutation removeComment($input: RemoveCommentInput!) {
    removeComment(input: $input)
  }
`;
