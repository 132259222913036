import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { CustomCellRendererProps, Tag, useStyles2 } from '@grafana/ui';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { CVES_ROUTE } from 'shared/constants';

const PackageCell = memo((props: CustomCellRendererProps) => {
  const styles = useStyles2(getStyles);
  const navigate = useNavigate();

  const { text } = props.field.display!(props.value);

  return <Tag className={styles.tags} name={text} onClick={() => navigate(`${CVES_ROUTE}?var-package=${text}`)} />;
});

const getStyles = (theme: GrafanaTheme2) => ({
  tags: css({
    svg: {
      marginRight: theme.spacing(0.5),
    },
  }),
});

PackageCell.displayName = 'PackageCell';

export { PackageCell };
