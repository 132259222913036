import { Box, Button, Stack, Text } from '@grafana/ui';
import { Source } from '__generated__/graphql';
import { GITHUB_PREFIX } from 'shared/constants';

import { getSourceImage } from '../utils';

import { ReleasePlanTable } from './ReleasePlanTable';
import { buildReleaseTableCSV, downloadCSV } from './csvUtils';

export const addDaysToDate = (date: Date, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export interface ReportRow {
  cves: Array<{
    cve: string;
    url: string;
  }>;
  package: string;
  target: string;
  fixedIn: string;
  sloRemaining: number;
  installedVersions: {
    [tag: string]: string;
  };
}

interface BuildReleasePlanType {
  source: Source;
  releaseDate: Date;
  bufferDays: number;
}

export const BuildReleasePlan = ({ source, releaseDate, bufferDays }: BuildReleasePlanType) => {
  const releaseDateWithBuffer = addDaysToDate(releaseDate, bufferDays);
  const reportRows = source.versions.reduce(
    (acc, version) => {
      version.issues.forEach((issue) => {
        if (issue.isGone || issue.isDismissed) {
          return;
        }

        const sloExpiresOn = addDaysToDate(new Date(), issue.sloRemaining);
        if (sloExpiresOn > releaseDateWithBuffer) {
          return;
        }

        if (issue.package in acc) {
          const existingRow = acc[issue.package];

          const existingCves = existingRow.cves.map((c) => c.cve);
          if (!existingCves.includes(issue.cve.cve)) {
            existingRow.cves.push({ cve: issue.cve.cve, url: issue.cve.url });
          }
          if (!(version.tag in existingRow.installedVersions)) {
            existingRow.installedVersions[version.tag] = issue.installedVersion;
          }
          if (!existingRow.target.length && issue.target.length) {
            existingRow.target = issue.target;
          }
          if (issue.sloRemaining < existingRow.sloRemaining) {
            existingRow.sloRemaining = issue.sloRemaining;
          }
        } else {
          acc[issue.package] = {
            cves: [{ cve: issue.cve.cve, url: issue.cve.url }],
            package: issue.package,
            target: issue.target,
            fixedIn: issue.fixedVersion,
            sloRemaining: issue.sloRemaining,
            installedVersions: {
              [version.tag]: issue.installedVersion,
            },
          };
        }
      });

      return acc;
    },
    {} as { [pkg: string]: ReportRow }
  );

  const tags = source.versions.map((v) => v.tag);

  const handleExportToCSV = () => {
    const csvData = buildReleaseTableCSV(reportRows, tags);
    downloadCSV(csvData, source.name.replace(GITHUB_PREFIX, ''), source.type);
  };

  const sourceImage = getSourceImage(source.origin);

  return (
    <Box marginBottom={2} paddingTop={2} backgroundColor="primary" borderColor="strong" borderStyle="solid">
      <Stack direction="column" gap={1}>
        <Box paddingX={2}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" gap={0.5}>
            <Text variant="h2">
              <Box marginRight={1} display="inline" justifyContent="center">
                <img src={sourceImage} alt="logo" height="30px" width="30px" />
              </Box>
              {source.name.replace(GITHUB_PREFIX, '')}
            </Text>
            <Button
              size="sm"
              icon="file-download"
              variant="success"
              disabled={!Object.keys(reportRows).length}
              tooltip={!Object.keys(reportRows).length ? 'Nothing to export' : ''}
              onClick={handleExportToCSV}
            >
              Download as CSV
            </Button>
          </Stack>
        </Box>
        <ReleasePlanTable rows={reportRows} tags={tags} />
      </Stack>
    </Box>
  );
};
