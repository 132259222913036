import { TypedDocumentNode, gql } from '@apollo/client';
import { GetUsersQuery, GetUsersQueryVariables } from '__generated__/graphql';

export const GET_USERS: TypedDocumentNode<GetUsersQuery, GetUsersQueryVariables> = gql`
  query getUsers($filters: UserFilters, $first: Int, $after: Int) {
    users(filters: $filters, first: $first, after: $after) {
      response {
        id
        name
        role
      }
      metadata {
        totalCount
      }
    }
  }
`;
