import { TypedDocumentNode, gql } from '@apollo/client';
import { ModifyBugBountySubmissionMutation, ModifyBugBountySubmissionMutationVariables } from '__generated__/graphql';

export const MODIFY_BUG_BOUNTY_SUBMISSION: TypedDocumentNode<
  ModifyBugBountySubmissionMutation,
  ModifyBugBountySubmissionMutationVariables
> = gql`
  mutation modifyBugBountySubmission($input: ModifyBugBountySubmissionInput!) {
    modifyBugBountySubmission(input: $input) {
      submissionCode
      status
      squad
      cve
    }
  }
`;
