import { Alert, ErrorBoundary, LoadingPlaceholder } from '@grafana/ui';
import { Suspense, useCallback, useDeferredValue, useState } from 'react';

import { ExploreBugBountySubmissions } from './ExploreBugBountySubmissions';

interface ExploreBugBountySubmissionsContainerType {
  submissionCode: string;
  squad: string;
  severity: string;
  status: string;
  sortBy: string;
  from: string;
  to: string;
}

export const ExploreBugBountySubmissionsContainer = ({
  submissionCode,
  squad,
  severity,
  status,
  sortBy,
  from,
  to,
}: ExploreBugBountySubmissionsContainerType) => {
  const pageSize = 10;
  const [offset, setOffset] = useState<number>(0);

  const deferredSubmissionCode = useDeferredValue(submissionCode);
  const deferredSquad = useDeferredValue(squad);
  const deferredSeverity = useDeferredValue(severity);
  const deferredStatus = useDeferredValue(status);
  const deferredSortBy = useDeferredValue(sortBy);
  const deferredOffset = useDeferredValue(offset);
  const deferredFrom = useDeferredValue(from);
  const deferredTo = useDeferredValue(to);

  const onNavigate = useCallback(
    (page: number) => {
      setOffset(pageSize * (page - 1));
    },
    [setOffset]
  );

  return (
    <ErrorBoundary>
      {({ error }) => {
        if (error) {
          return (
            <Alert
              title="Error: failed to load bug bounty submissions"
              onRemove={() => window.location.reload()}
              buttonContent="Reload"
            >
              {error.message}
            </Alert>
          );
        }
        return (
          <Suspense fallback={<LoadingPlaceholder text="Loading..." />}>
            <ExploreBugBountySubmissions
              submissionCode={deferredSubmissionCode}
              squad={deferredSquad}
              severity={deferredSeverity}
              status={deferredStatus}
              sortBy={deferredSortBy}
              offset={deferredOffset}
              from={deferredFrom}
              to={deferredTo}
              pageSize={pageSize}
              onNavigate={onNavigate}
            />
          </Suspense>
        );
      }}
    </ErrorBoundary>
  );
};
