import { Button, ButtonGroup, Dropdown, Menu } from '@grafana/ui';
import { Role } from '__generated__/graphql';
import { useCallback, useState } from 'react';
import { useRole } from 'scenes/hooks';

export enum CommentScope {
  Issue,
  Version,
  Source,
  All,
}

interface ButtonOption {
  label: string;
  description: string;
  disabled: boolean;
  scope: CommentScope;
}

interface AddCommentButtonType {
  disabled: boolean;
  isSignedIn: boolean;
  updateScope: (scope: CommentScope) => void;
}

const defaultButton: ButtonOption = {
  label: 'Add Comment to Issue',
  description: 'Affects this issue only',
  disabled: false,
  scope: CommentScope.Issue,
};

export const AddCommentButton = ({ disabled, isSignedIn, updateScope }: AddCommentButtonType) => {
  const { role } = useRole();

  const [selectedButton, setSelectedButton] = useState<ButtonOption>(defaultButton);

  const getButtonOptions = useCallback(
    (isAdmin: boolean): ButtonOption[] => [
      defaultButton,
      {
        label: 'Add Comment to All Issues',
        description: `Affects all issues from this cve for this version only ${isAdmin ? '(administrators only)' : ''}`,
        disabled: isAdmin,
        scope: CommentScope.Version,
      },
      {
        label: 'Add Comment to All Versions',
        description: `Affects all issues from this cve for this source only ${isAdmin ? '(administrators only)' : ''}`,
        disabled: isAdmin,
        scope: CommentScope.Source,
      },
      {
        label: 'Add Comment to All Sources',
        description: `Affects all issues from this cve for all sources ${isAdmin ? '(administrators only)' : ''}`,
        disabled: isAdmin,
        scope: CommentScope.All,
      },
    ],
    []
  );

  return (
    <ButtonGroup>
      <Button
        disabled={disabled}
        type="submit"
        size="sm"
        tooltip={!isSignedIn ? 'Sign in to add a comment' : ''}
        tooltipPlacement="top"
      >
        {selectedButton.label}
      </Button>
      <Dropdown
        placement="auto-end"
        overlay={
          <Menu>
            {getButtonOptions(role !== Role.Administrator).map((option, index) => (
              <Menu.Item
                key={index}
                disabled={option.disabled}
                label={option.label}
                description={option.description}
                onClick={() => {
                  setSelectedButton(option);
                  updateScope(option.scope);
                }}
              />
            ))}
          </Menu>
        }
      >
        <Button
          size="sm"
          disabled={disabled}
          icon="angle-down"
          tooltipPlacement="auto-end"
          tooltip="Additional Options"
        />
      </Dropdown>
    </ButtonGroup>
  );
};
