import {
  CustomVariable,
  EmbeddedScene,
  QueryVariable,
  SceneAppPage,
  SceneFlexItem,
  SceneFlexLayout,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';
import { VariableHide } from '@grafana/schema';
import { Icon } from '@grafana/ui';
import { EXCEPTION_REASONS } from 'scenes/components/CreateException/CreateException';
import { TextInputVariable } from 'scenes/controls/TextInputVariable';
import { CveExceptions } from 'scenes/customScenes/CveExceptions';
import { baseOpts, getInfinityDatasource, urlOpts } from 'scenes/datasources/infinity';
import { CVE_EXCEPTIONS_CREATE_ROUTE, CVE_EXCEPTIONS_ROUTE, CVE_EXCEPTION_DETAILS_ROUTE } from 'shared/constants';

import { getCreateCveExceptionDrilldown } from './getCreateCveExceptionDrilldown';
import { getCveExceptionDetailsDrilldown } from './getCveExceptionDetailsDrilldown';

interface GetExploreCveExceptionsTabType {
  apiUrl: string;
}

export const getExploreCveExceptionsTab = ({ apiUrl }: GetExploreCveExceptionsTabType) =>
  new SceneAppPage({
    title: 'Explore CVE Exceptions',
    titleIcon: 'exclamation-circle',
    key: 'cveExceptionsDrilldown',
    url: `${CVE_EXCEPTIONS_ROUTE}`,
    getScene: () => {
      return new EmbeddedScene({
        key: 'exploreExceptionsDrilldown',
        $variables: new SceneVariableSet({
          variables: [
            new TextInputVariable({
              name: 'cve',
              prefix: <Icon name="search" />,
              placeholder: 'Search CVEs',
              width: 35,
              hide: VariableHide.hideLabel,
            }),
            new QueryVariable({
              name: 'source',
              label: 'Source',
              includeAll: true,
              defaultToAll: true,
              allValue: '0',
              datasource: getInfinityDatasource(),
              query: {
                refId: 'variable-source',
                infinityQuery: {
                  ...baseOpts,
                  url: apiUrl,
                  root_selector: 'data.sources.response',
                  url_options: {
                    ...urlOpts,
                    body_graphql_query: `
                      query getSources {
                        sources {
                            response {
                              __value: id
                              __text: name
                            }
                        }
                      }
                    `,
                  },
                },
                queryType: 'infinity',
              },
            }),
            new QueryVariable({
              name: 'version',
              label: 'Version',
              includeAll: true,
              defaultToAll: true,
              allValue: '0',
              datasource: getInfinityDatasource(),
              query: {
                refId: 'variable-version',
                infinityQuery: {
                  ...baseOpts,
                  url: apiUrl,
                  root_selector: 'data.versions.response',
                  url_options: {
                    ...urlOpts,
                    body_graphql_query: `
                      query getVersions($filters: VersionFilters) {
                        versions(filters: $filters) {
                            response {
                              __value: id
                              __text: version
                            }
                        }
                      }
                    `,
                    body_graphql_variables: JSON.stringify({
                      filters: {
                        sourceId: '$source',
                      },
                    }),
                  },
                },
                queryType: 'infinity',
              },
            }),
            new CustomVariable({
              name: 'reason',
              label: 'Status',
              value: 'All',
              text: 'All',
              query: `All,${Object.values(EXCEPTION_REASONS).join(',')}`,
            }),
          ],
        }),
        body: new SceneFlexLayout({
          direction: 'column',
          maxWidth: '100%',
          children: [
            new SceneFlexItem({
              height: '100%',
              body: new CveExceptions({
                cve: '${cve}',
                source: '${source}',
                version: '${version}',
                reason: '${reason}',
              }),
            }),
          ],
        }),
        controls: [new VariableValueSelectors({})],
      });
    },
    drilldowns: [
      {
        routePath: `${CVE_EXCEPTION_DETAILS_ROUTE}/:exceptionID`,
        getPage(routeMatch, parent) {
          const exceptionID = routeMatch.params.exceptionID;
          return getCveExceptionDetailsDrilldown({ exceptionID, parent });
        },
      },
      {
        routePath: CVE_EXCEPTIONS_CREATE_ROUTE,
        getPage(_, parent) {
          return getCreateCveExceptionDrilldown({ parent });
        },
      },
    ],
  });
