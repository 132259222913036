import { useSuspenseQuery } from '@apollo/client';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Button, LinkButton, Pagination, Stack, Tag, Text, useStyles2 } from '@grafana/ui';
import { GetCveExceptionsQuery } from '__generated__/graphql';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { GITHUB_PREFIX, SOURCES_ROUTE, CVE_EXCEPTION_DETAILS_ROUTE, CVE_DETAILS_ROUTE } from 'shared/constants';

import { formatDate } from '../utils';

import { GET_CVE_EXCEPTIONS } from './ExploreExceptionsQueries';
import { ReasonBox } from './ReasonBox';

interface ExploreExceptionsType {
  cve: string;
  source: string;
  version: string;
  reason: string;
  offset: number;
  pageSize: number;
  onNavigate: (page: number) => void;
}

const ExploreExceptions = memo(
  ({ cve, source, version, reason, offset, pageSize, onNavigate }: ExploreExceptionsType) => {
    const styles = useStyles2(getStyles);

    const navigate = useNavigate();

    const { data } = useSuspenseQuery<GetCveExceptionsQuery>(GET_CVE_EXCEPTIONS, {
      variables: {
        filters: {
          cve,
          reason,
          sourceId: source,
          versionId: version,
          isActive: true,
        },
        first: pageSize,
        after: offset,
      },
      fetchPolicy: 'network-only',
    });

    const exceptions = data.cveExceptions.response;

    return (
      <Stack direction="column">
        {exceptions.length ? (
          <>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>CVE</th>
                  <th>Status</th>
                  <th className={styles.tagsCol}>Excepted Versions</th>
                  <th>Created On</th>
                  <th className={styles.detailsCol} />
                </tr>
              </thead>
              <tbody>
                {exceptions.map((exception, index) => (
                  <tr key={`${exception.id}-${index}`}>
                    <td>
                      <LinkButton
                        className={styles.linkButton}
                        fill="text"
                        href={`${CVE_DETAILS_ROUTE}/${exception.cve.cve}`}
                      >
                        {exception.cve.cve}
                      </LinkButton>
                    </td>
                    <td>
                      <ReasonBox value={exception.reason} />
                    </td>
                    <td>
                      {exception.exceptionVersions.map(({ version }, index) => {
                        const name = version?.source?.name.replace(GITHUB_PREFIX, '');
                        const tag = version?.version;
                        const icon = version?.source?.type === 'repository' ? 'github' : 'docker';
                        return (
                          <Tag
                            className={styles.tags}
                            name={`${name}:${tag}`}
                            icon={icon}
                            key={`tag-${exception.id}-${index}`}
                            onClick={() => navigate(`${SOURCES_ROUTE}/${version?.source!.id}/version/${version!.id}`)}
                          />
                        );
                      })}
                      {exception.exceptionSources.map(({ source }, index) => {
                        const name = source?.name.replace(GITHUB_PREFIX, '');
                        const icon = source?.type === 'repository' ? 'github' : 'docker';
                        return (
                          <Tag
                            className={styles.tags}
                            name={`${name}:all`}
                            icon={icon}
                            key={`tag-${exception.id}-${index}`}
                            onClick={() => navigate(`${SOURCES_ROUTE}/${source!.id}`)}
                          />
                        );
                      })}
                    </td>
                    <td>{formatDate(new Date(exception.createdOn), false)}</td>
                    <td>
                      <Button size="sm" onClick={() => navigate(`${CVE_EXCEPTION_DETAILS_ROUTE}/${exception.id}`)}>
                        More Details
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Stack direction="row-reverse">
              <Pagination
                currentPage={Math.ceil((offset + 1) / pageSize)}
                numberOfPages={Math.ceil(data.cveExceptions.metadata.totalCount / pageSize)}
                onNavigate={onNavigate}
                hideWhenSinglePage
              />
            </Stack>
          </>
        ) : (
          <Text italic color="secondary">
            No CVE exceptions
          </Text>
        )}
      </Stack>
    );
  }
);

ExploreExceptions.displayName = 'ExploreExceptions';

export { ExploreExceptions };

const getStyles = (theme: GrafanaTheme2) => ({
  table: css({
    marginTop: theme.spacing(1),
    width: '100%',
    borderCollapse: 'collapse',
    'thead tr': {
      backgroundColor: theme.colors.background.primary,
    },
    'tbody tr:nth-of-type(odd)': {
      backgroundColor: theme.colors.background.secondary,
    },
    'tbody tr:nth-of-type(even)': {
      backgroundColor: theme.colors.background.primary,
    },
    'th, td': {
      padding: '8px',
    },
  }),
  tagsCol: css({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(1),
  }),
  detailsCol: css({
    width: '110px',
  }),
  linkButton: css({
    padding: theme.spacing(0),
  }),
  tags: css({
    display: 'inline-flex',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    svg: {
      marginRight: theme.spacing(0.5),
    },
  }),
});
