import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Box, useStyles2 } from '@grafana/ui';

import { hexToRgba } from '../utils';

import { CountBox } from './CountBox';
import type { CveCounts } from './VersionSummaryCard';

interface SeverityCountsType {
  counts: CveCounts;
  isSlo?: boolean;
}

export const SeverityCounts = ({ counts, isSlo }: SeverityCountsType) => {
  const styles = useStyles2(getStyles);
  return (
    <Box display="flex" justifyContent="flex-start">
      <CountBox label="Critical" count={counts.critical} styleClass={styles.critical} />
      <CountBox label="High" count={counts.high} styleClass={isSlo ? styles.critical : styles.high} />
      <CountBox label="Medium" count={counts.medium} styleClass={isSlo ? styles.critical : styles.medium} />
      <CountBox label="Low" count={counts.low} styleClass={isSlo ? styles.critical : styles.low} />
    </Box>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  critical: css({
    border: `1px solid ${theme.visualization.getColorByName('dark-red')}`,
    background: hexToRgba(theme.visualization.getColorByName('dark-red'), 0.08),
  }),
  high: css({
    border: `1px solid ${theme.visualization.getColorByName('dark-orange')}`,
    background: hexToRgba(theme.visualization.getColorByName('dark-orange'), 0.08),
  }),
  medium: css({
    border: `1px solid ${theme.visualization.getColorByName('dark-yellow')}`,
    background: hexToRgba(theme.visualization.getColorByName('dark-yellow'), 0.08),
  }),
  low: css({
    border: `1px solid ${theme.visualization.getColorByName('grey')}`,
    background: hexToRgba(theme.visualization.getColorByName('grey'), 0.08),
  }),
});
