import { TypedDocumentNode, gql } from '@apollo/client';
import { GetIssueCommentsQuery, GetIssueCommentsQueryVariables } from '__generated__/graphql';

export const GET_ISSUE_COMMENTS: TypedDocumentNode<GetIssueCommentsQuery, GetIssueCommentsQueryVariables> = gql`
  query getIssueComments($id: ID!) {
    issue(id: $id) {
      id
      comments {
        id
        comment
        userId
        userName
        date
        gravatarUrl
      }
    }
  }
`;
