import { css, cx } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { hexToRgba } from '../utils';

interface CountBoxProps {
  label: string;
  count: number;
  styleClass: string;
}

export const CountBox = ({ label, count, styleClass }: CountBoxProps) => {
  const styles = useStyles2(getStyles);
  return (
    <div
      className={cx(styles.box, styleClass, {
        [styles.none]: count === 0,
      })}
    >
      <abbr className={styles.abbr} title={label}>
        {label.charAt(0)}
      </abbr>
      : {count}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  box: css({
    minWidth: '64px',
    textAlign: 'center',
    alignItems: 'center',
    padding: `${theme.spacing(0.25)} ${theme.spacing(0.5)}`,
    marginRight: theme.spacing(1),
    borderRadius: theme.shape.radius.default,
    color: theme.colors.text.maxContrast,
  }),
  none: css({
    border: `1px solid ${theme.colors.secondary.border}`,
    background: hexToRgba(theme.colors.secondary.border, 0.1),
  }),
  abbr: css({
    textDecoration: 'none',
    borderBottom: 'none',
  }),
});
