import { getAppEvents, usePluginInteractionReporter } from '@grafana/runtime';
import { Card, TagList } from '@grafana/ui';
import { Cve } from '__generated__/graphql';
import { CVES_CARD_CLICK, CVE_DETAILS_ROUTE } from 'shared/constants';
import { CveCardPackageClickedEvent } from 'shared/events';

import { SeverityBox } from '../SeverityBox';

interface CveCardProps {
  cve: Cve;
}

export const CveCard = ({ cve }: CveCardProps) => {
  const report = usePluginInteractionReporter();
  const dedupedPackages = [...new Set(cve.issues.map((iss) => iss.package))];

  const onTagClick = (tag: string) => {
    const payload = {
      package: tag,
    };
    getAppEvents().publish(new CveCardPackageClickedEvent(payload));
  };

  return (
    <Card
      href={`${CVE_DETAILS_ROUTE}/${cve.cve}`}
      onClick={() =>
        report(CVES_CARD_CLICK, {
          cve_id: cve.id,
        })
      }
    >
      <Card.Heading>{cve.cve}</Card.Heading>
      <Card.Meta>{cve.title}</Card.Meta>
      <Card.Figure>
        <SeverityBox value={cve.cvssScore} />
      </Card.Figure>
      <Card.Tags>
        <TagList tags={dedupedPackages} displayMax={10} onClick={onTagClick} />
      </Card.Tags>
    </Card>
  );
};
