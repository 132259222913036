import { useMutation, useSuspenseQuery } from '@apollo/client';
import { AppEvents, SelectableValue } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import { Switch, Button, Box, Text, Divider } from '@grafana/ui';
import {
  GetAlertingConfigQuery,
  ModifyAlertingConfigInput,
  ModifyAlertingConfigMutation,
  Severity,
} from '__generated__/graphql';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import {
  GithubAssigneesField,
  GithubLabelsField,
  GithubRepositoryNameField,
  GithubSubIssuesField,
  SlackChannelField,
} from '../ConfigureSource/Fields';
import { SEVERITY_OPTIONS, SlackSeveritiesField } from '../ConfigureSource/Fields/SlackSeveritiesField';
import { GithubAlertConfig, SlackAlertConfig } from '../ConfigureSource/types';

import { MODIFY_ALERTING_CONFIG } from './ManageSourceMutations';
import { GET_ALERTING_CONFIG } from './ManageSourceQueries';

interface ManageAlertingType {
  sourceID: string;
}

export interface AlertingFormType extends SlackAlertConfig, GithubAlertConfig {}

const mapStringsToSelectableValues = (strings: string[]): Array<SelectableValue<Severity>> => {
  return strings.map((str) => {
    const option = SEVERITY_OPTIONS.find((option) => option.label.toLowerCase() === str.toLowerCase());
    if (option) {
      return option;
    }
    throw new Error(`Invalid severity label: ${str}`);
  });
};

export const ManageAlerting = ({ sourceID }: ManageAlertingType) => {
  const { data } = useSuspenseQuery<GetAlertingConfigQuery>(GET_ALERTING_CONFIG, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: sourceID,
    },
  });

  const [modifyAlertConfig] = useMutation<ModifyAlertingConfigMutation>(MODIFY_ALERTING_CONFIG, {
    ignoreResults: true,
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to update alerts`],
      });
    },
    onCompleted: () => {
      getAppEvents().publish({
        type: AppEvents.alertSuccess.name,
        payload: [`Success: updated alerts`],
      });
    },
  });

  const alertConfig = data.source.alerts;

  const { control, handleSubmit, watch, formState } = useForm<AlertingFormType>({
    defaultValues: {
      slackAlertsEnabled: alertConfig.slackAlert.enabled,
      slackChannelName: alertConfig.slackAlert.channelName,
      slackSeverities: mapStringsToSelectableValues(alertConfig.slackAlert.severities),
      githubIssuesEnabled: alertConfig.githubIssue.enabled,
      githubCreateSubIssues: alertConfig.githubIssue.createSubIssues,
      githubRepositoryName: alertConfig.githubIssue.repositoryName,
      githubLabels: alertConfig.githubIssue.labels,
      githubAssignees: alertConfig.githubIssue.assignees,
    },
  });

  const slackAlertsEnabled = watch('slackAlertsEnabled');
  const githubIssuesEnabled = watch('githubIssuesEnabled');

  const onSubmit: SubmitHandler<AlertingFormType> = (data) => {
    const payload: ModifyAlertingConfigInput = {
      githubAssignees: data.githubAssignees,
      githubCreateSubIssues: data.githubCreateSubIssues,
      githubIssuesEnabled: data.githubIssuesEnabled,
      githubLabels: data.githubLabels,
      githubRepositoryName: data.githubRepositoryName,
      slackAlertsEnabled: data.slackAlertsEnabled,
      slackChannelName: data.slackChannelName,
      slackSeverities: data.slackSeverities.map((severity) => severity.value!),
      sourceId: sourceID,
    };

    modifyAlertConfig({
      variables: {
        input: payload,
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" direction="row" gap={1} marginBottom={1} alignItems="center">
        <Text variant="body">Enable Slack Alerts</Text>
        <Controller name="slackAlertsEnabled" control={control} render={({ field }) => <Switch {...field} />} />
      </Box>
      {slackAlertsEnabled && (
        <>
          <SlackChannelField control={control} formState={formState} required={slackAlertsEnabled} />
          <SlackSeveritiesField control={control} formState={formState} required={slackAlertsEnabled} />
        </>
      )}
      <Divider />
      <Box display="flex" direction="row" gap={1} marginBottom={1} alignItems="center">
        <Text variant="body">
          Enable GitHub Issues{' '}
          <Text variant="body" color="secondary" italic>
            (coming soon)
          </Text>
        </Text>
        <Controller
          name="githubIssuesEnabled"
          control={control}
          render={({ field }) => <Switch {...field} disabled />}
        />
      </Box>
      {githubIssuesEnabled && (
        <>
          <GithubRepositoryNameField control={control} formState={formState} required={githubIssuesEnabled} />
          <GithubSubIssuesField control={control} formState={formState} />
          <GithubLabelsField control={control} formState={formState} />
          <GithubAssigneesField control={control} formState={formState} />
        </>
      )}
      <Divider />
      <Button type="submit" size="md" disabled={!formState.isDirty} tooltip={!formState.isDirty ? 'No changes' : ''}>
        Save Alerting Configuration
      </Button>
    </form>
  );
};
