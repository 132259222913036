import { useMutation } from '@apollo/client';
import { css } from '@emotion/css';
import { AppEvents, GrafanaTheme2 } from '@grafana/data';
import { config, getAppEvents } from '@grafana/runtime';
import { useStyles2, Field, ReactMonacoEditor, Stack } from '@grafana/ui';
import {
  AddIssueCommentMutation,
  AddIssueCommentToAllMutation,
  AddIssueCommentToSourceMutation,
  AddIssueCommentToVersionMutation,
} from '__generated__/graphql';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import { AddCommentButton, CommentScope } from './AddCommentButton';
import {
  ADD_ISSUE_COMMENT,
  ADD_ISSUE_COMMENT_TO_ALL,
  ADD_ISSUE_COMMENT_TO_SOURCE,
  ADD_ISSUE_COMMENT_TO_VERSION,
} from './IssueCommentsMutations';
import { GET_ISSUE_COMMENTS } from './IssueCommentsQueries';

interface FormType {
  comment: string;
  scope: CommentScope;
}

interface AddCommentFormType {
  issueID: string;
}

export const AddCommentForm = ({ issueID }: AddCommentFormType) => {
  const styles = useStyles2(getStyles);

  const [addIssueComment] = useMutation<AddIssueCommentMutation>(ADD_ISSUE_COMMENT, {
    refetchQueries: [GET_ISSUE_COMMENTS],
    ignoreResults: true,
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to add comment to issue`],
      });
    },
  });
  const [addIssueCommentToVersion] = useMutation<AddIssueCommentToVersionMutation>(ADD_ISSUE_COMMENT_TO_VERSION, {
    refetchQueries: [GET_ISSUE_COMMENTS],
    ignoreResults: true,
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to add comment to all issues`],
      });
    },
  });
  const [addIssueCommentToSource] = useMutation<AddIssueCommentToSourceMutation>(ADD_ISSUE_COMMENT_TO_SOURCE, {
    refetchQueries: [GET_ISSUE_COMMENTS],
    ignoreResults: true,
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to add comment to all versions`],
      });
    },
  });
  const [addIssueCommentToAll] = useMutation<AddIssueCommentToAllMutation>(ADD_ISSUE_COMMENT_TO_ALL, {
    refetchQueries: [GET_ISSUE_COMMENTS],
    ignoreResults: true,
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to add comment to all sources`],
      });
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { isValid },
  } = useForm<FormType>({
    defaultValues: {
      comment: '',
      scope: CommentScope.Issue,
    },
  });

  const onSubmit: SubmitHandler<FormType> = ({ comment, scope }) => {
    const user = config.bootData.user;
    const payload = {
      variables: {
        input: {
          comment,
          userId: user.id,
          userName: user.name,
          issueId: issueID,
          gravatarUrl: user.gravatarUrl,
        },
      },
    };

    switch (scope) {
      case CommentScope.Issue:
        addIssueComment(payload);
        break;
      case CommentScope.Version:
        addIssueCommentToVersion(payload);
        break;
      case CommentScope.Source:
        addIssueCommentToSource(payload);
        break;
      case CommentScope.All:
        addIssueCommentToAll(payload);
        break;
    }
    reset();
  };

  const user = config.bootData.user;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field>
        <Controller
          name="comment"
          control={control}
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => (
            <ReactMonacoEditor
              {...field}
              height="120px"
              language="markdown"
              className={styles.editor}
              options={{
                overviewRulerLanes: 0,
                wordWrap: 'on',
                renderLineHighlight: 'none',
                folding: false,
                lineNumbers: 'off',
                lineDecorationsWidth: 8,
                fontSize: 14,
                minimap: {
                  enabled: false,
                },
                scrollbar: {
                  vertical: 'hidden',
                },
              }}
            />
          )}
        />
      </Field>
      <Stack justifyContent="space-between" alignItems="center">
        <em>Working as: {user.name || 'anonymous'}</em>
        <AddCommentButton
          disabled={!user.isSignedIn || !isValid}
          isSignedIn={user.isSignedIn}
          updateScope={(scope: CommentScope) => {
            setValue('scope', scope);
          }}
        />
      </Stack>
    </form>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  editor: css({
    background: theme.colors.background.canvas,
    border: `1px solid ${theme.colors.border.weak}`,
    padding: '4px 0px',
  }),
});
