import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Box, Stack, useStyles2, Text, Tag, IconButton } from '@grafana/ui';
import { CveException } from '__generated__/graphql';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { GITHUB_PREFIX, SOURCES_ROUTE } from 'shared/constants';

import { formatDate } from '../utils';

import { AddVersionModal } from './AddVersionModal';

interface ExceptionDetailsMetadataType {
  exception: CveException;
}

export const ExceptionDetailsMetadata = ({ exception }: ExceptionDetailsMetadataType) => {
  const styles = useStyles2(getStyles);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const createdOnDate = formatDate(new Date(exception.createdOn), false);

  return (
    <>
      <div className={styles.grid}>
        <Box backgroundColor="canvas" borderColor="strong" borderStyle="solid" padding={1.5}>
          <Stack direction="column">
            <Text element="p" variant="h5">
              Created By: {exception.team}
            </Text>
            <Text element="p" variant="h5">
              Created On: {createdOnDate}
            </Text>
          </Stack>
        </Box>
        <Box backgroundColor="canvas" borderColor="strong" borderStyle="solid" padding={1.5}>
          <Stack direction="column">
            <Stack direction="row" justifyContent="space-between">
              <Text element="p">Affected Versions</Text>
              <IconButton name="edit" tooltip="Add versions" variant="secondary" onClick={() => setIsModalOpen(true)} />
            </Stack>
            <div className={styles.tagList}>
              {exception.exceptionVersions.map(({ version }, index) => {
                const name = version?.source?.name.replace(GITHUB_PREFIX, '');
                const tag = version?.version;
                const icon = version?.source?.type === 'repository' ? 'github' : 'docker';
                return (
                  <Tag
                    className={styles.tags}
                    name={`${name}:${tag}`}
                    icon={icon}
                    key={`tag-${exception.id}-${index}`}
                    onClick={() => navigate(`${SOURCES_ROUTE}/${version?.source!.id}/version/${version!.id}`)}
                  />
                );
              })}
              {exception.exceptionSources.map(({ source }, index) => {
                const name = source?.name.replace(GITHUB_PREFIX, '');
                const icon = source?.type === 'repository' ? 'github' : 'docker';
                return (
                  <Tag
                    className={styles.tags}
                    name={`${name}:all`}
                    icon={icon}
                    key={`tag-${exception.id}-${index}`}
                    onClick={() => navigate(`${SOURCES_ROUTE}/${source!.id}`)}
                  />
                );
              })}
            </div>
          </Stack>
        </Box>
      </div>
      <AddVersionModal isOpen={isModalOpen} onDismiss={() => setIsModalOpen(false)} exception={exception} />
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  grid: css({
    display: 'grid',
    gridTemplateColumns: 'max-content auto',
    gap: theme.spacing(2),
  }),
  tags: css({
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    svg: {
      marginRight: theme.spacing(0.5),
    },
  }),
  tagList: css({
    display: 'flex',
    flexWrap: 'wrap',
  }),
});
