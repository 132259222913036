import { SceneQueryRunner } from '@grafana/scenes';
import { getInfinityGQLQuery } from 'scenes/datasources/infinity';

export const getIssuesQueryRunner = (apiUrl: string) =>
  new SceneQueryRunner(
    getInfinityGQLQuery([
      {
        refId: 'A',
        url: apiUrl,
        root_selector: 'data.issues.response',
        columns: [
          {
            selector: 'isDismissed',
            text: 'Status',
            type: 'string',
          },
          {
            selector: 'version.source.name',
            text: 'Source',
            type: 'string',
          },
          {
            selector: 'version.tag',
            text: 'Version',
            type: 'string',
          },
          {
            selector: 'tool.name',
            text: 'Tool',
            type: 'string',
          },
          {
            selector: 'cve.cve',
            text: 'CVE',
            type: 'string',
          },
          {
            selector: 'cve.id',
            text: 'cveId',
            type: 'string',
          },
          {
            selector: 'cve.cvssScore',
            text: 'CVSS',
            type: 'number',
          },
          {
            selector: 'package',
            text: 'Package',
            type: 'string',
          },
          {
            selector: 'installedVersion',
            text: 'Installed',
            type: 'string',
          },
          {
            selector: 'fixedVersion',
            text: 'Fixed In',
            type: 'string',
          },
          {
            selector: 'version.source.type',
            text: 'Type',
            type: 'string',
          },
          {
            selector: 'sloRemaining',
            text: 'SLO Remaining',
            type: 'number',
          },
          {
            selector: 'risk.risk',
            text: 'Risk',
            type: 'string',
          },
          {
            selector: 'id',
            text: 'Actions',
            type: 'string',
          },
          {
            selector: 'version.source.id',
            text: 'sourceId',
            type: 'string',
          },
          {
            selector: 'version.id',
            text: 'versionId',
            type: 'string',
          },
        ],
        url_options: {
          body_graphql_query: `
              query getIssues($filters: IssueFilters, $first: Int, $after: Int) {
                  issues(filters: $filters, first: $first, after: $after) {
                      response {
                        id
                        isDismissed
                        package
                        installedVersion
                        fixedVersion
                        tool {
                            name
                        }
                        version {
                            id
                            tag
                            source {
                                id
                                name
                                type
                            }
                        }
                        cve {
                            id
                            cve
                            cvssScore
                        }
                        sloRemaining
                        risk {
                          risk
                        }
                      }
                  }
              }
            `,
          body_graphql_variables: JSON.stringify({
            filters: {
              cve: '$cve',
              package: '$package',
              sourceId: '$source',
              versionId: '$version',
              risk: '$risk',
              hideDismissed: '$hideDismissed',
              type: '$type',
              latestVersionsOnly: '$showLatestOnly',
            },
            first: '$issueTableFirst',
            after: '$issueTableAfter',
          }),
        },
      },
    ])
  );
