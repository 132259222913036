import { useQuery } from '@apollo/client';
import { GetRoleQuery } from '__generated__/graphql';

import { GET_ROLE } from './userRoleQueries';

export const useRole = () => {
  const { data, loading, error } = useQuery<GetRoleQuery>(GET_ROLE);

  let role = '';
  if (data && data.role) {
    role = data.role;
  }

  return { role, loading, error };
};
