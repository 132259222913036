import { useMutation, useQuery } from '@apollo/client';
import { css, cx } from '@emotion/css';
import { AppEvents, GrafanaTheme2 } from '@grafana/data';
import { config, getAppEvents } from '@grafana/runtime';
import {
  Box,
  Button,
  Checkbox,
  Field,
  IconButton,
  Modal,
  MultiSelect,
  Select,
  Stack,
  Text,
  useStyles2,
} from '@grafana/ui';
import {
  AddTargetsToCveExceptionMutation,
  CveException,
  GetImpactedVersionListQuery,
  Issue,
} from '__generated__/graphql';
import { ChangeEvent } from 'react';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';

import { TargetsType } from '../CreateException/CreateException';
import { GET_IMPACTED_VERSIONS_LIST } from '../CreateException/CreateExceptionQueries';
import { parseSourceOptionsFromIssues, parseVersionOptionsFromIssues } from '../CreateException/utils';

import { ADD_TARGETS_TO_CVE_EXCEPTION } from './ExceptionDetailsMutations';
import { GET_EXCEPTION } from './ExceptionDetailsQueries';

export interface AddVersionToExceptionFormType {
  targets: TargetsType;
}

interface AddVersionModalType {
  isOpen: boolean;
  onDismiss: () => void;
  exception: CveException;
}

export const AddVersionModal = ({ isOpen, onDismiss, exception }: AddVersionModalType) => {
  const styles = useStyles2(getStyles);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<AddVersionToExceptionFormType>({
    defaultValues: {
      targets: [{ source: null, versions: [], all: false }],
    },
  });

  const targetValue = watch('targets');
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'targets',
  });

  const { data: impactedVersions, loading: loadingImpactedVersions } = useQuery<GetImpactedVersionListQuery>(
    GET_IMPACTED_VERSIONS_LIST,
    {
      variables: {
        id: exception.cve.id,
      },
      fetchPolicy: 'cache-and-network',
      onError: () => {
        getAppEvents().publish({
          type: AppEvents.alertError.name,
          payload: [`Error: failed to fetch impacted versions`],
        });
      },
    }
  );

  const [addTargetsToCveException] = useMutation<AddTargetsToCveExceptionMutation>(ADD_TARGETS_TO_CVE_EXCEPTION, {
    ignoreResults: true,
    onError: () => {
      getAppEvents().publish({
        type: AppEvents.alertError.name,
        payload: [`Error: failed to update CVE exception`],
      });
    },
    onCompleted: () => {
      getAppEvents().publish({
        type: AppEvents.alertSuccess.name,
        payload: [`Updated exception`],
      });
    },
    refetchQueries: [GET_EXCEPTION, GET_IMPACTED_VERSIONS_LIST],
  });

  const onSubmit: SubmitHandler<AddVersionToExceptionFormType> = (formData) => {
    addTargetsToCveException({
      variables: {
        input: {
          exceptionId: exception.id,
          targets: formData.targets.map(({ source, versions, all }) => ({
            sourceId: source!.value,
            versionIds: versions.map((version) => version.value),
            all,
          })),
        },
      },
    });
    onDismiss();
    reset();
  };

  const user = config.bootData.user;

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      title={
        <Stack direction="column">
          <Box marginTop={1}>
            <Text element="h2">Add versions to exception on {exception.cve.cve}</Text>
          </Box>
          <Box marginBottom={1}>
            <Text element="h4" variant="body" color="secondary" italic>
              Only sources and versions with active issues related to the CVE are shown
            </Text>
          </Box>
        </Stack>
      }
      className={styles.modal}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        {fields.map((formField, index) => {
          const sourceOptions = impactedVersions
            ? parseSourceOptionsFromIssues(impactedVersions.cve.issues as Issue[], targetValue)
            : [];

          const versionOptions =
            impactedVersions && targetValue[index]?.source?.value
              ? parseVersionOptionsFromIssues(
                  impactedVersions.cve.issues as Issue[],
                  targetValue[index]?.source?.value!
                )
              : [];

          return (
            <div key={formField.id} className={styles.target}>
              <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                <Stack direction="column">
                  <Field label="Source">
                    <Controller
                      name={`targets.${index}.source`}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          virtualized
                          allowCustomValue={false}
                          placeholder="Select a source"
                          options={sourceOptions}
                          isLoading={loadingImpactedVersions}
                          width={60}
                          className={cx({ [styles.invalid]: errors.targets ? !!errors.targets[index]?.source : false })}
                        />
                      )}
                    />
                  </Field>
                  <Field label="Versions">
                    <Controller
                      name={`targets.${index}.versions`}
                      control={control}
                      rules={{ required: !targetValue[index]?.all }}
                      render={({ field }) => (
                        <Stack direction="row" alignItems="center">
                          <MultiSelect
                            {...field}
                            virtualized
                            placeholder={targetValue[index]?.all ? 'All' : 'Select versions'}
                            options={versionOptions}
                            isLoading={loadingImpactedVersions}
                            width={60}
                            className={cx({
                              [styles.invalid]: errors.targets ? !!errors.targets[index]?.versions : false,
                            })}
                            disabled={targetValue[index]?.all}
                          />
                          <Controller
                            name={`targets.${index}.all`}
                            control={control}
                            render={({ field }) => (
                              <Checkbox
                                {...field}
                                label="All Versions (includes future)"
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                  setValue(`targets.${index}.all`, e.target.checked);
                                  setValue(`targets.${index}.versions`, []);
                                }}
                                disabled={!versionOptions.length}
                              />
                            )}
                          />
                        </Stack>
                      )}
                    />
                  </Field>
                </Stack>
                {targetValue.length > 1 && (
                  <IconButton
                    name="trash-alt"
                    tooltip="Remove Target"
                    variant="destructive"
                    size="lg"
                    onClick={() => remove(index)}
                  />
                )}
              </Stack>
            </div>
          );
        })}
        <div className={styles.addSource}>
          <Button size="sm" icon="plus" onClick={() => append({ source: null, versions: [], all: false })}>
            Add Another Source
          </Button>
        </div>
        <Modal.ButtonRow>
          <Button variant="secondary" fill="outline" onClick={onDismiss}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            {...(!user.isSignedIn && {
              disabled: true,
              tooltip: 'Sign in to perform this action',
            })}
          >
            Update Exception
          </Button>
        </Modal.ButtonRow>
      </form>
    </Modal>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  modal: css({}),
  form: css({
    maxWidth: '720px',
  }),
  target: css({
    borderTop: `1px solid ${theme.colors.border.medium}`,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  }),
  addSource: css({
    borderBottom: `1px solid ${theme.colors.border.medium}`,
    paddingBottom: theme.spacing(1),
  }),
  invalid: css({
    border: `1px solid ${theme.colors.error.border}`,
  }),
});
