import {
  SceneObjectState,
  SceneObjectBase,
  SceneComponentProps,
  sceneGraph,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { ExploreBugBountySubmissionsContainer } from 'scenes/components/ExploreBugBountySubmissions';

interface BugBountySubmissionsState extends SceneObjectState {
  submissionCode: string;
  squad: string;
  severity: string;
  status: string;
  sortBy: string;

  from?: string;
  to?: string;
}

export class BugBountySubmissions extends SceneObjectBase<BugBountySubmissionsState> {
  static Component = BugBountySubmissionsRenderer;

  constructor(state: BugBountySubmissionsState) {
    super(state);
    this.addActivationHandler(() => this.activationHandler());
  }

  protected _variableDependency = new VariableDependencyConfig(this, {
    statePaths: ['submissionCode', 'squad', 'severity', 'status', 'sortBy'],
  });

  private activationHandler() {
    const timeRange = sceneGraph.getTimeRange(this);

    this.setState({ from: timeRange.state.value.from.toISOString() });
    this.setState({ to: timeRange.state.value.to.toISOString() });

    this._subs.add(
      timeRange.subscribeToState((state) => {
        this.setState({ from: state.value.from.toISOString() });
        this.setState({ to: state.value.to.toISOString() });
      })
    );
  }
}

function BugBountySubmissionsRenderer({ model }: SceneComponentProps<BugBountySubmissions>) {
  const { submissionCode, squad, severity, status, sortBy, from, to } = model.useState();

  return (
    <ExploreBugBountySubmissionsContainer
      submissionCode={sceneGraph.interpolate(model, submissionCode)}
      squad={sceneGraph.interpolate(model, squad)}
      severity={sceneGraph.interpolate(model, severity)}
      sortBy={sceneGraph.interpolate(model, sortBy)}
      status={sceneGraph.interpolate(model, status)}
      from={from!}
      to={to!}
    />
  );
}
