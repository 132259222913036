import { useSuspenseQuery } from '@apollo/client';
import { Stack } from '@grafana/ui';
import { CveException, GetExceptionQuery } from '__generated__/graphql';

import { ExceptionDetailsDescription } from './ExceptionDetailsDescription';
import { ExceptionDetailsMetadata } from './ExceptionDetailsMetadata';
import { GET_EXCEPTION } from './ExceptionDetailsQueries';

interface ExceptionDetailsType {
  exceptionID: string;
}

export const ExceptionDetails = ({ exceptionID }: ExceptionDetailsType) => {
  const {
    data: { cveException },
  } = useSuspenseQuery<GetExceptionQuery>(GET_EXCEPTION, {
    variables: { id: exceptionID },
  });

  return (
    <Stack direction="column" gap={2}>
      <ExceptionDetailsMetadata exception={cveException as CveException} />
      <ExceptionDetailsDescription
        cve={cveException.cve.cve}
        reason={cveException.reason}
        description={cveException.description}
      />
    </Stack>
  );
};
