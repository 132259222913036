import { SceneQueryRunner } from '@grafana/scenes';
import { getInfinityGQLQuery } from 'scenes/datasources/infinity';

const filter =
  `(isMostRecentVersion == $latestOnly || isMostRecentVersion == true)` +
  `&&` +
  `(isDismissed == !$hideDismissed || isDismissed == false)` +
  `&&` +
  `(isArchived == $includeArchived || isArchived == false)` +
  `&&` +
  `isGone == false`;

export const getImpactedVersionsQueryRunner = (apiUrl: string, cve: string) =>
  new SceneQueryRunner(
    getInfinityGQLQuery([
      {
        refId: 'C',
        url: apiUrl,
        root_selector: 'data.cves.response[0].issues',
        columns: [
          {
            text: 'versionID',
            selector: 'version.id',
            type: 'string',
          },
          {
            text: 'version',
            selector: 'version.version',
            type: 'string',
          },
          {
            text: 'isMostRecentVersion',
            selector: 'version.isMostRecentVersion',
            type: 'boolean',
          },
          {
            text: 'name',
            selector: 'version.source.name',
            type: 'string',
          },
          {
            text: 'type',
            selector: 'version.source.type',
            type: 'string',
          },
          {
            text: 'sourceID',
            selector: 'version.source.id',
            type: 'string',
          },
          {
            text: 'isArchived',
            selector: 'version.isArchived',
            type: 'boolean',
          },
          {
            text: 'isGone',
            selector: 'isGone',
            type: 'boolean',
          },
          {
            text: 'isDismissed',
            selector: 'isDismissed',
            type: 'boolean',
          },
          {
            text: 'risk',
            selector: 'risk.risk',
            type: 'string',
          },
        ],
        filterExpression: filter,
        url_options: {
          body_graphql_query: `
              query getImpactedVersions($filters: CveFilters, $first: Int, $after: Int) {
                cves(filters: $filters, first: $first, after: $after) {
                  response {
                    id
                    issues {
                      isGone
                      isDismissed
                      risk {
                        risk
                      }
                      version {
                        id
                        version
                        isMostRecentVersion
                        isArchived
                        source {
                          id
                          name
                          type
                        }
                      }
                    }
                  }
                }
              }
            `,
          body_graphql_variables: JSON.stringify({
            filters: {
              cve,
            },
            first: 1,
          }),
        },
      },
    ])
  );
