import { TypedDocumentNode, gql } from '@apollo/client';
import {
  AddGroupMutation,
  AddGroupMutationVariables,
  ArchiveVersionMutation,
  ArchiveVersionMutationVariables,
  DeleteSourceMutation,
  DeleteSourceMutationVariables,
  DeleteVersionMutation,
  DeleteVersionMutationVariables,
  RemoveGroupMutation,
  RemoveGroupMutationVariables,
  UnarchiveVersionMutation,
  UnarchiveVersionMutationVariables,
} from '__generated__/graphql';

export const ADD_GROUP: TypedDocumentNode<AddGroupMutation, AddGroupMutationVariables> = gql`
  mutation addGroup($input: AddGroupInput!) {
    addGroup(input: $input) {
      id
      name
    }
  }
`;

export const REMOVE_GROUP: TypedDocumentNode<RemoveGroupMutation, RemoveGroupMutationVariables> = gql`
  mutation removeGroup($input: RemoveGroupInput!) {
    removeGroup(input: $input)
  }
`;

export const DELETE_VERSION: TypedDocumentNode<DeleteVersionMutation, DeleteVersionMutationVariables> = gql`
  mutation deleteVersion($input: DeleteVersionInput!) {
    deleteVersion(input: $input)
  }
`;

export const DELETE_SOURCE: TypedDocumentNode<DeleteSourceMutation, DeleteSourceMutationVariables> = gql`
  mutation deleteSource($input: DeleteSourceInput!) {
    deleteSource(input: $input)
  }
`;

export const ARCHIVE_VERSION: TypedDocumentNode<ArchiveVersionMutation, ArchiveVersionMutationVariables> = gql`
  mutation archiveVersion($input: ArchiveVersionInput!) {
    archiveVersion(input: $input)
  }
`;

export const UNARCHIVE_VERSION: TypedDocumentNode<UnarchiveVersionMutation, UnarchiveVersionMutationVariables> = gql`
  mutation unarchiveVersion($input: ArchiveVersionInput!) {
    unarchiveVersion(input: $input)
  }
`;
