import { BusEventWithPayload } from '@grafana/data';

interface BugBountySquadUpdatedEventPayload {
  squad: string;
}
export class BugBountySquadUpdatedEvent extends BusEventWithPayload<BugBountySquadUpdatedEventPayload> {
  public static readonly type = 'bug-bounty-squad-updated';
}

interface SourceCardGroupClickedEventPayload {
  groupName: string;
  groupID: string;
}
export class SourceCardGroupClickedEvent extends BusEventWithPayload<SourceCardGroupClickedEventPayload> {
  public static readonly type = 'source-card-group-clicked';
}

interface CveCardPackageClickedEventPayload {
  package: string;
}
export class CveCardPackageClickedEvent extends BusEventWithPayload<CveCardPackageClickedEventPayload> {
  public static readonly type = 'cve-card-package-clicked';
}
